import React, { useState, useContext } from 'react';
import Modal from "react-bootstrap/Modal";
import { Link } from 'react-router-dom';
import swal from 'sweetalert2';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
//import 'react-tabs/style/react-tabs.css';

import ReCAPTCHA from "react-google-recaptcha";
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';

import useLoading from '../../hooks/useLoading';
import useAuth from '../../hooks/useAuth';

import { UserContext } from '../../context/UserContext';

import Settings from "../../config/settings";
import Api from "../../util/api";
import Util from "../../util/util";

function ModalLogin(props) {
  const { startLoading, stopLoading } = useLoading();
  const recaptchaRef = React.useRef();
  const [state, setState] = useState({
    auth: {
      email: "",
      pass: "",
      captchaScore: "",
    },
    register: {
      email: "",
      pass: "",
      captchaScore: "",
      nombre: ""
    },
  });

  const { login } = useAuth();

  const { stateApp, setStateApp, setShowLoginModal } = useContext(UserContext);

  const [tabIndex, setTabIndex] = useState(0);


  const handleChangeLogin = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      auth: {
        ...prevState.auth,
        [id]: value,
      },
    }));
  };

  const handleChangeSignUp = (e) => {
    const { id, value } = e.target;
    console.log(e.target);
    console.log(id, value);
    setState((prevState) => ({
      ...prevState,
      register: {
        ...prevState.register,
        [id]: value,
      },
    }));
    console.log(state);
  };

  function onChangeCaptchaLogin(value) {
    setState((prevState) => ({
      ...prevState,
      auth: {
        ...prevState.auth,
        captchaToken: value,
      },
    }));
  }
  function onChangeCaptchaSignUp(value) {
    setState((prevState) => ({
      ...prevState,
      register: {
        ...prevState.register,
        captchaToken: value,
      },
    }));
  }

  const validarLogin = async () => {

    if (!Util.emailValido(state.auth.email)) {
      await swal.fire("¡Ups!", 'Debes ingresar un email válido', "error");
      return false;
    }
    if (state.auth.pass.length < 0) {
      await swal.fire("¡Ups!", 'Debes ingresar una contraseña', "error");
      return false;
    }
    if (!state.auth.captchaToken) {
      await swal.fire("¡Ups!", 'Es necesario que marques la casilla "No soy un robot"', "error");
      return false;
    }
    return true;
  }

  const loginClick = async (e) => {
    if (e) e.preventDefault();
    try {
      if (!await validarLogin()) return;
      startLoading();
      var res = await Api.auth.login(state.auth);
      var data = await res.json();
      if (await Util.requestSuccess(res)) {
        localStorage.setItem("JWT", data.token);
        login(data.token);
        setShowLoginModal(false);
        window.location.reload();
        const jsonUser = JSON.parse(
          decodeURIComponent(escape(atob(data.token.split(".")[1])))
        );
        console.log(jsonUser);
        setStateApp((prevState) => ({
          ...prevState,
          usuario: {
            _id: jsonUser._id,
            nombre: jsonUser.nombre,
            foto_perfil: jsonUser.foto_perfil,
            email: jsonUser.email,
            llave_publica_lifechain: jsonUser.llave_publica_lifechain
          },
        }));
      } else {
        if (res.status !== 403) {
          await swal.fire("¡Ups!", data.msg, "error");
          recaptchaRef.current.reset();
          stopLoading();
          return;
        }

        const { value: activation_token } = await swal.fire({
          title: 'Email enviado',
          icon: 'success',
          input: 'text',
          inputLabel: 'Número de verificación',
          inputPlaceholder: 'Ingresa el número de verificación',
          html: "Hemos enviado un email a la dirección " +
            state.auth.email +
            " para confirmar que sea real, es necesario que confirmes tu cuenta."
        });

        if (!activation_token) {
          stopLoading();
          return;
        }

        res = await Api.auth.verificarEmail({
          email: state.auth.email,
          activation_token
        });

        data = await res.json();
        if (Util.requestSuccess(res)) {
          await swal.fire("Listo!", "Tu email fué verificado, ya puedes iniciar sesión.", "success");
        } else {
          await swal.fire("Ups!", "Error al verificar email." + data.msg, "error");
          recaptchaRef.current.reset();
          stopLoading();
          return;
        }
      }
    } catch (e) {
      console.log(e);
      await swal.fire("¡Ups!", "Error al iniciar sesión. Es posible que tengas problemas con tu conexión a internet.", "error");
    }
    recaptchaRef.current.reset();
    stopLoading();
  };

  const googleLogin = useGoogleLogin({
    onSuccess: tokenResponse => console.log(tokenResponse),
    flow: 'auth-code',
  });

  const googleLoginSendCredential = async (credential) => {
    try {
      startLoading();
      var res = await Api.auth.googleLogin(
        credential
      );
      var data = await res.json();
      if (await Util.requestSuccess(res)) {
        localStorage.setItem("JWT", data.token);
        login(data.token);
        const jsonUser = JSON.parse(
          decodeURIComponent(escape(atob(data.token.split(".")[1])))
        );
        console.log(jsonUser);
        setStateApp((prevState) => ({
          ...prevState,
          usuario: {
            _id: jsonUser._id,
            nombre: jsonUser.nombre,
            foto_perfil: jsonUser.foto_perfil,
            email: jsonUser.email,
            llave_publica_lifechain: jsonUser.llave_publica_lifechain
          },
        }));
        if(stateApp.refreshPage){
          window.location.reload();
        }

        setShowLoginModal(false);
        
        
      } else {
        await swal.fire("¡Ups!", "Error al iniciar sesión con google.", "error");
      }
    } catch (e) {
      console.log(e);
      await swal.fire("¡Ups!", "Error al iniciar sesión. Es posible que tengas problemas con tu conexión a internet.", "error");
    }
    recaptchaRef.current.reset();
    stopLoading();
  };

  function nombre_valido(value) {
    if (value.search(" ") !== -1) return false;
    var reg = /^([a-z 0-9]{1,25})$/i;
    if (reg.test(value)) return true;
    else return false;
  }

  const validarRegistro = async () => {
    console.log(state);
    if (state.register.nombre.length < 3) {
      await swal.fire("¡Ups!", 'Tu nombre de usuario debe tener al menos 3 caracteres', "error");
      return false;
    }
    if (!nombre_valido(state.register.nombre)) {
      await swal.fire("¡Ups!", 'Tu nombre de usuario no puede tener espacios ni acentos', "error");
      return false;
    }
    if (!Util.emailValido(state.register.email)) {
      await swal.fire("¡Ups!", 'Debes ingresar un email válido', "error");
      return false;
    }
    if (state.register.pass.length < 8) {
      await swal.fire("¡Ups!", 'Tu contraseña no debe ser menor a 8 dígitos', "error");
      return false;
    }
    if (state.register.pass !== state.register.confirmPass) {
      await swal.fire("¡Ups!", 'Las contraseñas no coindicen', "error");
      return false;
    }
    if (!state.register.captchaToken) {
      await swal.fire("¡Ups!", 'Es necesario que marques la casilla "No soy un robot"', "error");
      return false;
    }
    return true;
  }

  const registerClick = async (e) => {
    if (e) e.preventDefault();
    console.log(state.register);
    if (!await validarRegistro()) return;
    try {
      startLoading();
      console.log(state.register);
      var res = await Api.usuarios.crear(state.register);
      console.log(res);
      var data = await res.json();
      if (!await Util.requestSuccess(res)) {
        console.log(data.msg);
        await swal.fire("Ups!", "Error al registrar nuevo usuario. " + data.msg, "error");

        stopLoading();
        return;
      }
      const { value: activation_token } = await swal.fire({
        title: 'Email enviado',
        icon: 'success',
        input: 'text',
        inputLabel: 'Número de verificación',
        inputPlaceholder: 'Ingresa el número de verificación',
        html: "Hemos enviado un email a la dirección " +
          state.register.email +
          " para confirmar que sea real, es necesario que confirmes tu cuenta."
      });

      if (!activation_token) {
        stopLoading();
        return;
      }

      res = await Api.auth.verificarEmail({
        email: state.register.email,
        activation_token
      });

      data = await res.json();
      if (Util.requestSuccess(res)) {
        await swal.fire("Listo!", "Tu email fué verificado, ya puedes iniciar sesión.", "success");
        window.location.href = "/login";
      } else {
        await swal.fire("Ups!", "Error al verificar email." + data.msg, "error");
        stopLoading();
        recaptchaRef.current.reset();
        return;
      }

    } catch (e) {
      console.log(e);
      await swal.fire("Ups!", "Error al registrar nuevo usuario", "error");
      recaptchaRef.current.reset();
    }
    stopLoading();
  };

  function hideLoginModal() {
    setStateApp((prevState) => ({
      ...prevState,
      showLoginModal: false
    }));
  }

  return (
    <div className="ViewImg" style={{ zIndex: "-1" }}>
      <Modal {...props} size="lg" scrollable={true}>
        <Modal.Header closeButton>
          <Modal.Title>Crea tu usuario o inicia sesión</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{
          maxHeight: 'calc(100vh - 90px)',
          overflowY: 'auto'
        }}>
          <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
            <TabList>
              <Tab style={{ fontSize: "20px" }}>Iniciar sesión</Tab>
              <Tab style={{ fontSize: "20px" }}>Registrate</Tab>
            </TabList>

            <TabPanel>
              <div className="flat-form box-login-social" hidden={false}>
                <div className="box-title-login">
                  <h5>Inicia sesión con</h5>
                </div>
                <ul>
                  <li style={{ width: "100%" }}>
                    <Link to="#" onClick={() => googleLogin()} hidden={true} className="sc-button style-2 fl-button pri-3">
                      <i className="icon-fl-google-2"></i>
                      <span>Google</span>
                    </Link>
                    <GoogleLogin
                      style={{ margin: "0 auto" }}
                      onSuccess={googleLoginSendCredential}
                      onError={() => {
                        console.log('Login Failed');
                      }}
                    />
                  </li>
                </ul>
              </div>
              <div className="flat-form box-login-email">
                <div className="box-title-login" hidden={false}>
                  <h5>O usa tu email</h5>
                </div>

                <div className="form-inner">
                  <form action="#" id="contactform">
                    <input onChange={handleChangeLogin} id="email" name="email" tabIndex="1" aria-required="true" type="email" placeholder="Email" required />
                    <input onChange={handleChangeLogin} id="pass" name="pass" tabIndex="2" aria-required="true" type="password" placeholder="Password" required />
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      onChange={onChangeCaptchaLogin}
                      sitekey={Settings.auth.captcha_key}
                      style={{ marginBottom: "20px" }}
                    />
                    <div className="row-form style-1">
                      <Link to="/sign-up" onClick={() => setTabIndex(0)} className="forgot-pass">Crea tu cuenta</Link>
                      <Link to="/forgotpass" onClick={hideLoginModal} className="forgot-pass">¿Olvidaste tu contraseña?</Link>
                    </div>

                    <button onClick={loginClick} className="mb-4">Iniciar sesión</button>


                  </form>
                </div>

              </div>
            </TabPanel>
            <TabPanel>
              <div className="flat-form box-login-social" hidden={false}>
                <div className="box-title-login">
                  <h5>Registrate con</h5>
                </div>
                <ul>
                  <li style={{ width: "100%" }}>
                    <Link to="#" onClick={() => googleLogin()} hidden={true} className="sc-button style-2 fl-button pri-3">
                      <i className="icon-fl-google-2"></i>
                      <span>Google</span>
                    </Link>
                    <GoogleLogin
                      style={{ margin: "0 auto" }}
                      onSuccess={googleLoginSendCredential}
                      onError={() => {
                        console.log('Login Failed');
                      }}
                    />
                  </li>
                </ul>
              </div>
              <div className="flat-form box-login-email">
                <div className="box-title-login" hidden={true}>
                  <h5>O usa tu email</h5>
                </div>
                <p style={{ marginBottom: "15px" }}>O da click <Link to="/sign-up" onClick={hideLoginModal} className="forgot-pass">aquí</Link> para registrarte con tu email</p>
                <div className="form-inner">
                  <form id="contactform" hidden={true}>
                    <input onChange={handleChangeSignUp} id="nombre" name="nombre" tabIndex="1" aria-required="true" type="text" placeholder="Nombre" required />
                    <input onChange={handleChangeSignUp} id="email" name="email" tabIndex="2" aria-required="true" type="email" placeholder="Email" required />
                    <input onChange={handleChangeSignUp} id="celular" name="celular" tabIndex="2" aria-required="true" type="tel" placeholder="Celular (opcional)" />
                    <input onChange={handleChangeSignUp} id="pass" name="pass" tabIndex="3" aria-required="true" type="password" placeholder="Contraseña" required />
                    <input onChange={handleChangeSignUp} id="confirmPass" name="pass" tabIndex="3" aria-required="true" type="password" placeholder="Confirmar contraseña" required />
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      onChange={onChangeCaptchaSignUp}
                      sitekey={Settings.auth.captcha_key}
                      style={{ marginBottom: "20px" }}
                    />

                    <div className="row-form style-1">
                      <Link to="#" onClick={() => setTabIndex(0)} className="forgot-pass">Iniciar sesión</Link>
                    </div>


                    <button onClick={registerClick} hidden={true}>Crear usuario</button>
                  </form>
                  <p className="color-2 mb-4">
                    <input
                      type="checkbox"
                      name="aceptoPoliticaPrivacidad"
                      id="aceptoPoliticaPrivacidad"
                    />{" "}
                    Acepto el{" "}
                    <a href="https://www.lifechain.mx/politica-privacidad" rel="noreferrer" target="_blank" className="accent bold">
                      Aviso de privacidad
                    </a>
                    {" y "}
                    <a href="https://www.lifechain.mx/terminos-condiciones" rel="noreferrer" target="_blank" className="accent bold">
                      Terminos y condiciones
                    </a>
                  </p>
                </div>

              </div>
            </TabPanel>
          </Tabs>

        </Modal.Body>
      </Modal>
    </div>
  );
}
export default ModalLogin;
