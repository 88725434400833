
const menus = {
    menusNormal: [
        {
            id: 1,
            name: 'Home',
            links: '/',
        },
        {
            id: 2,
            name: 'Explorar',
            links: '#',
            namesub: [
                {
                    id: 2,
                    sub: 'Eventos',
                    links: '/events'
                },
                {
                    id: 1,
                    sub: 'Colecciones',
                    links: '/collections'
                },
    
                /*{
                    id: 5,
                    sub: 'Subastas en vivo',
                    links: '/live-auctions'
                },
                {
                    id: 6,
                    sub: 'Item Details 01',
                    links: '/item-details-01'
                },
                {
                    id: 7,
                    sub: 'Item Details 02',
                    links: '/item-details-02'
                }*/
    
            ],
        }, /*{
                id: 3,
                name: 'Canjear',
                links: '/redeem',
            },*//*{
                id: 3,
                name: 'Activity',
                links: '#',
                namesub: [
                    {
                        id: 1,
                        sub: 'Activity 01',
                        links: '/activity-01'
                    },
                    {
                        id: 2,
                        sub: 'Activity 02',
                        links: '/activity-02'
                    }
                ],
            },*/
        /*{
            id: 4,
            name: 'Community',
            links: '#',
            namesub: [
                {
                    id: 1,
                    sub: 'Blog',
                    links: '/blog'
                },
                {
                    id: 2,
                    sub: 'Blog Details',
                    links: '/blog-details'
                },
                {
                    id: 3,
                    sub: 'Help Center',
                    links: '/help-center'
                }
            ],
        },*/
    
    
        {
            id: 7,
            name: 'Contacto',
            links: '/contact',
        },
        {
            id: 8,
            name: 'FAQ',
            links: '/faq'
        },
        {
            id: 9,
            name: 'Crea tu evento',
            links: '/event/create'
        },
    
    ],
    
    
    menusAuthenticated: { //Usuarios con sesión
        id: 5,
        name: 'Mi Cuenta',
        links: '#',
        namesub: [
            {
                id: 5,
                sub: "Mis eventos",
                links: "/event/reports/"
            },
            {
                id: 6,
                sub: "Mi equipo",
                links: "/myteam/"
            },
            {
                id: 7,
                sub: 'Mi perfil',
                links: '/profile'
            },
            {
                id: 8,
                sub: 'Editar perfil',
                links: '/edit-profile'
            },
            {
                id: 9,
                sub: 'Cerrar sesión',
                links: '/',
                onClick: () => {
                    localStorage.removeItem("JWT");
                    window.location.href = "/";
                }
            }
        ],
    },
    menusWithoutSession: { //Usuarios sin sesión
        id: 5,
        name: 'Mi Cuenta',
        links: '#',
        namesub: [
            {
                id: 7,
                sub: 'Iniciar sesión',
                links: '/login',
    
            },
            {
                id: 8,
                sub: 'Registrate',
                links: '/sign-up'
            }
        ],
    }
}


export default menus;