import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';

const NFTCard = props => {

    const [modalShow, setModalShow] = useState(false);

    return (
        <div key={props.item.id} className={`sc-card-product explode style2 mg-bt ${props.item.feature ? 'comingsoon' : ''} `}>
            <div className="card-media">
                <Link to={(props.item.coleccion && "/asset/" + props.item.coleccion.direccion_smart_contract + "/" + props.item.nft_id) || "/"}><img src={props.item.url || props.item.coleccion && props.item.coleccion.url_foto_perfil || "/assets/icon/capturas.gif"} alt={props.item.nombre || ""} /></Link>
                {(props.item.price && (
                    <div className="button-place-bid">
                        <button onClick={() => setModalShow(true)} className="sc-button style-place-bid style bag fl-button pri-3"><span>Hacer oferta</span></button>
                    </div>))}
                <Link to="/login" className="wishlist-button heart" hidden={true}><span className="number-like">{props.item.wishlist || 0}</span></Link>
                <div className="coming-soon">{props.item.feature}</div>
            </div>
            <div className="card-title">
                <h5><Link to={(props.item.coleccion && "/asset/" + props.item.coleccion.direccion_smart_contract + "/" + props.item.nft_id) || "/"}>{props.item.nombre || ""}</Link></h5>

            </div>
            {/*<div className="meta-info">
                <div className="author">
                    <div className="avatar">
                        <img src={props.item.creador.foto_perfil} alt="Foto de autor" />
                    </div>
                    <div className="info">
                        <span>Creador</span>
                        <h6> <Link to={"/" + props.item.creador.nombre}>{props.item.creador.nombre}</Link> </h6>
                    </div>

                </div>
                {props.item.coleccion && (<div className="tags">{props.item.coleccion.red}</div>)}

                </div>*/}
            <div className="meta-info">
                {props.item && props.item.creador && (

                    <div className="author">
                        <div className="avatar">
                            <img src={props.item.creador.foto_perfil || "/assets/icon/profile.png"} alt="Foto de comprador" />
                        </div>
                        <div className="info">
                            <span>Creador</span>
                            <h6> <a href={"/" + props.item.creador.nombre}>{props.item.creador.nombre}</a> </h6>
                        </div>
                    </div>
                )}
                {props.item && props.item.comprador && (
                    <div className="author">
                        <div className="avatar">
                            <img src={props.item.comprador.foto_perfil || "/assets/icon/profile.png"} alt="Foto de comprador" />
                        </div>
                        <div className="info">
                            <span>Dueño</span>
                            <h6> <a href={"/" + props.item.comprador.nombre}>{props.item.comprador.nombre}</a> </h6>
                        </div>
                    </div>
                )}
            </div>
            <div className="card-bottom style-explode">
                {(props.item.price && (
                    <div className="price">
                        <span>Oferta actual</span>
                        <div className="price-details">
                            <h5>{props.item.price}</h5>
                            <span>= {props.item.priceChange}</span>
                        </div>
                    </div>
                )) || (
                        <div className="price">
                            <span>Este NFT no está a la venta</span>
                        </div>
                    )}

                {false && <Link to="/activity-01" className="view-history reload">Ver historial</Link>}
                {props.item.coleccion && (<div className="tags">{props.item.coleccion.red}</div>)}
            </div>
        </div>
    );
}

export default NFTCard;
