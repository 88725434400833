import React, { useState, useEffect, useContext } from "react";
import swal from 'sweetalert2';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import TiposTicket from '../components/layouts/tipos-boleto/TiposTicketIframe';
import Sponsors from '../components/layouts/sponsors/Sponsors';

import "bootstrap-icons/font/bootstrap-icons.css";

import { UserContext } from '../context/UserContext';

import Api from "../util/api";
import Util from "../util/util";
import UtilEth from '../util/eth';
import LoginForm from "./../pages/LoginForm";
import IFrameFooter from '../components/footer/IFrameFooter';
import useLoading from "../hooks/useLoading";
import useUser from "../hooks/useUser";
import EventDescription from "../components/layouts/EventDescription";

const Event = () => {
    const { startLoading, stopLoading } = useLoading();
    const { user } = useUser();
    const [state, setState] = useState({
        selectedIndex: 0,
        evento: {},
        tiposTicket: [],
        patrocinadores: [],
        eventoId: "",
        error: null,
        loading: false,
        metamaskPublicKey: null,
    });

    const { stateApp, setStateApp } = useContext(UserContext);

    async function mintTickets() {

        startLoading();

        var items = [];

        for (var i = 0; i < state.tiposTicket.length; i++) {
            if (!state.tiposTicket[i].cantidad_a_comprar || state.tiposTicket[i].cantidad_a_comprar === 0) continue;
            items.push(state.tiposTicket[i]);
        }

        //Llamar API para generar referencia de compra
        var res = await Api.ordenCompra.crearTicketsMercadoPago({
            coleccion: state.evento._id,
            items,
            address_to: user.llave_publica_lifechain
        });
        console.log(res);
        var data = await res.json();
        if (await Util.requestSuccess(res)) {
            console.log(data);
            window.location = data.init_point;
        } else {
            stopLoading();
            await swal.fire("Ups!", data.msg, "error");
        }
        stopLoading();
    }

    useEffect(() => {
        const consultarEvento = async (eventoId) => {
            console.log('consultar');
            try {
                startLoading();
                var res = await Api.eventos.get(eventoId);
                var data = await res.json();
                var evento = data.evento;
                var nfts = data.nfts;
                console.log(data);
                if (Util.requestSuccess(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        nfts,
                        evento,
                        eventoId
                    }));
                } else {
                    console.log(data.msg);
                    stopLoading();
                    await swal.fire("Ups!", "Error al consultar evento " + data.msg, "error");
                    return;
                }
            } catch (e) {
                console.log(e);
                stopLoading();
                await swal.fire("Ups!", "Error al consultar evento", "error");
            }
            stopLoading();
        };

        const consultarTiposTicket = async (eventoId) => {
            console.log('consultar');
            try {
                startLoading();
                var res = await Api.tipoTicket.listEvent(eventoId);
                var data = await res.json();
                var tiposTicket = data.tiposTicket;
                console.log(data);
                if (Util.requestSuccess(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        tiposTicket,
                    }));
                } else {
                    console.log(data.msg);
                    stopLoading();
                    await swal.fire("Ups!", "Error al consultar tipos de tickets" + data.msg, "error");
                    return;
                }
            } catch (e) {
                console.log(e);
                stopLoading();
                await swal.fire("Ups!", "Error al consultar tipos de tickets", "error");
            }
            stopLoading();
        };

        if (state.eventoId.length === 0) {
            var initialId = window.location.pathname.replace("/iframe/event/", "");
            if (initialId.length > 0) {
                const MINUTE_MS = 900000;
                const interval = setInterval(() => {
                    consultarEvento(initialId.replace("/", ""));
                    clearInterval(interval);
                }, MINUTE_MS);

                setState((prevState) => ({
                    ...prevState,
                    eventoId: initialId
                }));
            }
            consultarEvento(initialId.replace("/", ""));
            consultarTiposTicket(initialId.replace("/", ""));
        }
    }, []);

    function getTiposTicket(tiposBoleto) {
        setState((prevState) => ({
            ...prevState,
            selectedIndex: 1,
        }));
    }

    function getFotoPortadaColeccion() {
        if (state.evento && state.evento.url_foto_portada)
            return {
                background: 'url("' + state.evento.url_foto_portada + '")',
                backgroundPosition: "center",
                filter: 'blur(8px)',
                height: "500px",
                backgroundRepeat: "no-repeat"
            };
        return {};
    }

    async function validarPasosCompra(tabClick) {
        if (tabClick > state.selectedIndex) {
            if (tabClick === 1) await swal.fire("Ups!", "Primero debes elegir tus tickets", "error");
        }
    }

    async function regresarPaso() {
        if (state.selectedIndex === 0) return;
        setState((prevState) => ({
            ...prevState,
            selectedIndex: prevState.selectedIndex - 1,
        }));
    }

    async function connectWalletHandler() {
        await UtilEth.connectWalletHandler(setState);
        const wallet = await UtilEth.getMetamaskWallet();
        if (!wallet) {
            setStateApp((prevState) => ({
                ...prevState,
                llave_publica_wallet: null,
                isWalletConnected: false
            }));
            return;
        }
        sessionStorage.setItem("isWalletConnected", "true");
        setStateApp((prevState) => ({
            ...prevState,
            llave_publica_wallet: wallet,
            isWalletConnected: true
        }));
        /*if (props.handleMetamaskPublicKey)
            props.handleMetamaskPublicKey(wallet);*/
    }

    const getTotalTickets = (tiposTicket = null) => {
        if (!tiposTicket) tiposTicket = state.tiposTicket;
        var totalTickets = 0;
        for (var i = 0; i < tiposTicket.length; i++) {
            if (tiposTicket[i].cantidad_a_comprar) totalTickets += tiposTicket[i].cantidad_a_comprar;
        }
        return totalTickets;
    }

    const getTotalAmount = () => {
        var totalAmount = 0;
        for (var i = 0; i < state.tiposTicket.length; i++) {
            if (state.tiposTicket[i].cantidad_a_comprar) {
                totalAmount += state.tiposTicket[i].cantidad_a_comprar * state.tiposTicket[i].precio;
            }
        }
        return new Intl.NumberFormat('en-US').format(totalAmount);
    }

    function onChangeTab() {

    }


    return (
        <div className='explore'>
            <section className="tf-section popular-collection bg-home-3">
                <div className="container">
                    <div className="row">
                        <div className="mb-4 col-md-12">
                            <h2 className="heading text-center mt-4 mb-4">{(state.evento && state.evento.titulo) || ""}</h2>
                        </div>
                    </div>
                    <div className="mb-4" hidden={!state.tiposTicket || state.tiposTicket.length === 0}>
                        <Tabs selectedIndex={state.selectedIndex} className="authors-2" onSelect={() => { }}>
                            <TabList hidden={true}>
                                <Tab tabIndex="0" onClick={validarPasosCompra}>1. Tickets</Tab>
                                <Tab tabIndex="1" onClick={validarPasosCompra}>2. Checkout</Tab>
                            </TabList>

                            <div className="content-tab">
                                <div className="content-inner">
                                    <div className="row">
                                        <TabPanel key={0}>
                                            <TiposTicket evento={state.evento || null} tiposTicket={state.tiposTicket} getTiposTicket={getTiposTicket} iframe={true} />
                                        </TabPanel>
                                        <TabPanel key={1}>
                                            <div style={{ width: "100%", textAlign: "center" }}>{user ? (
                                                <div className="container mt-5">
                                                    <div className="row">
                                                        <div className="col-xl-2 col-md-2"></div>
                                                        <div className="col-xl-8 col-md-8 col-12">
                                                            <div className="container">


                                                                {
                                                                    state.tiposTicket && state.tiposTicket.map(item => {
                                                                        return item.cantidad_a_comprar > 0 ?
                                                                            <div className="row">
                                                                                <div className="col-md-6 col-7"><p>{item.nombre} </p></div>
                                                                                <div className="col-md-2 col-1"><p>x{item.cantidad_a_comprar}</p></div>
                                                                                <div className="col-md-4 col-4" style={{ textAlign: "right" }}><p>${new Intl.NumberFormat('en-US').format(item.precio)} MXN</p></div>
                                                                            </div>

                                                                            : null
                                                                    })
                                                                }
                                                                <hr />
                                                                <br />
                                                                <div className="row mb-5">
                                                                    <div className="col-md-6 col-7"><p>Total a pagar </p></div>
                                                                    <div className="col-md-2 col-1"><p>x{getTotalTickets()}</p></div>
                                                                    <div className="col-md-4 col-4" style={{ textAlign: "right" }}><p> ${getTotalAmount()} MXN</p></div>
                                                                </div>
                                                                <div className="row mb-5">
                                                                    <div className="col-md-8">
                                                                        <p style={{ overflowWrap: "break-word" }}>{getTotalTickets() > 1 ? "Wallet que recibirá los boletos:" : "Wallet que recibirá el boleto:"} </p>
                                                                        <p>{(stateApp.isWalletConnected && stateApp.llave_publica_wallet + " (Metamask)") || (user?.llave_publica_lifechain && (user?.llave_publica_lifechain.substring(0, 30) + "... (Generada por LifeChain)")) || "No se encontro wallet"}</p>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className="row form-inner" style={{ width: "100%", textAlign: "center" }}>
                                                                            <p>Si prefieres conectar otra wallet con Metamask, da click <a href="" onClick={connectWalletHandler}>aquí</a></p>
                                                                        </div>
                                                                    </div>
                                                                    <br /><br />
                                                                </div>

                                                            </div>

                                                        </div>
                                                        <div className="col-xl-2 col-md-2"></div>

                                                    </div>
                                                    <div className="row form-inner">
                                                        <div className="col-md-6 col" style={{ textAlign: "right" }}>
                                                            <button style={{ width: "100%" }} onClick={regresarPaso}>Regresar</button>
                                                        </div>
                                                        <div className="col-md-6 col">
                                                            <button style={{ width: "100%" }} onClick={mintTickets}>Pagar</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (<LoginForm />)}</div>
                                        </TabPanel>
                                    </div>
                                </div>
                            </div>
                        </Tabs >
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="mb-5" hidden={!state.evento || !state.evento.descripcion || state.evento.descripcion.length === 0}>
                                <h4 className="heading mb-2">About this event</h4>
                                <p hidden={!state.evento.fecha_inicio_evento} className="mt-4 mb-4" > <i className="bi bi-calendar mr-4"></i>{Util.formatDateTimeShort(state.evento.fecha_inicio_evento)} al {Util.formatDateTimeShort(state.evento.fecha_fin_evento)}</p>

                                <EventDescription style={{ whiteSpace: 'pre-wrap' }} htmlContent={state.evento?.descripcion?.replaceAll("<p></p>", "<br/><br/>") || null} />
                                
                                {/*<EjemplosNFT data={personajes} url_foto_firma_artista={(state.evento && state.evento.url_foto_firma_artista) || null} />
                                            <div class="video-container" style={{ width: "100%", position: "relative", paddingBottom: "56.25%" }}>
                                                <iframe
                                                    src="https://www.youtube.com/embed/tRrux04ypOc"
                                                    frameborder="0"
                                                    style={{ left: "0", top: "0", height: "100%", width: "100%", position: "absolute" }}
                                                    allow="accelerometer; autoplay; encrypted-media; gyroscope;"
                                                    allowfullscreen></iframe></div>*/ }




                            </div>
                        </div>
                        <div className="col-md-4">
                            <div hidden={!state.evento || !state.evento.nombre_lugar || state.evento.nombre_lugar.length === 0}>
                                <h4 className="heading mb-2">Place</h4>

                                <p hidden={!state.evento.nombre_lugar} className="mt-4 mb-4"><i className="bi bi-geo-alt mr-4"></i>{state.evento?.nombre_lugar || ""}</p>
                                <p className="mb-2 mt-2">{state.evento?.direccion_lugar || ""}</p>
                                {state.evento?.url_mapa && (<iframe src={state.evento?.url_mapa} width="100%" height="300" style={{ border: "0" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>)}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Sponsors evento={state.eventoId} hidden={!state.patrocinadores || state.patrocinadores.length === 0} />

            <div hidden={true}>
                <IFrameFooter />
            </div>
        </div >
    );
}

export default Event;
