import React, { useState } from "react";
import swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import useLoading from '../hooks/useLoading';

import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import Settings from "../config/settings";
import Api from "../util/api";
import Util from "../util/util";

import $ from 'jquery';

const ForgotPass = () => {
    const { startLoading, stopLoading } = useLoading();
    const recaptchaRef = React.useRef();
    const [state, setState] = useState({
        auth: {
            email: "",
            captchaScore: "",
        },
        passSelected: false,
        error: null,
        loading: false,
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            auth: {
                ...prevState.auth,
                [id]: value,
            },
        }));
    };

    function onChangeCaptcha(value) {
        setState((prevState) => ({
            ...prevState,
            auth: {
                ...prevState.auth,
                captchaToken: value,
            },
        }));
    }

    const validarRegistro = async () => {
        if (!Util.emailValido(state.auth.email)) {
            await swal.fire("¡Ups!", 'Debes ingresar un email válido', "error");
            return false;
        }
        return true;
    }

    const loginClick = async (e) => {
        if (e) e.preventDefault();
        try {
            if (!await validarRegistro()) return;
            startLoading();
            var res = await Api.auth.cambiarPass(
                state.auth
            );
            var data = await res.json();
            if (await Util.requestSuccess(res)) {
                await establecerNuevaContrasena();
            } else {
                if (res.status !== 403) {
                    await swal.fire("¡Ups!", data.msg, "error");
                    recaptchaRef.current.reset();
                    stopLoading();
                    return;
                }
            }
        } catch (e) {
            console.log(e);
            await swal.fire("¡Ups!", "Error al restablecer contraseña.", "error");
            recaptchaRef.current.reset();
        }
        stopLoading();
    };

    const establecerNuevaContrasena = async () => {
        try {
            var { value: userInput } = await swal.fire({
                title: 'Ingresa tu nueva contraseña',
                text: 'Ingresa tu nueva contraseña y el número de verificación',
                icon: 'success',
                html: '<p>Ingresa tu contraseña y el número que enviamos a tu email</p>' +
                    '<input id="verification_token" placeholder="Número de verificación" class="swal2-input" style="width:80%">' +
                    '<input id="pass" type="password" placeholder="Contraseña" class="swal2-input" style="width:80%">' +
                    '<input id="confirmPass" type="password" placeholder="Confirmar contraseña" class="swal2-input" style="width:80%">',
                preConfirm: function () {
                    return new Promise(async function (resolve, reject) {
                        const verification_token = $('#verification_token').val();
                        const pass = $('#pass').val();
                        const confirmPass = $('#confirmPass').val();
                        if (pass.length < 8) {
                            await swal.fire("¡Ups!", "La contraseña debe tener al menos 8 caracteres", "error");
                            return reject(false);
                        }
                        if (pass !== confirmPass) {
                            await swal.fire("¡Ups!", "Las contraseñas no coindicen", "error");
                            return reject(false);
                        }
                        resolve({
                            pass,
                            verification_token
                        });
                    })
                },
            });

            var activation_token = userInput.verification_token;
            var pass = userInput.pass;
            if (!activation_token) {
                stopLoading();
                return;
            }

            var res = await Api.auth.validarCambiarPass({ pass }, activation_token);
            if (Util.requestSuccess(res)) {
                await swal.fire("Listo!", "Tu contraseña fue actualizada, ya puedes iniciar sesión.", "success");
                window.location.href = "/login";
            } else {
                await swal.fire("Ups!", "Error al restablecer contraseña", "error");
                recaptchaRef.current.reset();
                stopLoading();
                return;
            }
        } catch (e) {
            await swal.fire("¡Ups!", "Error al restablecer contraseña.", "error");
            recaptchaRef.current.reset();
        }
    }

    return (
        <div>
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">¿Olvidaste tu contraseña?</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="tf-login tf-section">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="tf-title-heading ct style-1">
                                Te ayudamos a recuperarla
                            </h2>

                            <div className="flat-form box-login-email">
                                <div className="box-title-login" hidden={true}>
                                    <h5>Ingresa tu email</h5>
                                </div>

                                <div className="form-inner">
                                    <form action="#" id="contactform">
                                        <input onChange={handleChange} id="email" name="email" tabIndex="1" aria-required="true" type="email" placeholder="Email" required />
                                        <ReCAPTCHA
                                            ref={recaptchaRef}
                                            onChange={onChangeCaptcha}
                                            sitekey={Settings.auth.captcha_key}
                                            style={{ marginLeft: "50px", marginBottom: "20px" }}
                                        />
                                        <div className="row-form style-1">
                                            <Link to="/sign-up" className="forgot-pass">Crea tu cuenta</Link>
                                            <Link to="/login" className="forgot-pass">Iniciar sesión</Link>
                                        </div>

                                        <button onClick={loginClick}>Restablecer contraseña</button>
                                    </form>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default ForgotPass;
