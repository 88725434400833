import React, { useState, useEffect, useContext } from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import ModalViewQRCode from '../components/modal/ViewQRCode';
import { Link } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import useLoading from '../hooks/useLoading';
import swal from 'sweetalert2';
import Api from "../util/api";
import Util from "../util/util";
import 'react-tabs/style/react-tabs.css';
import settings from '../config/settings';

const ItemDetails01 = () => {
    const { startLoading, stopLoading } = useLoading();
    const [state, setState] = useState({
        nft_id: "",
        nft: {},
    });

    const { setStateApp } = useContext(UserContext);

    const handleLoading = (isLoading) => {
        setStateApp((prevState) => ({
            ...prevState,
            isLoading
        }));
    }

    useEffect(() => {
        const consultarNFT = async (direccion_smart_contract, nft_id) => {
            try {
                await Util.getValidJWTInfo(setState);
                startLoading();
                var res = await Api.nft.get(direccion_smart_contract, nft_id);
                var data = await res.json();
                var nft = data.nft;
                console.log(data);
                if (Util.requestSuccess(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        nft,
                        direccion_smart_contract,
                        nft_id
                    }));
                } else {
                    console.log(data.msg);
                    await swal.fire("Ups!", "Error al consultar usuario " + data.msg, "error");
                }
            } catch (e) {
                console.log(e);
                await swal.fire("Ups!", "Error al consultar usuario", "error");
            }
            stopLoading();
        };

        if (state.nft_id.length === 0) {
            var pathParams = window.location.pathname.replace("/asset/", "").split("/");
            if (pathParams.length === 2) {
                consultarNFT(pathParams[0], pathParams[1]);
            }
        }
        console.log(11);

    }, []);


    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <div className='item-details'>
            <Header />
            <ModalViewQRCode
                show={modalIsOpen}
                onHide={closeModal}
                onLoading={handleLoading}
            />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">{state.nft.nombre}</h1>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to={(state.nft.creador && "/" + state.nft.creador.nombre) || "/"}>{(state.nft.creador && state.nft.creador.nombre) || ""}</Link></li>
                                    <li><Link to={(state.nft.coleccion && "/collection/" + state.nft.coleccion.titulo_url) || "/"}>{(state.nft.coleccion && state.nft.coleccion.titulo) || ""}</Link></li>
                                    <li>{state.nft.nombre}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="tf-section tf-item-details">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-xl-6 col-md-12">
                            <div className="content-left">
                                <div className="media">
                                    <img src={state.nft.url || (state.nft.coleccion && state.nft.coleccion.url_foto_perfil) || ""} hidden={state.nft.url === undefined} alt="Contenido NFT" className="mb-5" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="content-right">
                                <div className="sc-item-details">
                                    <h2 className="style2">{state.nft.nombre} </h2>
                                    <div className="meta-item">
                                        <div className="left">
                                            {state.nft && state.nft.vistas > 0 && (
                                                <span className="viewed eye">{(state.nft && state.nft.vistas) || 0}</span>
                                            )}
                                            {/*<span to="/login" className="liked heart wishlist-button mg-l-8"><span className="number-like">100</span></span>*/}
                                        </div>
                                        {/*<div className="right">
                                                <Link to="#" className="share"></Link>
                                                <Link to="#" className="option"></Link>
                                                </div>*/}
                                    </div>
                                    <div className="client-infor sc-card-product">
                                        <div className="meta-info">
                                            <div className="author">
                                                <div className="avatar">
                                                    <img src={(state.nft.comprador && state.nft.comprador.foto_perfil) || "/assets/icon/profile.png"} alt={(state.nft.comprador && state.nft.comprador.nombre) || "Comprador"} />
                                                </div>
                                                <div className="info">
                                                    <span>Comprado por</span>
                                                    <h6> <Link to={(state.nft.comprador && "/" + state.nft.comprador.nombre) || "/"}>{state.nft.comprador && state.nft.comprador.nombre}</Link> </h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="meta-info">
                                            <div className="author">
                                                <div className="avatar">
                                                    <img src={(state.nft.creador && state.nft.creador.foto_perfil) || "/assets/icon/profile.png"} alt="Creador" />
                                                </div>
                                                <div className="info">
                                                    <span>Creado por</span>
                                                    <h6> <Link to={(state.nft.creador && "/" + state.nft.creador.nombre) || "/"}>{state.nft.creador && state.nft.creador.nombre}</Link> </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className='mb-5' hidden={true}>{(state.nft && state.nft.coleccion && state.nft.coleccion.descripcion) || ""}</p>
                                    <p className='mb-5' style={{ whiteSpace: 'pre-wrap' }}>{(state.nft && state.nft.descripcion) || ""}</p>
                                    <img style={{ width: "100px", margin: "10px", marginBottom: "25px" }} src={state.nft && state.nft.coleccion && state.nft.coleccion.url_foto_firma_artista} hidden={!(state.nft && state.nft.coleccion && state.nft.coleccion.url_foto_firma_artista)} alt={"Firma"} />
                                    {/*<div className="meta-item-details style2" hidden={true}>
                                            <div className="item meta-price">
                                                <span className="heading">Oferta actual</span>
                                                <div className="price">
                                                    <div className="price-box">
                                                        <h5> 4.89 ETH</h5>
                                                        <span>= $12.246</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="item count-down">
                                                <span className="heading style-2">Cuenta regresiva</span>
                                                <Countdown date={Date.now() + 500000000}>
                                                    <span>You are good to go!</span>
                                                </Countdown>
                                            </div>
                                            </div>*/}

                                    <button className="sc-button loadmore style fl-button pri-3" style={{ width: "100%" }} onClick={openModal} hidden={settings.env === "PRO" || (!state.usuario || !state.nft || !state.nft.comprador || state.nft.comprador._id !== state.usuario._id)}><span>Generar QR de Acceso</span></button>
                                    <a href={state.nft && state.nft.coleccion && (settings.url.opensea + "assets/" + Util.getBlockchainNameOpenSea(state.nft.coleccion.red) + "/" + state.direccion_smart_contract + "/" + state.nft_id)} target="_blank" rel="noreferrer" className="sc-button loadmore style fl-button pri-3"><span>Ver en OpenSea</span></a>
                                    <a href={state.nft && state.nft.tokenURI} target="_blank" rel="noreferrer" className="sc-button loadmore style fl-button pri-3" hidden={true}><span>Ver metadatos</span></a>
                                    {/*
                                            (<Link to="/wallet-connect" className="sc-button loadmore style bag fl-button pri-3"><span>Hacer una oferta</span></Link>)
                                            */}
                                    {/*<div className="flat-tabs themesflat-tabs">
                                            <Tabs>
                                                <TabList>
                                                    <Tab>Historial de ofertas</Tab>
                                                    <Tab>Info</Tab>
                                                    <Tab>Procedencia</Tab>
                                                </TabList>

                                                <TabPanel>
                                                    <ul className="bid-history-list">
                                                        {
                                                            dataHistory.map((item, index) => (
                                                                <li key={index} item={item}>
                                                                    <div className="content">
                                                                        <div className="client">
                                                                            <div className="sc-author-box style-2">
                                                                                <div className="author-avatar">
                                                                                    <Link to="#">
                                                                                        <img src={item.img} alt="Axies" className="avatar" />
                                                                                    </Link>
                                                                                    <div className="badge"></div>
                                                                                </div>
                                                                                <div className="author-infor">
                                                                                    <div className="name">
                                                                                        <h6><Link to="/author-02">{item.name} </Link></h6> <span> place a bid</span>
                                                                                    </div>
                                                                                    <span className="time">{item.time}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="price">
                                                                            <h5>{item.price}</h5>
                                                                            <span>= {item.priceChange}</span>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                </TabPanel>
                                                <TabPanel>
                                                    <ul className="bid-history-list">
                                                        <li>
                                                            <div className="content">
                                                                <div className="client">
                                                                    <div className="sc-author-box style-2">
                                                                        <div className="author-avatar">
                                                                            <Link to="#">
                                                                                <img src={img1} alt="Axies" className="avatar" />
                                                                            </Link>
                                                                            <div className="badge"></div>
                                                                        </div>
                                                                        <div className="author-infor">
                                                                            <div className="name">
                                                                                <h6> <Link to="/author-02">Mason Woodward </Link></h6> <span> place a bid</span>
                                                                            </div>
                                                                            <span className="time">8 hours ago</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </TabPanel>
                                                <TabPanel>
                                                    <div className="provenance">
                                                        <p>{state.nft.descripcion}</p>
                                                    </div>
                                                </TabPanel>
                                            </Tabs>
                                                    </div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div >
    );
}

export default ItemDetails01;
