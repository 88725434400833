

import img1 from '../images/slider/slide_1.png';
import img2 from '../images/slider/slide_2.png';
import imgbg1 from '../images/slider/bg_slide_1.png'
import imgbg2 from '../images/slider/bg_slide_1.png'

const heroSliderData = [
    {
        title_1: "Asesoría, creación, ",
        title_2: "galerías virtuales",
        title_3: " y canjeo de NFTs",
        description: "Somos tus aliados para aprovechar al máximo esta tecnología",
        img: img2,
        imgbg: imgbg2,
        class:'right',
        href: '/contact',
        CTA: 'Contáctanos'
    },
    {
        title_1: "Comienza a vivir de ",
        title_2: "tu arte ",
        description: "Nosotros te ayudamos a crear tus NFTs",
        img: img1,
        imgbg: imgbg1,
        class:'left',
        href: '/contact',
        CTA: 'Contáctanos'
    },
    {
        title_1: "Descubre y colecciona ",
        title_2: "NFTs ",
        title_3: " ",
        description: "El primer marketplace de NFT 100% mexicano",
        class:'center',
        href: '/explore',
        CTA: 'Explorar colecciones'
    },
    
]

export default heroSliderData;