import React, { useState, useEffect } from "react";
import swal from 'sweetalert2';
import { Link } from 'react-router-dom'
import Resources from "../util/resources";
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import 'datatables.net-responsive';
import 'datatables.net-select';
import 'datatables.net-buttons-bs4';
import 'datatables.net-buttons/js/buttons.colVis';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.flash';
import 'datatables.net-buttons/js/buttons.print';

import 'react-tabs/style/react-tabs.css';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';

import "bootstrap-icons/font/bootstrap-icons.css";
import "./EventReports.css";

import useLoading from '../hooks/useLoading';

import Api from "../util/api";
import Util from "../util/util";

const EventReports = () => {
    const { startLoading, stopLoading } = useLoading();
    const [state, setState] = useState({
        selectedIndex: 0,
        evento: {},
        tiposTicket: [],
        patrocinadores: [],
        ordenes: [],
        eventoId: "",
        error: null,
        loading: false,
        metamaskPublicKey: null,
        loadingTicket: false,
    });

    useEffect(() => {

        //falta validar permisos de usuario


        const consultarEvento = async (eventoId) => {
            console.log('consultar');
            try {
                startLoading();
                var res = await Api.eventos.get(eventoId);
                var data = await res.json();
                var evento = data.evento;
                var nfts = data.nfts;
                console.log(data);
                if (await Util.requestSuccess(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        nfts,
                        evento,
                        eventoId
                    }));
                } else {
                    console.log(data.msg);
                    await swal.fire("Ups!", "Error al consultar evento " + data.msg, "error");

                }
                await consultarOrdenesCompra(evento);
            } catch (e) {
                console.log(e);
                await swal.fire("Ups!", "Error al consultar evento", "error");
            }
            stopLoading();
        };

        const consultarOrdenesCompra = async (evento) => {
            console.log('consultar');
            try {
                startLoading();
                var res = await Api.ordenCompra.getOrdenesEvento(evento._id);
                var data = await res.json();
                var ordenes = data.ordenes;
                console.log(data);
                if (await Util.requestSuccess(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        ordenes
                    }));
                    if (!state.datatableAlready) initDatatableVentas(evento);
                } else {
                    console.log(data.msg);
                    await swal.fire("Ups!", "Error al consultar información " + data.msg, "error");

                }
            } catch (e) {
                console.log(e);
                await swal.fire("Ups!", "Error al consultar información", "error");
            }
            stopLoading();
        };



        if (state.eventoId.length === 0) {
            var initialId = window.location.pathname.replace("/event/reports/", "");
            if (initialId.length > 0) {
                consultarEvento(initialId.replace("/", ""));

                setState((prevState) => ({
                    ...prevState,
                    eventoId: initialId
                }));
            }
        }
    }, []);

    function getFotoPortadaColeccion() {
        if (state.evento && state.evento.url_foto_portada)
            return {
                background: 'url("' + state.evento.url_foto_portada + '")',
                backgroundPosition: "center",
                filter: 'blur(8px)',
                height: "500px",
                backgroundRepeat: "no-repeat"
            };
        return {};
    }

    function getTotalRecaudadoOrdenesCompra() {
        var totalRecaudado = 0;
        for (var i = 0; i < state.ordenes.length; i++) {
            totalRecaudado += state.ordenes[i].monto_total;
        }
        return totalRecaudado;
    }

    function getCantidadBoletosVendidos() {
        var totalBoletosVendidos = 0;
        for (var i = 0; i < state.ordenes.length; i++) {
            for (var j = 0; j < state.ordenes[i].items.length; j++) {
                totalBoletosVendidos += state.ordenes[i].items[j].cantidad;
            }
        }
        return totalBoletosVendidos;
    }

    function initDatatableVentas(evento) {
        const tableVentas = $("#dynamic_table").DataTable({
            ...Resources.datatableDefaultConfig,
            dom: 'Blfrtip',
            autoWidth: true,
            buttons: [
                {
                    extend: 'copyHtml5',
                },
                {
                    extend: 'excelHtml5',
                    title: 'Ventas de ' + evento.titulo,
                },
                {
                    extend: 'pdfHtml5',
                    title: 'Ventas de ' + evento.titulo,
                }
            ],
        });


    }


    return (
        <div className='explore'>
            <Header />
            <section className="flat-title-page inner" style={getFotoPortadaColeccion()}>
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">{(state.evento && state.evento.titulo) || ""}</h1>
                            </div>
                            <div className="breadcrumbs style2" hidden={true}>
                                <ul>
                                    <li><Link to={"/" + (state.evento && state.evento.creador && state.evento.creador.nombre) || ""}>{(state.evento && state.evento.creador && state.evento.creador.nombre) || ""}</Link></li>
                                    <li>{(state.evento && state.evento.titulo) || ""}</li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="tf-section popular-collection bg-home-3">
                <div className="container">
                    <div className="row">
                        <div className="mb-4 col-md-12">
                            <h2 className="heading text-center mt-4 mb-4">{(state.evento && state.evento.titulo) || ""}</h2>
                            <p hidden={!state.evento.nombre_lugar}><i className="bi bi-geo-alt mr-4"></i>{state.evento.nombre_lugar}</p>
                            <p hidden={!state.evento.fecha_inicio_evento}> <i className="bi bi-calendar mr-4"></i>{Util.formatDateTimeShort(state.evento.fecha_inicio_evento)} al {Util.formatDateTimeShort(state.evento.fecha_fin_evento)}</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="tf-help-center tf-section">
                <div className="themesflat-container">
                    <div style={{ textAlign: 'center' }}>
                        <h4 className="heading mb-3">Resumen</h4>
                    </div>
                    <div className="sc-box-icon-inner style-3">
                        <div className='sc-box-icon ml-0 col-6 col-md'>
                            <h4 className="heading">Ventas totales</h4>
                            <h3 className="heading">{state.ordenes?.length}</h3>
                            <p className="content" hidden={true}>Descripción</p>
                        </div>

                        <div className='sc-box-icon ml-0 col-6 col-md'>
                            <h4 className="heading">Tickets vendidos</h4>
                            <h3 className="heading">{getCantidadBoletosVendidos()}</h3>
                            <p className="content" hidden={true}>Descripción</p>
                        </div>

                        <div className='sc-box-icon ml-0 col-6 col-md'>
                            <h4 className="heading">Ventas totales</h4>
                            <h3 className="heading">${new Intl.NumberFormat('en-US').format(Util.getTotalRecaudadoOrdenesCompra(state.ordenes))} {state.evento.moneda}</h3>
                            <p className="content" hidden={true}>Descripción</p>
                        </div>

                        <div className='sc-box-icon ml-0 col-6 col-md'>
                            <h4 className="heading">Ingreso neto</h4>
                            <h3 className="heading">${new Intl.NumberFormat('en-US').format(Util.getTotalRecaudadoOrdenesCompra(state.ordenes) * 0.9)} {state.evento.moneda}</h3>
                            <p className="content" hidden={true}>Descripción</p>
                        </div>

                        <div className='sc-box-icon ml-0 col-6 col-md'>
                            <h4 className="heading">Cargos por servicio</h4>
                            <h3 className="heading">${new Intl.NumberFormat('en-US').format(Util.getTotalRecaudadoOrdenesCompra(state.ordenes) * 0.1)} {state.evento.moneda}</h3>
                            <p className="content" hidden={true}>Descripción</p>
                        </div>

                    </div>
                </div>
            </section>
            {state.ordenes && state.ordenes.length > 0 && (
                <section className="tf-help-center tf-section" >
                    <div className="themesflat-container">
                        <div style={{ textAlign: 'center' }}>
                            <h4 className="heading">Lista de ventas</h4>
                        </div>
                        <div style={{ all: 'unset' }}>
                            <table id="dynamic_table" className="" style={{ "width": "100%", "textAlign": "center", fontSize: "38px" }}>
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: "center" }}><p>Fecha</p></th>
                                        <th style={{ textAlign: "center" }}><p>Monto total</p></th>
                                        <th style={{ textAlign: "center" }}><p>Ingreso neto</p></th>
                                        <th style={{ textAlign: "center" }}><p>Cargos por servicio</p></th>
                                        <th style={{ textAlign: "center" }}><p>Email comprador</p></th>
                                        <th style={{ textAlign: "center" }}><p>Nombre comprador</p></th>
                                        <th style={{ textAlign: "center" }}><p>Estado</p></th>

                                    </tr>
                                </thead>
                                <tbody id="ventasContainer" style={{ "width": "100%" }}>
                                    {
                                        state.ordenes.map((item, index) => (
                                            <tr id={"item" + item._id} key={"item" + item._id}>
                                                <td><p>{Util.formatDate(item.fecha_registro)} </p></td>
                                                <td><p>${new Intl.NumberFormat('en-US').format(item.monto_total)}</p> </td>
                                                <td><p>${new Intl.NumberFormat('en-US').format(item.monto_total * 0.9)}</p> </td>
                                                <td><p>${new Intl.NumberFormat('en-US').format(item.monto_total * 0.1)}</p></td>
                                                <td><p>{item.email_comprador}</p></td>
                                                <td><p>{item.nombre}</p></td>
                                                <td><p>{item.status}</p></td>
                                                

                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </section>
            )}
            <Footer />
        </div >
    );
}

export default EventReports;
