import React, { useState, useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import swal from 'sweetalert2';
import Api from '../../util/api';
import Util from "../../util/util";

import '../input/CheckboxInfo.css';
import useLoading from '../../hooks/useLoading';
import SelectCheckbox from '../input/SelectCheckbox';


function ModalCrearEditarStaff(props) {
    const { startLoading, stopLoading } = useLoading();
    const [state, setState] = useState({
        staff: {
            totalidad_eventos: false,
            roles: [],
            usuario: {}
        }
    });

    useEffect(async () => {
        setState((prevState) => ({
            ...prevState,
            staff: props.staff
        }));

    }, [props.staff]);


    useEffect(() => {

        //falta validar permisos de usuario
        const consultarEventosUsuario = async () => { //Las cargas iniciales en un modal no requieren mostrar Loading
            try {
                var res = await Api.eventos.listUsuario();
                var data = await res.json();
                var eventos = data.eventos;
                console.log(data);
                if (await Util.requestSuccess(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        eventos,
                    }));

                } else {
                    console.log(data.msg);

                }
                //await consultarOrdenesCompra(evento._id);
            } catch (e) {
                console.log(e);
                await swal.fire("Ups!", "Error al consultar evento", "error");
            }
        };



        consultarEventosUsuario();
    }, []);

    const handleChange = (e) => {
        var { id, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            staff: {
                ...prevState.staff,
                usuario: {
                    ...prevState.staff?.usuario,
                    [id]: value,
                },
            }

        }));

        console.log(state.staff);
    };

    const handleChangeCheck = (e) => {
        var { id, value } = e.target;

        setState((prevState) => ({
            ...prevState,
            staff: {
                ...prevState.staff,
                totalidad_eventos: !prevState.staff.totalidad_eventos,
            }

        }));
    };

    const handleChangeCheckRoles = (e) => {
        var { id, value } = e.target;

        if (state.staff?.roles?.includes(id)) {
            setState((prevState) => ({
                ...prevState,
                staff: {
                    ...prevState.staff,
                    roles: state.staff.roles.filter(item => item !== id),
                }

            }));
        } else {
            var arrayTemp = state.staff.roles;
            arrayTemp.push(id);
            setState((prevState) => ({
                ...prevState,
                staff: {
                    ...prevState.staff,
                    roles: arrayTemp,
                }

            }));
        }
    };




    const OnChangeEvento = (eventos) => {
        setState((prevState) => ({
            ...prevState,
            staff:{
                eventos
            }
        }));
    };

    const setCrearEditarStaff = async (e) => {
        startLoading();
        if (e) e.preventDefault();
        /*if (!state.staff?.usuario?.nombre) {
            await swal.fire("Ups!", "Es necesario agregar un nombre para el nuevo integrante", "error");
            return;
        }

        if (!state.staff?.usuario?.apellido) {
            await swal.fire("Ups!", "Es necesario agregar un apellido para el nuevo integrante", "error");
            return;
        }*/

        if (!state.staff?.usuario?.email) {
            await swal.fire("Ups!", "Es necesario agregar un email para el nuevo integrante", "error");
            return;
        }

        try {
            var res;
            //props.startLoading();
            if (state.staff._id) {
                res = await Api.staff.actualizar(state.staff._id, state.staff);
            } else {
                res = await Api.staff.crear(state.staff);
            }

            var data = await res.json();
            console.log(data);
            if (await Util.requestSuccess(res)) {
                await swal.fire("Listo!", "La información fue guardada con éxito", "success");
                props.consultarTeam();
                props.onHide();
                //window.location.reload();
            } else {
                console.log(data.msg);
                return;
            }
        } catch (e) {
            console.log(e);
            await swal.fire("Ups!", "Error al actualizar información", "error");
        }
        stopLoading();
    }

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


    return (
        <div className="CrearEditarTicket">
            <Modal {...props} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{(props.staff === null ? "Crear " : "Editar ")}Staff</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row" >
                        <div className="col-xl-12 col-lg-12 col-md-12 col-12">

                            <div className="form-inner">
                                <form action="#" id="contactform">
                                    <div className="row" >
                                        <div className="col-md-6 col-12">
                                            <h4 className="title-create-item">Nombre(s)</h4>
                                            <input type="text" onChange={handleChange} id="nombre" value={state.staff?.usuario?.nombre || ""} />
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <h4 className="title-create-item">Apellido(s)</h4>
                                            <input type="text" onChange={handleChange} id="apellido" value={state.staff?.usuario?.apellido || ""} />
                                        </div>
                                    </div>
                                    <h4 className="title-create-item">Email</h4>
                                    <input type="text" onChange={handleChange} id="email" value={state.staff?.usuario?.email || ""} />

                                    <div className='row'>
                                        <div className='col'>
                                            <h4 className="title-create-item">Eventos</h4>
                                            <div>

                                                <label className="checkbox-container">
                                                <input
                                                    type="checkbox"
                                                    id="totalidad_eventos"
                                                    checked={state.staff?.totalidad_eventos}
                                                    onChange={handleChangeCheck}
                                                />
                                                <span className="checkmark"></span>
                                                <div className="info">
                                                    <div className="title">Todos mis eventos</div>
                                                    <div className="subtitle">Incluye los eventos futuros</div>
                                                </div>
                                            </label>
                                            </div>
                                            {!state.staff?.totalidad_eventos && (
                                                <div>
                                                    <hr className='mt-0' />
                                                    <SelectCheckbox OnChangeEvento={OnChangeEvento} opciones={state.eventos} staff={state.staff}/>
                                                </div>
                                            )}
                                        </div>
                                        <div className='col'>
                                            <h4 className="title-create-item">Roles</h4>
                                            <label className="checkbox-container">
                                                <input
                                                    type="checkbox"
                                                    checked={state.staff?.roles?.includes("Checkin")}
                                                    onChange={handleChangeCheckRoles}
                                                    id="Checkin"
                                                />
                                                <span className="checkmark"></span>
                                                <div className="info">
                                                    <div className="title">Checkin</div>
                                                    <div className="subtitle">Escanea tickets en la entrada del evento</div>
                                                </div>
                                            </label>
                                            <label className="checkbox-container">
                                                <input
                                                    type="checkbox"
                                                    checked={state.staff?.roles?.includes("Editor")}
                                                    onChange={handleChangeCheckRoles}
                                                    id="Editor"
                                                />
                                                <span className="checkmark"></span>
                                                <div className="info">
                                                    <div className="title">Editor</div>
                                                    <div className="subtitle">Gestiona información del evento y los tickets</div>
                                                </div>
                                            </label>
                                            <label className="checkbox-container">
                                                <input
                                                    type="checkbox"
                                                    checked={state.staff?.roles?.includes("Analista")}
                                                    onChange={handleChangeCheckRoles}
                                                    id="Analista"
                                                />
                                                <span className="checkmark"></span>
                                                <div className="info">
                                                    <div className="title">Analista</div>
                                                    <div className="subtitle">Consulta reportes de ventas</div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>


                                    <button onClick={setCrearEditarStaff} className="mb-4">Guardar</button>


                                </form>
                            </div>

                        </div>
                    </div>


                </Modal.Body>
            </Modal>
        </div>
    );
}
export default ModalCrearEditarStaff;
