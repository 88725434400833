import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { UserProvider } from './context/UserContext';
import { AuthProvider } from './context/AuthContext';
import { LoadingProvider } from './context/LoadingContext';
import { BrowserRouter } from 'react-router-dom'
import ScrollToTop from './ScrollToTop';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Settings from "./config/settings";


if (Settings.env === 'PRO') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
}


ReactDOM.render(
  <BrowserRouter >
    <ScrollToTop />
    <GoogleOAuthProvider clientId={Settings.auth.google_oauth_client_id}>
      <UserProvider>
        <LoadingProvider>
          <AuthProvider>
            <App />
          </AuthProvider>
        </LoadingProvider>
      </UserProvider>
    </GoogleOAuthProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
