import React, { useRef, useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import swal from 'sweetalert2';
import UtilEth from '../../util/eth';

import { googleLogout } from '@react-oauth/google';

import Settings from "../../config/settings";
import menus from "../../pages/menu";
import DarkMode from './DarkMode';
import logoheader from '../../assets/images/logo/logo.png'
import logoheader2x from '../../assets/images/logo/logo@2x.png'
import logodark from '../../assets/images/logo/logo_dark.png'
import logodark2x from '../../assets/images/logo/logo_dark@2x.png'
import avt from '../../assets/images/avatar/avt-2.jpg'

import useUser from '../../hooks/useUser';
import useAuth from '../../hooks/useAuth';

const Header = () => {
    const [state, setState] = useState({
        errorMessage: null,
        userBalance: null,
        connButtonText: null,
        trimmedDataURL: null,
        defaultAccount: ""
    });
    const { pathname } = useLocation();

    const { stateApp, setStateApp } = useUser();

    const { logout, isAuthenticated } = useAuth();

    const headerRef = useRef(null)
    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });
    const isSticky = (e) => {
        const header = document.querySelector('.js-header');
        const scrollTop = window.scrollY;
        scrollTop >= 300 ? header.classList.add('is-fixed') : header.classList.remove('is-fixed');
        scrollTop >= 400 ? header.classList.add('is-small') : header.classList.remove('is-small');
    };

    const menuLeft = useRef(null)
    const btnToggle = useRef(null)
    const btnSearch = useRef(null)

    const menuToggle = () => {
        menuLeft.current.classList.toggle('active');
        btnToggle.current.classList.toggle('active');
    }

    const searchBtn = () => {
        btnSearch.current.classList.toggle('active');
    }

    const [activeIndex, setActiveIndex] = useState(null);
    const handleOnClick = index => {
        setActiveIndex(index);
    };

    const accountChangedHandler = async (newAccount) => {
        /*console.log('newAccount');
        console.log(newAccount);*/
        //const userBalance = await UtilEth.getUserBalance(newAccount.toString());
        setStateApp((prevState) => ({
            ...prevState,
            llave_publica_wallet: newAccount[0],
            //userBalance
        }));
    }

    const chainChangedHandler = async (networkId) => {
        if (sessionStorage?.getItem("isWalletConnected") === "true") {
            try {
                networkId = String(networkId).replace("0x", "");
                if (networkId !== Settings.eth.ethereum_network_id) {
                    swal.fire("Ups!", "Esta plataforma opera con la red " + Settings.eth.ethereum_network_name + ". Para hacer una compra o canjeo, es necesario que primero hagas el cambio de red en Metamask", "error");
                    window.ethereum.close();
                    sessionStorage.setItem("isWalletConnected", false);
                    return;
                }
            } catch (e) {
                console.log(e);
            }
        }
    }

    if (window.ethereum) {
        window.ethereum.on('accountsChanged', accountChangedHandler);
        window.ethereum.on('chainChanged', chainChangedHandler);
    }

    async function connectWalletHandler() {
        await UtilEth.connectWalletHandler(setState);
        const wallet = await UtilEth.getMetamaskWallet();
        if (!wallet) {
            setStateApp((prevState) => ({
                ...prevState,
                llave_publica_wallet: null,
                isWalletConnected: false
            }));
            return;
        }
        setStateApp((prevState) => ({
            ...prevState,
            llave_publica_wallet: wallet,
            isWalletConnected: true
        }));
        /*if (props.handleMetamaskPublicKey)
            props.handleMetamaskPublicKey(wallet);*/
    }

    async function cerrarSesion() {
        googleLogout();
        logout();
        if (stateApp.isWalletConnected) await connectWalletHandler();
        setStateApp((prevState) => ({
            ...prevState,
            usuario: null,
            isWalletConnected: false,
            llave_publica_wallet: null
        }));
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = "/";
    }

    return (
        <header id="header_main" className="header_1 js-header" ref={headerRef}>
            <div className="themesflat-container">
                <div className="row">
                    <div className="col-md-12">
                        <div id="site-header-inner">
                            <div className="wrap-box flex">
                                <div id="site-logo" className="clearfix">
                                    <div id="site-logo-inner">
                                        <Link to="/" rel="home" className="main-logo">
                                            <img className='logo-dark' id="logo_header" src={logodark} srcSet={`${logodark2x}`} alt="LifeChain NFT" />
                                            <img className='logo-light' id="logo_header" src={logoheader} srcSet={`${logoheader2x}`} alt="LifeChain NFT" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="mobile-button" ref={btnToggle} onClick={menuToggle}><span></span></div>
                                <nav id="main-nav" className="main-nav" ref={menuLeft} >
                                    <ul id="menu-primary-menu" className="menu">
                                        {
                                            menus.menusNormal.map((data, index) => (
                                                <li key={index} onClick={() => handleOnClick(index)} className={`menu-item ${data.namesub ? 'menu-item-has-children' : ''} ${activeIndex === index ? 'active' : ''} `}   >
                                                    <a href={data.links}>{data.name}</a>
                                                    {
                                                        data.namesub &&
                                                        <ul className="sub-menu" >
                                                            {
                                                                data.namesub.map((submenu) => (
                                                                    <li key={submenu.id} onClick={submenu.onClick} className={
                                                                        pathname === submenu.links
                                                                            ? "menu-item current-item"
                                                                            : "menu-item"
                                                                    }><Link to={submenu.links}>{submenu.sub}</Link></li>
                                                                ))
                                                            }
                                                        </ul>
                                                    }

                                                </li>
                                            ))
                                        }
                                        {isAuthenticated ? (
                                            <li key={"menuAuthenticated"} onClick={() => handleOnClick(menus.menusAuthenticated.index)} className={`menu-item ${menus.menusAuthenticated.namesub ? 'menu-item-has-children' : ''} ${activeIndex === menus.menusAuthenticated.index ? 'active' : ''} `}   >
                                                <a href={menus.menusAuthenticated.links}>{menus.menusAuthenticated.name}</a>
                                                {
                                                    menus.menusAuthenticated.namesub &&
                                                    <ul className="sub-menu" >
                                                        {
                                                            menus.menusAuthenticated.namesub.map((submenu) => (
                                                                <li key={submenu.id} onClick={submenu.onClick} className={
                                                                    pathname === submenu.links
                                                                        ? "menu-item current-item"
                                                                        : "menu-item"
                                                                }><Link to={submenu.links}>{submenu.sub}</Link></li>
                                                            ))
                                                        }
                                                    </ul>
                                                }

                                            </li>
                                        ) : (
                                            <li key={"menuWithoutSession"} onClick={() => handleOnClick(menus.menusWithoutSession.index)} className={`menu-item ${menus.menusWithoutSession.namesub ? 'menu-item-has-children' : ''} ${activeIndex === menus.menusWithoutSession.index ? 'active' : ''} `}   >
                                                <a href={menus.menusWithoutSession.links}>{menus.menusWithoutSession.name}</a>
                                                {
                                                    menus.menusWithoutSession.namesub &&
                                                    <ul className="sub-menu" >
                                                        {
                                                            menus.menusWithoutSession.namesub.map((submenu) => (
                                                                <li key={submenu.id} onClick={submenu.onClick} className={
                                                                    pathname === submenu.links
                                                                        ? "menu-item current-item"
                                                                        : "menu-item"
                                                                }><Link to={submenu.links}>{submenu.sub}</Link></li>
                                                            ))
                                                        }
                                                    </ul>
                                                }

                                            </li>
                                        )}
                                    </ul>
                                </nav>
                                <div className="flat-search-btn flex connect-wal">
                                    <div className="header-search flat-show-search" id="s1" hidden={true}>
                                        <Link to="#" className="show-search header-search-trigger" onClick={searchBtn} >
                                            <i className="far fa-search"></i>
                                        </Link>
                                        <div className="top-search" ref={btnSearch} >
                                            <form action="#" method="get" role="search" className="search-form">
                                                <input type="search" id="s" className="search-field" placeholder="Search..." name="s" title="Search for" required="" />
                                                <button className="search search-submit" type="submit" title="Search">
                                                    <i className="icon-fl-search-filled"></i>
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="sc-btn-top mg-r-12" id="site-header" hidden={stateApp.usuario === null || true}>
                                        <button onClick={connectWalletHandler} className="sc-button header-slider style style-1 wallet fl-button pri-1"><span>{(stateApp.isWalletConnected && "Desconectar wallet") || "Conectar wallet"}
                                        </span></button>
                                    </div>

                                    <div className="admin_active" id="header_admin">
                                        <div className="header_avatar">
                                            <div className="price" hidden={true}>
                                                <span>{state.userBalance || 0} <strong>ETH</strong> </span>
                                            </div>
                                            <img
                                                className="avatar"
                                                src={stateApp.usuario?.foto_perfil || avt}
                                                alt="avatar"
                                                hidden={stateApp.usuario === null}
                                            />
                                            <div className="avatar_popup mt-20">
                                                <div className="d-flex align-items-center copy-text justify-content-between ">
                                                    <span> {(state.defaultAccount && state.defaultAccount.substring(0, 15) + "...") || "0"} </span>
                                                    <Link to="/" className="ml-2">
                                                        <i className="fal fa-copy"></i>
                                                    </Link>
                                                </div>
                                                <div className="d-flex align-items-center mt-10">
                                                    <div className="info ml-10">
                                                        <p className="text-sm font-book text-gray-400" style={{ color: "black" }}>Balance</p>
                                                        <p className="w-full text-sm font-bold text-green-500" style={{ color: "black" }}>{state.userBalance || 0} ETH</p>
                                                    </div>
                                                </div>
                                                <div className="hr"></div>
                                                <div className="links mt-2">
                                                    <Link to={(stateApp.usuario && "/" + stateApp.usuario.nombre) || "/"}>
                                                        <i className="fab fa-accusoft"></i> <span> Mis coleccionables</span>
                                                    </Link>
                                                    <Link className="mt-10" to="/edit-profile">
                                                        <i className="fas fa-pencil-alt"></i> <span> Editar Perfil</span>
                                                    </Link>
                                                    <Link className="mt-10" to="/login" onClick={cerrarSesion} id="logout">
                                                        <i className="fal fa-sign-out"></i> <span> Cerrar Sesión</span>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DarkMode />
        </header>
    );
}

export default Header;
