const usosHorarios = [
{value:"Pacific/Niue", name: "-11:00 Niue Time - Alofi", default: ""},
{value:"Pacific/Midway", name: "-11:00 Samoa Time - Midway", default: ""},
{value:"Pacific/Pago_Pago", name: "-11:00 Samoa Time - Pago Pago", default: ""},
{value:"Pacific/Rarotonga", name: "-10:00 Cook Islands Time - Avarua", default: ""},
{value:"America/Adak", name: "-10:00 Hawaii-Aleutian Time - Adak", default: ""},
{value:"Pacific/Honolulu", name: "-10:00 Hawaii-Aleutian Time - Honolulu, East Honolulu, Pearl City, Hilo", default: ""},
{value:"Pacific/Tahiti", name: "-10:00 Tahiti Time - Faaa, Papeete, Punaauia", default: ""},
{value:"Pacific/Marquesas", name: "-09:30 Marquesas Time - Marquesas", default: ""},
{value:"America/Anchorage", name: "-09:00 Alaska Time - Anchorage, Juneau, Fairbanks, Eagle River", default: ""},
{value:"Pacific/Gambier", name: "-09:00 Gambier Time - Gambier", default: ""},
{value:"America/Los_Angeles", name: "-08:00 Pacific Time - Los Angeles, San Diego, San Jose, San Francisco", default: ""},
{value:"America/Tijuana", name: "-08:00 Pacific Time - Tijuana, Mexicali, Ensenada, Rosarito", default: ""},
{value:"America/Vancouver", name: "-08:00 Pacific Time - Vancouver, Surrey, Okanagan, Victoria", default: ""},
{value:"Pacific/Pitcairn", name: "-08:00 Pitcairn Time - Adamstown", default: ""},
{value:"America/Hermosillo", name: "-07:00 Mexican Pacific Time - Hermosillo, Ciudad Obregón, Nogales, San Luis Río Colorado", default: ""},
{value:"America/Edmonton", name: "-07:00 Mountain Time - Calgary, Edmonton, Red Deer, Sherwood Park", default: ""},
{value:"America/Denver", name: "-07:00 Mountain Time - Denver, El Paso, Albuquerque, Colorado Springs", default: ""},
{value:"America/Phoenix", name: "-07:00 Mountain Time - Phoenix, Tucson, Mesa, Chandler", default: ""},
{value:"America/Whitehorse", name: "-07:00 Yukon Time - Whitehorse, Fort St. John, Creston, Dawson", default: ""},
{value:"America/Belize", name: "-06:00 Central Time - Belize City, San Ignacio, San Pedro, Orange Walk", default: ""},
{value:"America/Chicago", name: "-06:00 Central Time - Chicago, Houston, San Antonio, Dallas", default: ""},
{value:"America/Guatemala", name: "-06:00 Central Time - Guatemala City, Mixco, Villa Nueva, Petapa", default: ""},
{value:"America/Managua", name: "-06:00 Central Time - Managua, León, Masaya, Chinandega", default: ""},
{value:"America/Mexico_City", name: "-06:00 Central Time - Mexico City, Iztapalapa, Ecatepec de Morelos, Guadalajara", default: "default"},
{value:"America/Costa_Rica", name: "-06:00 Central Time - San José, Limón, San Francisco, Alajuela", default: ""},
{value:"America/El_Salvador", name: "-06:00 Central Time - San Salvador, Soyapango, Santa Ana, San Miguel", default: ""},
{value:"America/Regina", name: "-06:00 Central Time - Saskatoon, Regina, Prince Albert, Moose Jaw", default: ""},
{value:"America/Tegucigalpa", name: "-06:00 Central Time - Tegucigalpa, San Pedro Sula, Choloma, La Ceiba", default: ""},
{value:"America/Winnipeg", name: "-06:00 Central Time - Winnipeg, Brandon, Steinbach, Kenora", default: ""},
{value:"Pacific/Galapagos", name: "-06:00 Galapagos Time - Galapagos", default: ""},
{value:"America/Ojinaga", name: "-06:00 Mountain Time - Ciudad Juárez, Chihuahua, Culiacán, Mazatlán", default: ""},
{value:"America/Rio_Branco", name: "-05:00 Acre Time - Rio Branco, Cruzeiro do Sul, Sena Madureira, Eirunepé", default: ""},
{value:"America/Bogota", name: "-05:00 Colombia Time - Bogotá, Cali, Medellín, Barranquilla", default: ""},
{value:"America/Havana", name: "-05:00 Cuba Time - Havana, Santiago de Cuba, Camagüey, Holguín", default: ""},
{value:"Pacific/Easter", name: "-05:00 Easter Island Time - Easter", default: ""},
{value:"America/Atikokan", name: "-05:00 Eastern Time - Atikokan", default: ""},
{value:"America/Cancun", name: "-05:00 Eastern Time - Cancún, Chetumal, Playa del Carmen, Cozumel", default: ""},
{value:"America/Grand_Turk", name: "-05:00 Eastern Time - Cockburn Town", default: ""},
{value:"America/Cayman", name: "-05:00 Eastern Time - George Town", default: ""},
{value:"America/Jamaica", name: "-05:00 Eastern Time - Kingston, New Kingston, Spanish Town, Portmore", default: ""},
{value:"America/Nassau", name: "-05:00 Eastern Time - Nassau, Lucaya, Freeport", default: ""},
{value:"America/New_York", name: "-05:00 Eastern Time - New York City, Brooklyn, Queens, Philadelphia", default: ""},
{value:"America/Panama", name: "-05:00 Eastern Time - Panamá, San Miguelito, Juan Díaz, David", default: ""},
{value:"America/Port-au-Prince", name: "-05:00 Eastern Time - Port-au-Prince, Carrefour, Delmas 73, Pétionville", default: ""},
{value:"America/Toronto", name: "-05:00 Eastern Time - Toronto, Montréal, Ottawa, Mississauga", default: ""},
{value:"America/Guayaquil", name: "-05:00 Ecuador Time - Guayaquil, Quito, Cuenca, Santo Domingo de los Colorados", default: ""},
{value:"America/Lima", name: "-05:00 Peru Time - Lima, Arequipa, Callao, Trujillo", default: ""},
{value:"America/Manaus", name: "-04:00 Amazon Time - Manaus, Campo Grande, Cuiabá, Porto Velho", default: ""},
{value:"America/St_Kitts", name: "-04:00 Atlantic Time - Basseterre", default: ""},
{value:"America/Blanc-Sablon", name: "-04:00 Atlantic Time - Blanc-Sablon", default: ""},
{value:"America/Montserrat", name: "-04:00 Atlantic Time - Brades, Plymouth", default: ""},
{value:"America/Barbados", name: "-04:00 Atlantic Time - Bridgetown", default: ""},
{value:"America/St_Lucia", name: "-04:00 Atlantic Time - Castries", default: ""},
{value:"America/Port_of_Spain", name: "-04:00 Atlantic Time - Chaguanas, Mon Repos, San Fernando, Port of Spain", default: ""},
{value:"America/Martinique", name: "-04:00 Atlantic Time - Fort-de-France, Le Lamentin, Le Robert, Sainte-Marie", default: ""},
{value:"America/St_Barthelemy", name: "-04:00 Atlantic Time - Gustavia", default: ""},
{value:"America/Halifax", name: "-04:00 Atlantic Time - Halifax, Moncton, Sydney, Dartmouth", default: ""},
{value:"Atlantic/Bermuda", name: "-04:00 Atlantic Time - Hamilton", default: ""},
{value:"America/St_Vincent", name: "-04:00 Atlantic Time - Kingstown, Kingstown Park", default: ""},
{value:"America/Kralendijk", name: "-04:00 Atlantic Time - Kralendijk", default: ""},
{value:"America/Guadeloupe", name: "-04:00 Atlantic Time - Les Abymes, Baie-Mahault, Le Gosier, Petit-Bourg", default: ""},
{value:"America/Marigot", name: "-04:00 Atlantic Time - Marigot", default: ""},
{value:"America/Aruba", name: "-04:00 Atlantic Time - Oranjestad, Tanki Leendert, San Nicolas", default: ""},
{value:"America/Lower_Princes", name: "-04:00 Atlantic Time - Philipsburg", default: ""},
{value:"America/Tortola", name: "-04:00 Atlantic Time - Road Town", default: ""},
{value:"America/Dominica", name: "-04:00 Atlantic Time - Roseau", default: ""},
{value:"America/St_Thomas", name: "-04:00 Atlantic Time - Saint Croix, Charlotte Amalie", default: ""},
{value:"America/Grenada", name: "-04:00 Atlantic Time - Saint George's", default: ""},
{value:"America/Antigua", name: "-04:00 Atlantic Time - Saint John’s", default: ""},
{value:"America/Puerto_Rico", name: "-04:00 Atlantic Time - San Juan, Bayamón, Carolina, Ponce", default: ""},
{value:"America/Santo_Domingo", name: "-04:00 Atlantic Time - Santo Domingo, Santiago de los Caballeros, Santo Domingo Oeste, Santo Domingo Este", default: ""},
{value:"America/Anguilla", name: "-04:00 Atlantic Time - The Valley", default: ""},
{value:"America/Thule", name: "-04:00 Atlantic Time - Thule", default: ""},
{value:"America/Curacao", name: "-04:00 Atlantic Time - Willemstad", default: ""},
{value:"America/La_Paz", name: "-04:00 Bolivia Time - La Paz, Santa Cruz de la Sierra, Cochabamba, Sucre", default: ""},
{value:"America/Guyana", name: "-04:00 Guyana Time - Georgetown, Linden, New Amsterdam", default: ""},
{value:"America/Caracas", name: "-04:00 Venezuela Time - Caracas, Maracaibo, Maracay, Valencia", default: ""},
{value:"America/St_Johns", name: "-03:30 Newfoundland Time - St. John's, Mount Pearl, Corner Brook, Conception Bay South", default: ""},
{value:"America/Argentina/Buenos_Aires", name: "-03:00 Argentina Time - Buenos Aires, Córdoba, Rosario, Mendoza", default: ""},
{value:"America/Sao_Paulo", name: "-03:00 Brasilia Time - São Paulo, Rio de Janeiro, Salvador, Fortaleza", default: ""},
{value:"Antarctica/Palmer", name: "-03:00 Chile Time - Palmer, Rothera", default: ""},
{value:"America/Punta_Arenas", name: "-03:00 Chile Time - Punta Arenas, Puerto Natales", default: ""},
{value:"America/Santiago", name: "-03:00 Chile Time - Santiago, Puente Alto, Antofagasta, Viña del Mar", default: ""},
{value:"Atlantic/Stanley", name: "-03:00 Falkland Islands Time - Stanley", default: ""},
{value:"America/Cayenne", name: "-03:00 French Guiana Time - Cayenne, Matoury, Saint-Laurent-du-Maroni, Kourou", default: ""},
{value:"America/Asuncion", name: "-03:00 Paraguay Time - Asunción, Ciudad del Este, San Lorenzo, Capiatá", default: ""},
{value:"America/Miquelon", name: "-03:00 St. Pierre &amp; Miquelon Time - Saint-Pierre", default: ""},
{value:"America/Paramaribo", name: "-03:00 Suriname Time - Paramaribo, Lelydorp", default: ""},
{value:"America/Montevideo", name: "-03:00 Uruguay Time - Montevideo, Salto, Paysandú, Las Piedras", default: ""},
{value:"America/Noronha", name: "-02:00 Fernando de Noronha Time - Noronha", default: ""},
{value:"Atlantic/South_Georgia", name: "-02:00 South Georgia Time - Grytviken", default: ""},
{value:"America/Nuuk", name: "-02:00 West Greenland Time - Nuuk", default: ""},
{value:"Atlantic/Azores", name: "-01:00 Azores Time - Ponta Delgada", default: ""},
{value:"Atlantic/Cape_Verde", name: "-01:00 Cape Verde Time - Praia, Mindelo, Santa Maria, Cova Figueira", default: ""},
{value:"America/Scoresbysund", name: "-01:00 East Greenland Time - Scoresbysund", default: ""},
{value:"Africa/Abidjan", name: "+00:00 Greenwich Mean Time - Abidjan, Abobo, Bouaké, Daloa", default: ""},
{value:"Africa/Accra", name: "+00:00 Greenwich Mean Time - Accra, Kumasi, Tamale, Takoradi", default: ""},
{value:"Africa/Bamako", name: "+00:00 Greenwich Mean Time - Bamako, Ségou, Sikasso, Mopti", default: ""},
{value:"Africa/Bissau", name: "+00:00 Greenwich Mean Time - Bissau, Bafatá", default: ""},
{value:"Africa/Conakry", name: "+00:00 Greenwich Mean Time - Camayenne, Conakry, Nzérékoré, Kindia", default: ""},
{value:"Africa/Dakar", name: "+00:00 Greenwich Mean Time - Dakar, Pikine, Touba, Thiès", default: ""},
{value:"America/Danmarkshavn", name: "+00:00 Greenwich Mean Time - Danmarkshavn", default: ""},
{value:"Europe/Isle_of_Man", name: "+00:00 Greenwich Mean Time - Douglas", default: ""},
{value:"Europe/Dublin", name: "+00:00 Greenwich Mean Time - Dublin, Cork, Luimneach, Gaillimh", default: ""},
{value:"Africa/Freetown", name: "+00:00 Greenwich Mean Time - Freetown, Bo, Kenema, Koidu", default: ""},
{value:"Atlantic/St_Helena", name: "+00:00 Greenwich Mean Time - Jamestown", default: ""},
{value:"Africa/Lome", name: "+00:00 Greenwich Mean Time - Lomé, Sokodé, Kara, Atakpamé", default: ""},
{value:"Europe/London", name: "+00:00 Greenwich Mean Time - London, Birmingham, Liverpool, Sheffield", default: ""},
{value:"Africa/Monrovia", name: "+00:00 Greenwich Mean Time - Monrovia, Gbarnga, Kakata, Bensonville", default: ""},
{value:"Africa/Nouakchott", name: "+00:00 Greenwich Mean Time - Nouakchott, Nouadhibou, Néma, Kaédi", default: ""},
{value:"Africa/Ouagadougou", name: "+00:00 Greenwich Mean Time - Ouagadougou, Bobo-Dioulasso, Koudougou, Ouahigouya", default: ""},
{value:"Atlantic/Reykjavik", name: "+00:00 Greenwich Mean Time - Reykjavík, Kópavogur, Hafnarfjörður, Akureyri", default: ""},
{value:"Europe/Jersey", name: "+00:00 Greenwich Mean Time - Saint Helier", default: ""},
{value:"Europe/Guernsey", name: "+00:00 Greenwich Mean Time - Saint Peter Port", default: ""},
{value:"Africa/Sao_Tome", name: "+00:00 Greenwich Mean Time - São Tomé", default: ""},
{value:"Africa/Banjul", name: "+00:00 Greenwich Mean Time - Serekunda, Brikama, Bakau, Banjul", default: ""},
{value:"Antarctica/Troll", name: "+00:00 Greenwich Mean Time - Troll", default: ""},
{value:"Atlantic/Canary", name: "+00:00 Western European Time - Las Palmas de Gran Canaria, Santa Cruz de Tenerife, La Laguna, Telde", default: ""},
{value:"Europe/Lisbon", name: "+00:00 Western European Time - Lisbon, Porto, Amadora, Braga", default: ""},
{value:"Atlantic/Faroe", name: "+00:00 Western European Time - Tórshavn", default: ""},
{value:"Africa/Algiers", name: "+01:00 Central European Time - Algiers, Boumerdas, Oran, Tébessa", default: ""},
{value:"Europe/Amsterdam", name: "+01:00 Central European Time - Amsterdam, Rotterdam, The Hague, Utrecht", default: ""},
{value:"Europe/Andorra", name: "+01:00 Central European Time - Andorra la Vella, les Escaldes", default: ""},
{value:"Europe/Belgrade", name: "+01:00 Central European Time - Belgrade, Niš, Novi Sad, Zemun", default: ""},
{value:"Europe/Berlin", name: "+01:00 Central European Time - Berlin, Hamburg, Munich, Köln", default: ""},
{value:"Europe/Malta", name: "+01:00 Central European Time - Birkirkara, Qormi, Mosta, Żabbar", default: ""},
{value:"Europe/Bratislava", name: "+01:00 Central European Time - Bratislava, Košice, Prešov, Nitra", default: ""},
{value:"Europe/Brussels", name: "+01:00 Central European Time - Brussels, Antwerpen, Gent, Charleroi", default: ""},
{value:"Europe/Budapest", name: "+01:00 Central European Time - Budapest, Debrecen, Miskolc, Szeged", default: ""},
{value:"Europe/Copenhagen", name: "+01:00 Central European Time - Copenhagen, Århus, Odense, Aalborg", default: ""},
{value:"Europe/Gibraltar", name: "+01:00 Central European Time - Gibraltar", default: ""},
{value:"Europe/Ljubljana", name: "+01:00 Central European Time - Ljubljana, Maribor, Celje, Kranj", default: ""},
{value:"Arctic/Longyearbyen", name: "+01:00 Central European Time - Longyearbyen", default: ""},
{value:"Europe/Luxembourg", name: "+01:00 Central European Time - Luxembourg, Esch-sur-Alzette, Dudelange", default: ""},
{value:"Europe/Madrid", name: "+01:00 Central European Time - Madrid, Barcelona, Valencia, Sevilla", default: ""},
{value:"Europe/Monaco", name: "+01:00 Central European Time - Monaco, Monte-Carlo", default: ""},
{value:"Europe/Oslo", name: "+01:00 Central European Time - Oslo, Bergen, Trondheim, Stavanger", default: ""},
{value:"Europe/Paris", name: "+01:00 Central European Time - Paris, Marseille, Lyon, Toulouse", default: ""},
{value:"Europe/Podgorica", name: "+01:00 Central European Time - Podgorica, Nikšić, Herceg Novi, Pljevlja", default: ""},
{value:"Europe/Prague", name: "+01:00 Central European Time - Prague, Brno, Ostrava, Pilsen", default: ""},
{value:"Europe/Rome", name: "+01:00 Central European Time - Rome, Milan, Naples, Turin", default: ""},
{value:"Europe/San_Marino", name: "+01:00 Central European Time - San Marino", default: ""},
{value:"Europe/Sarajevo", name: "+01:00 Central European Time - Sarajevo, Banja Luka, Zenica, Tuzla", default: ""},
{value:"Europe/Skopje", name: "+01:00 Central European Time - Skopje, Bitola, Kumanovo, Prilep", default: ""},
{value:"Europe/Stockholm", name: "+01:00 Central European Time - Stockholm, Göteborg, Malmö, Uppsala", default: ""},
{value:"Europe/Tirane", name: "+01:00 Central European Time - Tirana, Durrës, Elbasan, Vlorë", default: ""},
{value:"Africa/Tunis", name: "+01:00 Central European Time - Tunis, Sfax, Sousse, Kairouan", default: ""},
{value:"Europe/Vaduz", name: "+01:00 Central European Time - Vaduz", default: ""},
{value:"Europe/Vatican", name: "+01:00 Central European Time - Vatican City", default: ""},
{value:"Europe/Vienna", name: "+01:00 Central European Time - Vienna, Graz, Linz, Favoriten", default: ""},
{value:"Europe/Warsaw", name: "+01:00 Central European Time - Warsaw, Łódź, Kraków, Wrocław", default: ""},
{value:"Europe/Zagreb", name: "+01:00 Central European Time - Zagreb, Split, Rijeka, Osijek", default: ""},
{value:"Europe/Zurich", name: "+01:00 Central European Time - Zürich, Genève, Basel, Lausanne", default: ""},
{value:"Africa/Bangui", name: "+01:00 West Africa Time - Bangui, Bimbo, Mbaïki, Berbérati", default: ""},
{value:"Africa/Malabo", name: "+01:00 West Africa Time - Bata, Malabo, Ebebiyin", default: ""},
{value:"Africa/Brazzaville", name: "+01:00 West Africa Time - Brazzaville, Pointe-Noire, Dolisie, Kayes", default: ""},
{value:"Africa/Porto-Novo", name: "+01:00 West Africa Time - Cotonou, Abomey-Calavi, Djougou, Porto-Novo", default: ""},
{value:"Africa/Douala", name: "+01:00 West Africa Time - Douala, Yaoundé, Garoua, Kousséri", default: ""},
{value:"Africa/Kinshasa", name: "+01:00 West Africa Time - Kinshasa, Masina, Kikwit, Mbandaka", default: ""},
{value:"Africa/Lagos", name: "+01:00 West Africa Time - Lagos, Kano, Ibadan, Port Harcourt", default: ""},
{value:"Africa/Libreville", name: "+01:00 West Africa Time - Libreville, Port-Gentil, Franceville, Oyem", default: ""},
{value:"Africa/Luanda", name: "+01:00 West Africa Time - Luanda, N’dalatando, Huambo, Lobito", default: ""},
{value:"Africa/Ndjamena", name: "+01:00 West Africa Time - N'Djamena, Moundou, Sarh, Abéché", default: ""},
{value:"Africa/Niamey", name: "+01:00 West Africa Time - Niamey, Zinder, Maradi, Agadez", default: ""},
{value:"Africa/Casablanca", name: "+01:00 Western European Time - Casablanca, Rabat, Fès, Sale", default: ""},
{value:"Africa/El_Aaiun", name: "+01:00 Western European Time - Laayoune, Dakhla", default: ""},
{value:"Africa/Bujumbura", name: "+02:00 Central Africa Time - Bujumbura, Muyinga, Gitega, Ruyigi", default: ""},
{value:"Africa/Gaborone", name: "+02:00 Central Africa Time - Gaborone, Francistown, Molepolole, Selebi-Phikwe", default: ""},
{value:"Africa/Harare", name: "+02:00 Central Africa Time - Harare, Bulawayo, Chitungwiza, Mutare", default: ""},
{value:"Africa/Juba", name: "+02:00 Central Africa Time - Juba, Winejok, Yei, Malakal", default: ""},
{value:"Africa/Khartoum", name: "+02:00 Central Africa Time - Khartoum, Omdurman, Nyala, Port Sudan", default: ""},
{value:"Africa/Kigali", name: "+02:00 Central Africa Time - Kigali, Gisenyi, Butare, Gitarama", default: ""},
{value:"Africa/Blantyre", name: "+02:00 Central Africa Time - Lilongwe, Blantyre, Mzuzu, Zomba", default: ""},
{value:"Africa/Lubumbashi", name: "+02:00 Central Africa Time - Lubumbashi, Mbuji-Mayi, Kisangani, Kananga", default: ""},
{value:"Africa/Lusaka", name: "+02:00 Central Africa Time - Lusaka, Kitwe, Ndola, Kabwe", default: ""},
{value:"Africa/Maputo", name: "+02:00 Central Africa Time - Maputo, Matola, Beira, Nampula", default: ""},
{value:"Africa/Windhoek", name: "+02:00 Central Africa Time - Windhoek, Rundu, Walvis Bay, Oshakati", default: ""},
{value:"Europe/Athens", name: "+02:00 Eastern European Time - Athens, Thessaloníki, Pátra, Piraeus", default: ""},
{value:"Asia/Beirut", name: "+02:00 Eastern European Time - Beirut, Ra’s Bayrūt, Tripoli, Sidon", default: ""},
{value:"Europe/Bucharest", name: "+02:00 Eastern European Time - Bucharest, Sector 3, Sector 6, Sector 2", default: ""},
{value:"Africa/Cairo", name: "+02:00 Eastern European Time - Cairo, Alexandria, Giza, Shubrā al Khaymah", default: ""},
{value:"Europe/Chisinau", name: "+02:00 Eastern European Time - Chisinau, Tiraspol, Bălţi, Bender", default: ""},
{value:"Asia/Hebron", name: "+02:00 Eastern European Time - East Jerusalem, Gaza, Khān Yūnis, Jabālyā", default: ""},
{value:"Europe/Helsinki", name: "+02:00 Eastern European Time - Helsinki, Espoo, Tampere, Vantaa", default: ""},
{value:"Europe/Kaliningrad", name: "+02:00 Eastern European Time - Kaliningrad, Chernyakhovsk, Sovetsk, Baltiysk", default: ""},
{value:"Europe/Kiev", name: "+02:00 Eastern European Time - Kyiv, Kharkiv, Donetsk, Odessa", default: ""},
{value:"Europe/Mariehamn", name: "+02:00 Eastern European Time - Mariehamn", default: ""},
{value:"Asia/Nicosia", name: "+02:00 Eastern European Time - Nicosia, Limassol, Larnaca, Stróvolos", default: ""},
{value:"Europe/Riga", name: "+02:00 Eastern European Time - Riga, Daugavpils, Liepāja, Jelgava", default: ""},
{value:"Europe/Sofia", name: "+02:00 Eastern European Time - Sofia, Plovdiv, Varna, Burgas", default: ""},
{value:"Europe/Tallinn", name: "+02:00 Eastern European Time - Tallinn, Tartu, Narva, Kohtla-Järve", default: ""},
{value:"Africa/Tripoli", name: "+02:00 Eastern European Time - Tripoli, Benghazi, Mişrātah, Tarhuna", default: ""},
{value:"Europe/Vilnius", name: "+02:00 Eastern European Time - Vilnius, Kaunas, Klaipėda, Šiauliai", default: ""},
{value:"Asia/Jerusalem", name: "+02:00 Israel Time - Jerusalem, Tel Aviv, West Jerusalem, Haifa", default: ""},
{value:"Africa/Johannesburg", name: "+02:00 South Africa Time - Cape Town, Durban, Johannesburg, Soweto", default: ""},
{value:"Africa/Mbabane", name: "+02:00 South Africa Time - Manzini, Mbabane, Lobamba", default: ""},
{value:"Africa/Maseru", name: "+02:00 South Africa Time - Maseru, Mafeteng, Leribe, Maputsoe", default: ""},
{value:"Asia/Kuwait", name: "+03:00 Arabian Time - Al Aḩmadī, Ḩawallī, As Sālimīyah, Şabāḩ as Sālim", default: ""},
{value:"Asia/Baghdad", name: "+03:00 Arabian Time - Baghdad, Basrah, Al Mawşil al Jadīdah, Al Başrah al Qadīmah", default: ""},
{value:"Asia/Qatar", name: "+03:00 Arabian Time - Doha, Ar Rayyān, Umm Şalāl Muḩammad, Al Wakrah", default: ""},
{value:"Asia/Bahrain", name: "+03:00 Arabian Time - Manama, Al Muharraq, Ar Rifā‘, Dār Kulayb", default: ""},
{value:"Asia/Riyadh", name: "+03:00 Arabian Time - Riyadh, Jeddah, Mecca, Medina", default: ""},
{value:"Asia/Aden", name: "+03:00 Arabian Time - Sanaa, Al Ḩudaydah, Taiz, Aden", default: ""},
{value:"Africa/Addis_Ababa", name: "+03:00 East Africa Time - Addis Ababa, Dire Dawa, Mek'ele, Nazrēt", default: ""},
{value:"Indian/Antananarivo", name: "+03:00 East Africa Time - Antananarivo, Toamasina, Antsirabe, Fianarantsoa", default: ""},
{value:"Africa/Asmara", name: "+03:00 East Africa Time - Asmara, Keren, Massawa, Assab", default: ""},
{value:"Africa/Dar_es_Salaam", name: "+03:00 East Africa Time - Dar es Salaam, Mwanza, Zanzibar, Arusha", default: ""},
{value:"Africa/Djibouti", name: "+03:00 East Africa Time - Djibouti, 'Ali Sabieh, Tadjourah, Obock", default: ""},
{value:"Africa/Kampala", name: "+03:00 East Africa Time - Kampala, Gulu, Lira, Mbarara", default: ""},
{value:"Indian/Mayotte", name: "+03:00 East Africa Time - Mamoudzou, Koungou, Dzaoudzi", default: ""},
{value:"Africa/Mogadishu", name: "+03:00 East Africa Time - Mogadishu, Hargeysa, Berbera, Kismayo", default: ""},
{value:"Indian/Comoro", name: "+03:00 East Africa Time - Moroni, Moutsamoudou", default: ""},
{value:"Africa/Nairobi", name: "+03:00 East Africa Time - Nairobi, Mombasa, Ruiru, Kikuyu", default: ""},
{value:"Asia/Damascus", name: "+03:00 Eastern European Time - Aleppo, Damascus, Homs, Ḩamāh", default: ""},
{value:"Asia/Amman", name: "+03:00 Eastern European Time - Amman, Zarqa, Irbid, Russeifa", default: ""},
{value:"Europe/Minsk", name: "+03:00 Moscow Time - Minsk, Homyel', Mahilyow, Vitebsk", default: ""},
{value:"Europe/Moscow", name: "+03:00 Moscow Time - Moscow, Saint Petersburg, Nizhniy Novgorod, Kazan", default: ""},
{value:"Europe/Simferopol", name: "+03:00 Moscow Time - Sevastopol, Simferopol, Kerch, Yevpatoriya", default: ""},
{value:"Antarctica/Syowa", name: "+03:00 Syowa Time - Syowa", default: ""},
{value:"Europe/Istanbul", name: "+03:00 Turkey Time - Istanbul, Ankara, İzmir, Bursa", default: ""},
{value:"Asia/Tehran", name: "+03:30 Iran Time - Tehran, Mashhad, Isfahan, Karaj", default: ""},
{value:"Asia/Yerevan", name: "+04:00 Armenia Time - Yerevan, Gyumri, Vanadzor, Vagharshapat", default: ""},
{value:"Asia/Baku", name: "+04:00 Azerbaijan Time - Baku, Ganja, Sumqayıt, Lankaran", default: ""},
{value:"Asia/Tbilisi", name: "+04:00 Georgia Time - Tbilisi, Kutaisi, Batumi, Sokhumi", default: ""},
{value:"Asia/Dubai", name: "+04:00 Gulf Time - Dubai, Sharjah, Abu Dhabi, Ajman City", default: ""},
{value:"Asia/Muscat", name: "+04:00 Gulf Time - Muscat, Seeb, Şalālah, Bawshar", default: ""},
{value:"Indian/Mauritius", name: "+04:00 Mauritius Time - Port Louis, Beau Bassin-Rose Hill, Vacoas, Curepipe", default: ""},
{value:"Indian/Reunion", name: "+04:00 Réunion Time - Saint-Denis, Saint-Paul, Saint-Pierre, Le Tampon", default: ""},
{value:"Europe/Samara", name: "+04:00 Samara Time - Samara, Saratov, Tolyatti, Ulyanovsk", default: ""},
{value:"Indian/Mahe", name: "+04:00 Seychelles Time - Victoria", default: ""},
{value:"Asia/Kabul", name: "+04:30 Afghanistan Time - Kabul, Herāt, Mazār-e Sharīf, Kandahār", default: ""},
{value:"Indian/Kerguelen", name: "+05:00 French Southern &amp; Antarctic Time - Port-aux-Français", default: ""},
{value:"Indian/Maldives", name: "+05:00 Maldives Time - Male", default: ""},
{value:"Antarctica/Mawson", name: "+05:00 Mawson Time - Mawson", default: ""},
{value:"Asia/Karachi", name: "+05:00 Pakistan Time - Karachi, Lahore, Faisalabad, Rawalpindi", default: ""},
{value:"Asia/Dushanbe", name: "+05:00 Tajikistan Time - Dushanbe, Khujand, Kŭlob, Bokhtar", default: ""},
{value:"Asia/Ashgabat", name: "+05:00 Turkmenistan Time - Ashgabat, Türkmenabat, Daşoguz, Mary", default: ""},
{value:"Asia/Tashkent", name: "+05:00 Uzbekistan Time - Tashkent, Namangan, Samarkand, Andijon", default: ""},
{value:"Asia/Qyzylorda", name: "+05:00 West Kazakhstan Time - Kyzylorda, Aktobe, Oral, Atyrau", default: ""},
{value:"Asia/Yekaterinburg", name: "+05:00 Yekaterinburg Time - Yekaterinburg, Chelyabinsk, Ufa, Perm", default: ""},
{value:"Asia/Colombo", name: "+05:30 India Time - Colombo, Dehiwala-Mount Lavinia, Maharagama, Jaffna", default: ""},
{value:"Asia/Kolkata", name: "+05:30 India Time - Mumbai, Delhi, Bengaluru, Hyderābād", default: ""},
{value:"Asia/Kathmandu", name: "+05:45 Nepal Time - Kathmandu, Pokhara, Pātan, Biratnagar", default: ""},
{value:"Asia/Dhaka", name: "+06:00 Bangladesh Time - Dhaka, Chattogram, Khulna, Rājshāhi", default: ""},
{value:"Asia/Thimphu", name: "+06:00 Bhutan Time - Thimphu, Punākha, Tsirang, Phuntsholing", default: ""},
{value:"Asia/Urumqi", name: "+06:00 China Time - Ürümqi, Shihezi, Korla, Aksu", default: ""},
{value:"Asia/Almaty", name: "+06:00 East Kazakhstan Time - Almaty, Karagandy, Shymkent, Taraz", default: ""},
{value:"Indian/Chagos", name: "+06:00 Indian Ocean Time - Chagos", default: ""},
{value:"Asia/Bishkek", name: "+06:00 Kyrgyzstan Time - Bishkek, Osh, Jalal-Abad, Karakol", default: ""},
{value:"Asia/Omsk", name: "+06:00 Omsk Time - Omsk, Tara, Kalachinsk", default: ""},
{value:"Antarctica/Vostok", name: "+06:00 Vostok Time - Vostok", default: ""},
{value:"Indian/Cocos", name: "+06:30 Cocos Islands Time - West Island", default: ""},
{value:"Asia/Yangon", name: "+06:30 Myanmar Time - Yangon, Mandalay, Nay Pyi Taw, Mawlamyine", default: ""},
{value:"Indian/Christmas", name: "+07:00 Christmas Island Time - Flying Fish Cove", default: ""},
{value:"Antarctica/Davis", name: "+07:00 Davis Time - Davis", default: ""},
{value:"Asia/Hovd", name: "+07:00 Hovd Time - Khovd, Ölgii, Ulaangom, Uliastay", default: ""},
{value:"Asia/Bangkok", name: "+07:00 Indochina Time - Bangkok, Samut Prakan, Mueang Nonthaburi, Udon Thani", default: ""},
{value:"Asia/Ho_Chi_Minh", name: "+07:00 Indochina Time - Ho Chi Minh City, Da Nang, Biên Hòa, Cần Thơ", default: ""},
{value:"Asia/Phnom_Penh", name: "+07:00 Indochina Time - Phnom Penh, Takeo, Sihanoukville, Battambang", default: ""},
{value:"Asia/Vientiane", name: "+07:00 Indochina Time - Vientiane, Pakse, Thakhèk, Savannakhet", default: ""},
{value:"Asia/Novosibirsk", name: "+07:00 Novosibirsk Time - Novosibirsk, Krasnoyarsk, Barnaul, Novokuznetsk", default: ""},
{value:"Asia/Jakarta", name: "+07:00 Western Indonesia Time - Jakarta, Surabaya, Medan, Bandung", default: ""},
{value:"Australia/Perth", name: "+08:00 Australian Western Time - Perth, Rockingham, Mandurah, Bunbury", default: ""},
{value:"Asia/Brunei", name: "+08:00 Brunei Darussalam Time - Bandar Seri Begawan, Kuala Belait, Seria, Tutong", default: ""},
{value:"Asia/Makassar", name: "+08:00 Central Indonesia Time - Makassar, Denpasar, Banjarmasin, Manado", default: ""},
{value:"Asia/Macau", name: "+08:00 China Time - Macau", default: ""},
{value:"Asia/Shanghai", name: "+08:00 China Time - Shanghai, Beijing, Shenzhen, Guangzhou", default: ""},
{value:"Asia/Hong_Kong", name: "+08:00 Hong Kong Time - Hong Kong, Kowloon, Victoria, Tuen Mun", default: ""},
{value:"Asia/Irkutsk", name: "+08:00 Irkutsk Time - Irkutsk, Ulan-Ude, Bratsk, Angarsk", default: ""},
{value:"Asia/Kuala_Lumpur", name: "+08:00 Malaysia Time - Kota Bharu, Kuala Lumpur, Klang, Kampung Baru Subang", default: ""},
{value:"Asia/Manila", name: "+08:00 Philippine Time - Quezon City, Manila, Caloocan City, Budta", default: ""},
{value:"Asia/Singapore", name: "+08:00 Singapore Time - Singapore, Woodlands, Marine Parade", default: ""},
{value:"Asia/Taipei", name: "+08:00 Taipei Time - Taipei, Kaohsiung, Taichung, Tainan", default: ""},
{value:"Asia/Ulaanbaatar", name: "+08:00 Ulaanbaatar Time - Ulan Bator, Erdenet, Darhan, Hovd", default: ""},
{value:"Australia/Eucla", name: "+08:45 Australian Central Western Time - Eucla", default: ""},
{value:"Asia/Dili", name: "+09:00 East Timor Time - Dili, Maliana, Suai, Likisá", default: ""},
{value:"Asia/Jayapura", name: "+09:00 Eastern Indonesia Time - Ambon, Jayapura, Sorong, Ternate", default: ""},
{value:"Asia/Tokyo", name: "+09:00 Japan Time - Tokyo, Yokohama, Osaka, Nagoya", default: ""},
{value:"Asia/Pyongyang", name: "+09:00 Korean Time - Pyongyang, Hamhŭng, Namp’o, Sunch’ŏn", default: ""},
{value:"Asia/Seoul", name: "+09:00 Korean Time - Seoul, Busan, Incheon, Daegu", default: ""},
{value:"Pacific/Palau", name: "+09:00 Palau Time - Ngerulmud", default: ""},
{value:"Asia/Chita", name: "+09:00 Yakutsk Time - Chita, Yakutsk, Blagoveshchensk, Belogorsk", default: ""},
{value:"Australia/Darwin", name: "+09:30 Australian Central Time - Darwin, Alice Springs, Palmerston", default: ""},
{value:"Australia/Brisbane", name: "+10:00 Australian Eastern Time - Brisbane, Gold Coast, Logan City, Townsville", default: ""},
{value:"Pacific/Guam", name: "+10:00 Chamorro Time - Dededo Village, Yigo Village, Tamuning-Tumon-Harmon Village, Tamuning", default: ""},
{value:"Pacific/Saipan", name: "+10:00 Chamorro Time - Saipan", default: ""},
{value:"Pacific/Chuuk", name: "+10:00 Chuuk Time - Chuuk", default: ""},
{value:"Antarctica/DumontDUrville", name: "+10:00 Dumont-d’Urville Time - DumontDUrville", default: ""},
{value:"Pacific/Port_Moresby", name: "+10:00 Papua New Guinea Time - Port Moresby, Lae, Mount Hagen, Popondetta", default: ""},
{value:"Asia/Vladivostok", name: "+10:00 Vladivostok Time - Vladivostok, Khabarovsk, Khabarovsk Vtoroy, Komsomolsk-on-Amur", default: ""},
{value:"Australia/Adelaide", name: "+10:30 Australian Central Time - Adelaide, Adelaide Hills, Mount Gambier, Morphett Vale", default: ""},
{value:"Australia/Sydney", name: "+11:00 Australian Eastern Time - Sydney, Melbourne, Canberra, Newcastle", default: ""},
{value:"Pacific/Bougainville", name: "+11:00 Bougainville Time - Arawa", default: ""},
{value:"Antarctica/Casey", name: "+11:00 Casey Time - Casey", default: ""},
{value:"Pacific/Kosrae", name: "+11:00 Kosrae Time - Kosrae, Palikir - National Government Center", default: ""},
{value:"Australia/Lord_Howe", name: "+11:00 Lord Howe Time - Lord Howe", default: ""},
{value:"Pacific/Noumea", name: "+11:00 New Caledonia Time - Nouméa, Mont-Dore, Dumbéa", default: ""},
{value:"Asia/Sakhalin", name: "+11:00 Sakhalin Time - Yuzhno-Sakhalinsk, Magadan, Korsakov, Kholmsk", default: ""},
{value:"Pacific/Guadalcanal", name: "+11:00 Solomon Islands Time - Honiara", default: ""},
{value:"Pacific/Efate", name: "+11:00 Vanuatu Time - Port-Vila", default: ""},
{value:"Pacific/Fiji", name: "+12:00 Fiji Time - Suva, Lautoka, Nadi, Labasa", default: ""},
{value:"Pacific/Tarawa", name: "+12:00 Gilbert Islands Time - Tarawa", default: ""},
{value:"Pacific/Majuro", name: "+12:00 Marshall Islands Time - Majuro, Kwajalein, RMI Capitol", default: ""},
{value:"Pacific/Nauru", name: "+12:00 Nauru Time - Yaren", default: ""},
{value:"Pacific/Norfolk", name: "+12:00 Norfolk Island Time - Kingston", default: ""},
{value:"Asia/Kamchatka", name: "+12:00 Petropavlovsk-Kamchatski Time - Petropavlovsk-Kamchatsky, Yelizovo, Vilyuchinsk, Anadyr", default: ""},
{value:"Pacific/Funafuti", name: "+12:00 Tuvalu Time - Funafuti", default: ""},
{value:"Pacific/Wake", name: "+12:00 Wake Island Time - Wake", default: ""},
{value:"Pacific/Wallis", name: "+12:00 Wallis &amp; Futuna Time - Mata-Utu", default: ""},
{value:"Pacific/Apia", name: "+13:00 Apia Time - Apia", default: ""},
{value:"Pacific/Auckland", name: "+13:00 New Zealand Time - Auckland, Wellington, Christchurch, Manukau City", default: ""},
{value:"Antarctica/McMurdo", name: "+13:00 New Zealand Time - McMurdo", default: ""},
{value:"Pacific/Kanton", name: "+13:00 Phoenix Islands Time - Kanton", default: ""},
{value:"Pacific/Fakaofo", name: "+13:00 Tokelau Time - Fakaofo", default: ""},
{value:"Pacific/Tongatapu", name: "+13:00 Tonga Time - Nuku‘alofa", default: ""},
{value:"Pacific/Chatham", name: "+13:45 Chatham Time - Chatham", default: ""},
{value:"Pacific/Kiritimati", name: "+14:00 Line Islands Time - Kiritimati", default: ""},
]

export default usosHorarios;