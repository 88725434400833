import imgAuthor1 from '../images/avatar/avt-1.jpg'
import img1left from '../images/box-item/collection-item-2.jpg'
import img1right1 from '../images/box-item/collection-item-top-1.jpg'
import img1right2 from '../images/box-item/collection-item-top-2.jpg'
import img1right3 from '../images/box-item/collection-item-bottom-4.jpg'
import imgAuthor2 from '../images/avatar/avt-8.jpg'
import img2left from '../images/box-item/img-collection23.jpg'
import img2right1 from '../images/box-item/img-collection24.jpg'
import img2right2 from '../images/box-item/img-collection10.jpg'
import img2right3 from '../images/box-item/img-collection11.jpg'
import imgAuthor3 from '../images/avatar/avt-7.jpg'
import img3left from '../images/box-item/img-collection12.jpg'
import img3right1 from '../images/box-item/img-collection18.jpg'
import img3right2 from '../images/box-item/img-collection25.jpg'
import img3right3 from '../images/box-item/img-collection17.jpg'
import img1right4 from '../images/box-item/img-collection8.jpg'
import img2right4 from '../images/box-item/img-collection1.jpg'
import imgtop1 from '../images/box-item/collection-item-4.jpg'
import imgtop2 from '../images/box-item/collection-item-5.jpg'
import imgtop3 from '../images/box-item/collection-item-1.png'

const popularCollectionData = [
    {
        title: "Join(t) the Music",
        imgAuthor: "https://scontent.fgdl2-2.fna.fbcdn.net/v/t1.6435-9/195873965_103057558675111_9044504529440096296_n.jpg?_nc_cat=105&ccb=1-5&_nc_sid=09cbfe&_nc_eui2=AeFiHneZJY0Pj94btId3_rtnWN07cJcO3MZY3Ttwlw7cxplcXZn-2SDPdrcNG_Q9hGw&_nc_ohc=S2k8VTMZCIkAX9dDxak&_nc_ht=scontent.fgdl2-2.fna&oh=00_AT8pb4rj8cx25TtwXcrhl31j4ek0ySDYZzHZbSWCUQquNw&oe=62576E1D",
        name: "OG Jam",
        imgleft: img1left,
        imgright1: img1right1,
        imgright2: img1right2,
        imgright3: img1right3,
        imgright4: img1right4,
        imgtop: "https://lh3.googleusercontent.com/KpwPYOE4CLzxRuNmoUJN63L7_ajKnEu4uHv-2wzUJQYLFURhMgkpnN3vEiae9LYL0GF0qBwGaJDtyeJEuARaxmXR8ut2IAxJFeovJoI=s0",
        wishlist: "100",
        count: '12 item products'
    },
    {
        title: "California Vive la Música",
        imgAuthor: imgAuthor2,
        name: "Mario Lagsbartt",
        imgleft: img2left,
        imgright1: img2right1,
        imgright2: img2right2,
        imgright3: img2right3,
        imgright4: img1right4,
        imgtop: "https://scontent.fgdl2-1.fna.fbcdn.net/v/t1.6435-9/99127501_2661496033954815_1097987803444150272_n.jpg?_nc_cat=111&ccb=1-5&_nc_sid=09cbfe&_nc_eui2=AeFK7Vr8TBbu0lLpauNW2UkdzW2aAgcWBFjNbZoCBxYEWOitWg7LOzKBIjfVkPniczw&_nc_ohc=haRZ_pPCxicAX9ZNIbc&_nc_ht=scontent.fgdl2-1.fna&oh=00_AT_ViKDKipxMGfcOHMTdUEDRA5Lx1EOtGiqiPmxlBcB6QA&oe=62588600",
        wishlist: "220",
        count: '26 item products'
    }

]

export default popularCollectionData;