import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';

import { UserContext } from '../../context/UserContext';

import ModalViewQRCode from '../modal/ViewQRCode';
import Util from '../../util/util';

const TicketCard = props => {

    const [modalShow, setModalShow] = useState(false);

    function closeModal() {
        setModalShow(false);
    }

    const { stateApp } = useContext(UserContext);

    function getStatusColor() {
        const status = props.item.status;
        //alert(status);
        switch (status) {
            case 'valid':
                return { color: "green" };
            case 'used':
                return { color: "red" };
            case 'invalid':
                return { color: "red" };
            default:
                return {};
        }
    }

    return (
        <div key={props.item.id} className={`sc-card-product explode style2 mg-bt ${props.item.feature ? 'comingsoon' : ''} `}>
            <ModalViewQRCode
                show={modalShow}
                onHide={closeModal}
                ticket={props.item}
            />
            <div className="card-media">
                <Link to={("/ticket/" + props.item?.evento?.titulo_url + "/" + props.item?.nft_id) || "/"}><img src={props.item.url || props.item.evento?.url_foto_perfil || "/assets/icon/capturas.gif"} alt={props.item.nombre || ""} /></Link>
                {(props.item.price && (
                    <div className="button-place-bid">
                        <button onClick={() => setModalShow(true)} className="sc-button style-place-bid style bag fl-button pri-3"><span>Hacer oferta</span></button>
                    </div>))}
                <Link to="/login" className="wishlist-button heart" hidden={true}><span className="number-like">{props.item.wishlist || 0}</span></Link>
                <div className="coming-soon">{props.item.feature}</div>
            </div>
            <div className="card-title">
                <h5><Link to={(props.item.evento && "/ticket/" + props.item.evento.titulo_url + "/" + props.item.nft_id) || "/"}>{props.item.nombre || ""}</Link></h5>

            </div>
            {/*<div className="meta-info">
                <div className="author">
                    <div className="avatar">
                        <img src={props.item.creador.foto_perfil} alt="Foto de autor" />
                    </div>
                    <div className="info">
                        <span>Creador</span>
                        <h6> <Link to={"/" + props.item.creador.nombre}>{props.item.creador.nombre}</Link> </h6>
                    </div>

                </div>
                {props.item.evento && (<div className="tags">{props.item.evento.red}</div>)}

                </div>*/}
            <div className="meta-info">
                {props.item && props.item.creador && (

                    <div className="author">
                        <div className="avatar">
                            <img src={props.item.creador.foto_perfil || "/assets/icon/profile.png"} alt="Foto de comprador" />
                        </div>
                        <div className="info">
                            <span>Creador</span>
                            <h6> <a href={"/" + props.item.creador.titulo_url}>{props.item.creador.nombre}</a> </h6>
                        </div>
                    </div>
                )}
                {props.item && props.item.comprador && (
                    <div className="author">
                        <div className="avatar">
                            <img src={props.item.comprador.foto_perfil || "/assets/icon/profile.png"} alt="Foto de comprador" />
                        </div>
                        <div className="info">
                            <span>Dueño</span>
                            <h6> <a href={"/" + props.item.comprador.titulo_url}>{props.item.comprador.nombre}</a> </h6>
                        </div>
                    </div>
                )}
            </div>
            <div className="card-bottom style-explode">
                {(props.item.price && (
                    <div className="price">
                        <span>Oferta actual</span>
                        <div className="price-details">
                            <h5>{props.item.price}</h5>
                            <span>= {props.item.priceChange}</span>
                        </div>
                    </div>
                )) || (
                        <div className="price" style={{ fontWeight: 'bold' }}>
                            <span style={getStatusColor()}>{Util.getEstadoTicket(props.item.status)}</span>
                        </div>
                    )}

                {false && <Link to="/activity-01" className="view-history reload">Ver historial</Link>}
                {(props.item.status !== 'used' && props.item.comprador._id === stateApp?.usuario?._id) && (<button className="tags" style={{ padding: "0" }} onClick={() => setModalShow(true)}>QR Acceso</button>)}
            </div>
        </div>
    );
}

export default TicketCard;
