import React from 'react';

const PageSubtitle = (props) => {
    return (
        <div className="row">
            <div className="col-12">
                <div className="">
                    <h2 className="tf-title">{props.subtitle}</h2>
                    <div className="heading-line"></div>
                </div>
            </div>
        </div>
    );
};
export default PageSubtitle;