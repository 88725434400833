import React, { useState } from "react";
import swal from 'sweetalert2';
import ReCAPTCHA from "react-google-recaptcha";
import { GoogleLogin } from '@react-oauth/google';
import { Link } from 'react-router-dom';

import useLoading from '../hooks/useLoading';

import Api from "../util/api";
import Util from "../util/util";
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import Settings from "../config/settings";

const SignUp = () => {
  const { startLoading, stopLoading } = useLoading();
  const recaptchaRef = React.useRef();
  const [state, setState] = useState({
    register: {
      email: "",
      pass: "",
      captchaScore: "",
      nombre: ""
    },
  });

  const handleChange = async (e) => {
    var { id, value } = e.target;
    console.log(value.search(" "));
    if (id === "nombre" && !Util.nombre_valido(value)) {
      await swal.fire("Ups!", "Tu nombre de usuario no puede tener espacios ni acentos", "error");
      //value = value.replace(" ", "");
      return;
    }
    console.log(value.search(" "));
    setState((prevState) => ({
      ...prevState,
      register: {
        ...prevState.register,
        [id]: value,
      },
    }));
  };

  function onChangeCaptcha(value) {
    console.log("Captcha value:", value);
    setState((prevState) => ({
      ...prevState,
      register: {
        ...prevState.register,
        captchaToken: value,
      },
    }));
  }

  const validarRegistro = async () => {
    if (state.register.nombre.length < 3) {
      await swal.fire("¡Ups!", 'Tu nombre de usuario debe tener al menos 3 caracteres', "error");
      return false;
    }
    if (!Util.nombre_valido(state.register.nombre)) {
      await swal.fire("¡Ups!", 'Tu nombre de usuario no puede tener espacios ni acentos', "error");
      return false;
    }
    if (!Util.emailValido(state.register.email)) {
      await swal.fire("¡Ups!", 'Debes ingresar un email válido', "error");
      return false;
    }
    if (state.register.pass.length < 8) {
      await swal.fire("¡Ups!", 'Tu contraseña no debe ser menor a 8 dígitos', "error");
      return false;
    }
    if (state.register.pass !== state.register.confirmPass) {
      await swal.fire("¡Ups!", 'Las contraseñas no coindicen', "error");
      return false;
    }
    if (!state.register.captchaToken) {
      await swal.fire("¡Ups!", 'Es necesario que marques la casilla "No soy un robot"', "error");
      return false;
    }
    return true;
  }

  const registerClick = async (e) => {
    if (e) e.preventDefault();
    if (!await validarRegistro()) return;
    try {
      startLoading();
      console.log(state.register);
      var res = await Api.usuarios.crear(state.register);
      console.log(res);
      var data = await res.json();
      if (!await Util.requestSuccess(res)) {
        console.log(data.msg);
        await swal.fire("Ups!", "Error al registrar nuevo usuario. " + data.msg, "error");
        stopLoading();
        return;
      }
      const { value: activation_token } = await swal.fire({
        title: 'Email enviado',
        icon: 'success',
        input: 'text',
        inputLabel: 'Número de verificación',
        inputPlaceholder: 'Ingresa el número de verificación',
        html: "Hemos enviado un email a la dirección " +
          state.register.email +
          " para confirmar que sea real, es necesario que confirmes tu cuenta."
      });

      if (!activation_token) {
        stopLoading();
        return;
      }

      res = await Api.auth.verificarEmail({
        email: state.register.email,
        activation_token
      });

      data = await res.json();
      if (Util.requestSuccess(res)) {
        await swal.fire("Listo!", "Tu email fué verificado, ya puedes iniciar sesión.", "success");
        window.location.href = "/login";
      } else {
        await swal.fire("Ups!", "Error al verificar email." + data.msg, "error");
        stopLoading();
        recaptchaRef.current.reset();
        return;
      }

    } catch (e) {
      console.log(e);
      await swal.fire("Ups!", "Error al registrar nuevo usuario", "error");
      recaptchaRef.current.reset();
    }
    stopLoading();
  };

  const googleLoginSendCredential = async (credential) => {
    try {
      startLoading();
      var res = await Api.auth.googleLogin(
        credential
      );
      var data = await res.json();
      if (await Util.requestSuccess(res)) {
        localStorage.setItem("JWT", data.token);
        window.location.href = "/explore";
      } else {
        await swal.fire("¡Ups!", "Error al iniciar sesión con google.", "error");
      }
    } catch (e) {
      console.log(e);
      await swal.fire("¡Ups!", "Error al iniciar sesión. Es posible que tengas problemas con tu conexión a internet.", "error");
    }
    recaptchaRef.current.reset();
    stopLoading();
  };

  return (
    <div>
      <Header />
        <section className="flat-title-page inner">
          <div className="overlay"></div>
          <div className="themesflat-container">
            <div className="row">
              <div className="col-md-12">
                <div className="page-title-heading mg-bt-12">
                  <h1 className="heading text-center">Nuevo usuario</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="tf-login tf-section">
          <div className="themesflat-container">
            <div className="row">
              <div className="col-12">
                <h2 className="tf-title-heading ct style-1" hidden={true}>
                  Crea tu usuario y comienza a adquirir NFTs
                </h2>

                <div className="flat-form box-login-social">
                  <div className="box-title-login">
                    <h5>Registrate con</h5>
                  </div>
                  <ul>
                    <li>
                      <Link to="#" className="sc-button style-2 fl-button pri-3" hidden={true}>
                        <i className="icon-fl-google-2"></i>
                        <span>Google</span>
                      </Link>
                      <GoogleLogin
                        className="mt-4"
                        onSuccess={googleLoginSendCredential}
                        onError={() => {
                          console.log('Login Failed');
                        }}
                      />
                    </li>
                  </ul>
                </div>

                <div className="flat-form box-login-email">
                  <div className="box-title-login" >
                    <h5>Or login with email</h5>
                  </div>

                  <div className="form-inner">
                    <form id="contactform">
                      <input onChange={handleChange} id="nombre" name="nombre" tabIndex="1" aria-required="true" type="text" placeholder="Nombre" required />
                      <input onChange={handleChange} id="apellido" name="apellido" tabIndex="1" aria-required="true" type="text" placeholder="Apellido" required />
                      <input onChange={handleChange} id="email" name="email" tabIndex="2" aria-required="true" type="email" placeholder="Email" required />
                      <input onChange={handleChange} id="celular" name="celular" tabIndex="2" aria-required="true" type="tel" placeholder="Celular (opcional)" />
                      <input onChange={handleChange} id="pass" name="pass" tabIndex="3" aria-required="true" type="password" placeholder="Contraseña" required />
                      <input onChange={handleChange} id="confirmPass" name="pass" tabIndex="3" aria-required="true" type="password" placeholder="Confirmar contraseña" required />
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        onChange={onChangeCaptcha}
                        sitekey={Settings.auth.captcha_key}
                        style={{ marginLeft: "50px", marginBottom: "20px" }}
                      />
                      <p className="color-2 mb-4">
                        <input
                          type="checkbox"
                          name="aceptoPoliticaPrivacidad"
                          id="aceptoPoliticaPrivacidad"
                        />{" "}
                        Acepto el{" "}
                        <a href="https://www.lifechain.mx/politica-privacidad" rel="noreferrer" target="_blank" className="accent bold">
                          Aviso de privacidad
                        </a>
                        {" y "}
                        <a href="https://www.lifechain.mx/terminos-condiciones" rel="noreferrer" target="_blank" className="accent bold">
                          Terminos y condiciones
                        </a>
                      </p>
                      <div className="row-form style-1">
                        <Link to="/login" className="forgot-pass" >Iniciar sesión</Link>
                      </div>

                      <button onClick={registerClick}>Crear usuario</button>
                    </form>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </section>
      <Footer />
    </div>
  );
}

export default SignUp;
