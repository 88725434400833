import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import img1 from '../assets/images/box-item/img3rank.jpg'
import imga1 from '../assets/images/avatar/author_rank.jpg'

import swal from 'sweetalert2';

import "bootstrap-icons/font/bootstrap-icons.css";

import useLoading from '../hooks/useLoading';

import Api from "../util/api";
import Util from "../util/util";

const Ranking = () => {
    const { startLoading, stopLoading } = useLoading();
    const [state, setState] = useState({
        eventoId: "",
        ticketsReventa: [],
        data: [
            {
                img: img1,
                title: "Hamlet Contemplates Yorick's Yorick's",
                imgAuthor: imga1,
                nameAuthor: 'SalvadorDali',
                volume: '12,4353',
                day: '+3456%',
                week: '-564%',
                price: '12,4353 ETH',
                owners: '3.3k',
                assets: '23k'
            },
        ]
    }
    )
    const [visible, setVisible] = useState(6);
    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 3);
    }


    useEffect(() => {
        const consultarEvento = async (eventoId) => {
            console.log('consultar');
            try {
                startLoading();
                var res = await Api.eventos.get(eventoId);
                var data = await res.json();
                var evento = data.evento;
                var nfts = data.nfts;
                console.log(data);
                if (Util.requestSuccess(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        nfts,
                        evento,
                        eventoId
                    }));
                    consultarTicketsReventa(evento._id);
                } else {
                    console.log(data.msg);
                    await swal.fire("Ups!", "Error al consultar evento " + data.msg, "error");
                    return;
                }
            } catch (e) {
                console.log(e);
                await swal.fire("Ups!", "Error al consultar evento", "error");
            }
            //stopLoading();
        };

        const consultarTicketsReventa = async (eventoId) => {
            console.log('consultar');
            try {
                startLoading();
                var res = await Api.ticket.listEvent(eventoId, {
                    status: 'resale'
                });
                var data = await res.json();
                var ticketsReventa = data.tickets;
                console.log(data);
                if (Util.requestSuccess(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        ticketsReventa,
                    }));
                } else {
                    console.log(data.msg);
                    await swal.fire("Ups!", "Error al consultar tickets en reventa" + data.msg, "error");
                    return;
                }
            } catch (e) {
                console.log(e);
                await swal.fire("Ups!", "Error al consultar tickets en reventa", "error");
            }
            stopLoading();
        };

        if (state.eventoId.length === 0) {
            var initialId = window.location.pathname.replace("/event/resale/", "");
            if (initialId.length > 0) {
                consultarEvento(initialId.replace("/", ""));

                setState((prevState) => ({
                    ...prevState,
                    eventoId: initialId
                }));
            }
        }
    }, []);

    function getFotoPortadaColeccion() {
        if (state.evento && state.evento.url_foto_portada)
            return {
                background: 'url("' + state.evento.url_foto_portada + '")',
                backgroundPosition: "center",
                filter: 'blur(8px)',
                height: "500px",
                backgroundRepeat: "no-repeat"
            };
        return {};
    }

    function calcularPorcentajeCambioPrecio(precio, precio_inicial) {
        const porcentaje = (precio - precio_inicial) / precio_inicial * 100;
        if(Math.floor(porcentaje) !== porcentaje)
            return porcentaje.toFixed(2);
        else
            return porcentaje;
    }

    return (
        <div>
            <Header />
            <section className="flat-title-page inner" style={getFotoPortadaColeccion()}>
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Tickets en venta</h1>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Pages</Link></li>
                                    <li>Ranking</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="tf-section popular-collection bg-home-3">
                <div className="container">
                    <div className="row">
                        <div className="mb-4 col-md-12">
                            <h2 className="heading text-center mt-4 mb-4">{(state.evento?.titulo) || ""}</h2>
                            <p hidden={!state.evento?.nombre_lugar}><i className="bi bi-geo-alt mr-4"></i>{state.evento?.nombre_lugar}</p>
                            <p hidden={!state.evento?.fecha_inicio_evento}> <i className="bi bi-calendar mr-4"></i>{Util.formatDateTimeShort(state.evento?.fecha_inicio_evento)} al {Util.formatDateTimeShort(state.evento?.fecha_fin_evento)}</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="tf-section tf-rank">
                <div className="container-fluid">
                    {state.ticketsReventa.length === 0 ? (
                        <div style={{ width: "100%", textAlign: "center" }}>
                            <h3>No hay tickets en reventa</h3>
                        </div>
                    ) : (
                        <div className="row">
                            <div className="col-md-12">
                                <div className="table-ranking">
                                    <div className="flex th-title">
                                        <div className="column col-4">
                                            <h3>Tickets en venta</h3>
                                        </div>
                                        <div className="column col-2">
                                            <h3>Precio inicial</h3>
                                        </div>
                                        <div className="column col-2">
                                            <h3>Precio actual</h3>
                                        </div>
                                        <div className="column col-2">
                                            <h3>Descuento</h3>
                                        </div>
                                        <div className="column col-2">
                                            <h3></h3>
                                        </div>
                                    </div>
                                    {
                                        state.ticketsReventa.slice(0, visible).map((item, index) => (
                                            <div key={index} className="fl-item2">
                                                <div className="item flex">
                                                    <div className="infor-item flex col-4">
                                                        <div className="media">
                                                            <img src={item.url_imagen || state.evento.url_foto_perfil} alt="Foto del evento" />
                                                        </div>
                                                        <div className="content-collection pad-t-4">
                                                            <h5 className="title mb-15"><Link to={"/ticket/" + state.evento.direccion_smart_contract + "/" + item.nft_id}>{item.nombre}</Link></h5>
                                                            <div className="author flex">
                                                                <div className="author-avatar">
                                                                    <img src={item.comprador.foto_perfil} style={{ maxWidth: "50px" }} alt="Comprador" />
                                                                    <div className="badge"><i className="ripple"></i></div>
                                                                </div>
                                                                <div className="content">
                                                                    <p>Comprado por</p>
                                                                    <h6><Link to={"/" + item.comprador.nombre}>{item.comprador.nombre}</Link></h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="column col-2" >
                                                        <span style={{ textDecoration: "line-through", color: 'gray' }}>${item.precio_inicial + " " + state.evento.moneda}</span>
                                                    </div>
                                                    <div className="column col-2">
                                                        <span>${item.precio + " " + state.evento.moneda}</span>
                                                    </div>
                                                    <div className="column col-2 td3">
                                                        <span>{calcularPorcentajeCambioPrecio(item.precio, item.precio_inicial)}%</span>
                                                    </div>
                                                    <div className="column col-2 td3 form-inner">
                                                        <button style={{ width: "100%" }}>Comprar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                    {
                                        visible < state.data.length &&
                                        <div className="col-md-12 wrap-inner load-more text-center">
                                            <Link to="#" id="load-more" className="sc-button loadmore fl-button pri-3" onClick={showMoreItems}><span>Load More</span></Link>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Ranking;
