import React from 'react';
import { Modal } from "react-bootstrap";
import EventDescription from '../EventDescription';


const CardModal = (props) => {

    return (

        <Modal
            show={props.show}
            onHide={props.onHide}
            style={{ maxHeight: "100vh" }}
        >
            <Modal.Header closeButton></Modal.Header>

            <Modal.Body style={{ maxHeight: "100vh" }}>

                <div className="modal-body space-y-20">
                    <h6>{props.data.nombre} - ${props.data.precio} {props.evento && props.evento.moneda}</h6>
                    <EventDescription style={{ whiteSpace: 'pre-wrap' }} htmlContent = {props.data.descripcion?.replaceAll("<p></p>", "<br/><br/>") || null}/>
                                
                </div>
            </Modal.Body>


        </Modal >

    );
};

export default CardModal;
