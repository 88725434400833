import React from 'react';
import { Link } from 'react-router-dom'
import icon1 from '../../../assets/images/icon/Wallet.png'
import icon2 from '../../../assets/images/icon/Category.png'
import icon3 from '../../../assets/images/icon/Image2.png'
import icon4 from '../../../assets/images/icon/Bookmark.png'

const Create = () => {
    const data = [
        {
            title: "MEDIO DE FINANCIAMIENTO",
            description: "Los fans pueden invertir en sus artistas y financiar proyectos. Los artistas reciben regalías en todas las ventas futuras del NFT.",
            icon: icon1,
            colorbg: "icon-color1"
        },
        {
            title: "DERECHOS Y BENEFICIOS",
            description: "Ofrece entradas a eventos, meet and greets, y beneficios exclusivos al dueño del NFT ganadores.",
            icon: icon4,
            colorbg: "icon-color4",
        },
        {
            title: "ARTÍCULOS COLECCIONABLES",
            description: "Se podrá adquirir contenido audio visual o merch física exclusiva.",
            icon : icon3,
            colorbg: "icon-color2",
        },
        {
            title: "WEB 3.0 Y METAVERSO",
            description: "Gracias a que los NFTs pueden ser usados como certificado de propiedad, son perfectos para crear escasez dentro del metaverso, así como ser el puente entre el munto real y el virtual.",
            icon: icon2,
            
            colorbg : "icon-color3",
        },

    ]
    return (
        <section className="tf-box-icon create tf-section bg-home-3">
            <div className="themesflat-container">
                <h2 className="tf-title style2 mb-4">Usos de NFTs</h2>
                <div className="row">
                    {
                        data.map((item, index) => (
                            <CreateItem key={index} item={item} />
                        ))
                    }
                </div>
            </div>
        </section>
    );
}

const CreateItem = props => (
    <div className='col-lg-3 col-md-6 col-12'>
        <div className="sc-box-icon">
            <div className="image center">
                <div className={`icon-create ${props.item.colorbg}`}>
                    <img src={props.item.icon} alt="" />
                </div>
            </div>
            <h3 className="heading"><Link to="/wallet-connect">{props.item.title}</Link></h3>
            <p className="content">{props.item.description}</p>
        </div>
    </div>

)

export default Create;
