import React from 'react';
import { Modal } from "react-bootstrap";

const CardModal = (props) => {

    return (

        <Modal
            show={props.show}
            onHide={props.onHide}
            style={{ maxHeight: "100vh" }}
        >
            <Modal.Header closeButton></Modal.Header>

            <Modal.Body style={{ maxHeight: "100vh" }}>

                <div className="modal-body space-y-20">
                    {/*<img src={props.data.img} alt={props.data.nombre}/>*/}
                    <h6>{props.data.nombre}</h6>
                    <p>{props.data.descripcion}</p>
                    <img style={{ width: "100px", margin: "10px", marginRight: "25px" }} align="right" src={props.url_foto_firma_artista} alt={"Firma"} />
                </div>
            </Modal.Body>


        </Modal >

    );
};

export default CardModal;
