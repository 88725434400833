import React, { useState, useEffect } from "react";
import swal from 'sweetalert2';
import { Link } from 'react-router-dom'
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import TodayPicks from '../components/layouts/explore-02/TodayPicks';
import MintCollectionFree from '../components/layouts/MintCollectionFree';
import MintCollectionFIAT from '../components/layouts/MintCollectionFIAT';
import PasosCompra from '../components/layouts/PasosCompra';

import useLoading from '../hooks/useLoading';

import Api from "../util/api";
import Util from "../util/util";

const Collection = () => {
    const { startLoading, stopLoading } = useLoading();
    const [state, setState] = useState({
        coleccion: null,
        nfts: [],
        coleccionId: "",
        error: null,
        loading: false,
        metamaskPublicKey: null,
    });

    useEffect(() => {
        const consultarColeccion = async (coleccionId) => {
            console.log('consultarColeccion');
            try {
                startLoading();
                var res = await Api.colecciones.get(coleccionId);
                var data = await res.json();
                var coleccion = data.coleccion;
                var nfts = data.nfts;
                console.log(data);
                if (Util.requestSuccess(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        nfts,
                        coleccion,
                        coleccionId
                    }));
                } else {
                    console.log(data.msg);
                    await swal.fire("Ups!", "Error al consultar colección " + data.msg, "error");
                    return;
                }
            } catch (e) {
                console.log(e);
                await swal.fire("Ups!", "Error al consultar colección", "error");
            }
            stopLoading();
        };



        if (state.coleccionId.length === 0) {
            var initialId = window.location.pathname.replace("/collection/", "");
            if (initialId.length > 0) {
                const MINUTE_MS = 900000;
                const interval = setInterval(() => {
                    consultarColeccion(initialId.replace("/", ""));
                    clearInterval(interval);
                }, MINUTE_MS);

            }
            consultarColeccion(initialId.replace("/", ""));
        }
    }, []);

    function getFotoPortadaColeccion() {
        if (state.coleccion && state.coleccion.url_foto_portada)
            return {
                background: 'url("' + state.coleccion.url_foto_portada + '")',
                backgroundPosition: "center",
                height: "500px"
                //backgroundRepeat: "no-repeat"
            };
        return {};
    }


    return (
        <div className='explore'>
            <Header />
            <section className="flat-title-page inner" style={getFotoPortadaColeccion()}>
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">{(state.coleccion && state.coleccion.titulo) || ""}</h1>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to={"/" + (state.coleccion && state.coleccion.creador.nombre) || ""}>{(state.coleccion && state.coleccion.creador.nombre) || ""}</Link></li>
                                    <li>{(state.coleccion && state.coleccion.titulo) || ""}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="tf-section popular-collection bg-home-3">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="">
                                <div className="row mb-5">
                                    <div className="col-12">
                                        <p style={{ whiteSpace: 'pre-wrap' }}>{state.coleccion && state.coleccion.descripcion}</p>
                                        {/*<EjemplosNFT data={personajes} url_foto_firma_artista={(state.coleccion && state.coleccion.url_foto_firma_artista) || null} />
                                            <div class="video-container" style={{ width: "100%", position: "relative", paddingBottom: "56.25%" }}>
                                                <iframe
                                                    src="https://www.youtube.com/embed/tRrux04ypOc"
                                                    frameborder="0"
                                                    style={{ left: "0", top: "0", height: "100%", width: "100%", position: "absolute" }}
                                                    allow="accelerometer; autoplay; encrypted-media; gyroscope;"
                                                    allowfullscreen></iframe></div>*/ }
                                    </div>
                                    <div className="col-4"></div>
                                    <div className="col-4"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <PasosCompra coleccion={state.coleccion || null} />
            {state.coleccion && (
                state.coleccion.precio === 0 ? (
                    <MintCollectionFree coleccion={state.coleccion || null} metamaskPublicKey={state.metamaskPublicKey} />
                ) : (
                    <MintCollectionFIAT coleccion={state.coleccion || null} metamaskPublicKey={state.metamaskPublicKey} />
                )
            )}

            <TodayPicks coleccion={(state.coleccion)} />
            <Footer />
        </div>
    );
}

export default Collection;
