import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar, A11y } from 'swiper';
import swal from 'sweetalert2';


import Api from "../../../util/api";
import Util from "../../../util/util";

const PopularCollection = props => {
    const [state, setState] = useState({
        data: null,
        error: null,
        loading: false,
    });



    useEffect(() => {
        const consultarColecciones = async (e) => {
            if (e) e.preventDefault();
            try {
                if (props.onLoading) props.onLoading(true);

                var res = await Api.colecciones.list();
                var data = (await res.json()).colecciones;
                console.log(data);

                if(data.length===0){
                    await swal.fire("Ups!", "No se han encontrado colecciones. ", "error");
                }

                if (Util.requestSuccess(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        data
                    }))
                } else {
                    console.log(data.msg);
                    await swal.fire("Ups!", "Error al consultar colecciones. " + data.msg, "error");
                }
            } catch (e) {
                console.log(e);
                await swal.fire("Ups!", "Error al consultar colecciones", "error");
            }
            if (props.onLoading) props.onLoading(false);
        };

        consultarColecciones();
    }, []);


    return (
        <section className="tf-section popular-collection bg-home-3" hidden={!state.data || state.data.length === 0}>
            <div className="themesflat-container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="">
                            <h2 className="tf-title style2">Colecciones</h2>
                            <div className="heading-line"></div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="collection">
                            <Swiper
                                modules={[Navigation, Scrollbar, A11y]}
                                spaceBetween={30}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 1,
                                    },
                                    767: {
                                        slidesPerView: 2,
                                    },
                                    991: {
                                        slidesPerView: 3,
                                    },
                                }}
                                navigation
                                scrollbar={{ draggable: true }}
                            >
                                {
                                    state.data && state.data.map((item, index) => (
                                        <SwiperSlide key={index}>
                                            <PopularCollectionItem item={item} />
                                        </SwiperSlide>
                                    ))
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
PopularCollection.propTypes = {
    data: PropTypes.array.isRequired,
}

const PopularCollectionItem = props => (
    <div className="swiper-container show-shadow carousel4 button-arow-style">
        <div className="swiper-wrapper">
            <div className="swiper-slide">
                <div className="slider-item">
                    <div className="sc-card-collection">
                        <Link to={"/collection/" + props.item.titulo_url}>
                            <div className="media-images-box">
                                <img src={props.item.url_foto_perfil || props.item.creador.foto_perfil || ""} alt={props.item.titulo} />
                            </div>
                        </Link>
                        <div className="card-bottom">
                            <div className="author">
                                <div className="sc-author-box style-2">
                                    <div className="author-avatar">
                                        <img src={props.item?.creador?.foto_perfil} alt={props.item.titulo} className="avatar" />
                                        <div className="badge"><i className="ripple"></i></div>
                                    </div>
                                </div>
                                <div className="content">
                                    <h4><Link to={"/collection/" + props.item.titulo_url}>{props.item.titulo}</Link></h4>
                                    <div className="infor">
                                        <span>Creado por</span>
                                        <span className="name"><Link to={"/" + props.item?.creador?.nombre}>{props.item?.creador?.nombre}</Link></span>
                                    </div>
                                </div>
                            </div>
                            <Link to="/login" className="wishlist-button public heart" hidden={true}><span className="number-like">{props.item.wishlist || "0"}</span></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default PopularCollection;
