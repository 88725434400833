import React, { useState, useEffect } from "react";
import swal from 'sweetalert2';

import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import TodayPicks from '../components/layouts/explore-02/TodayPicks';
import MintCollection from '../components/layouts/MintCollection';
import MintCollectionFree from '../components/layouts/MintCollectionFree';
import MintCollectionFIAT from '../components/layouts/MintCollectionFIAT';
import PasosCompra from '../components/layouts/PasosCompra';
import PageSubtitle from "../components/pagetitle/subtitle";


import EjemplosNFT from '../components/layouts/ejemplos/EjemplosNFT';
import useLoading from '../hooks/useLoading';

import './videoresponsive.css';
import './Coleccion.css';

import Api from "../util/api";
import Util from "../util/util";

const CollectionCXS = () => {
    const { startLoading, stopLoading } = useLoading();
    const [state, setState] = useState({
        coleccion: null,
        nfts: [],
        coleccionId: "",
        error: null,
        loading: false,
        metamaskPublicKey: null,
    });

    const personajes = [
        {
            nombre: "VAINILLA",
            img: "https://lifechainqapublic.s3.amazonaws.com/nft/colecciones/images/VAINILLA.webp",
            descripcion: "Esta flor de vainilla representa el concepto de la simplicidad bajo el argumento de que la felicidad o plenitud son sinónimos de riqueza la cual, contraria al pensamiento popular, no se encuentra en la noción de poseer si no en la capacidad y sensibilidad para apreciar. Vainilla enseña a Cxsinensis a sensibilizar su habilidad de descubrir el verdadero valor en la naturaleza, señalando también lo absurdo de determinar el “valor” de algo o alguien en términos cuantificables (números)."
        },
        {
            nombre: "AFFRESCO",
            img: "https://lifechainqapublic.s3.amazonaws.com/nft/colecciones/images/AFFRESCO.webp",
            descripcion: "Este tierno bebé encarna la indefensa vulnerabilidad que nos invade después de poner alto a una situación que, a pesar de llevar consigo un pasado lleno de belleza y crecimiento, se ha tornado en algo que marchita y lastima. Affresco enseña a Cxsinensis a identificar el momento en que es preciso ponerse a sí mismo en primer plano, reconociendo, aceptando y abrazando el duelo visceral que esta decisión arrastra como un proceso imprescindible de limpieza y renacimiento emocional."
        },
        {
            nombre: "CACTI",
            img: "https://lifechainqapublic.s3.amazonaws.com/nft/colecciones/images/CACTI.webp",
            descripcion: "Este cactus sonriente señala la tendencia equívoca a percibir la sensibilidad emocional (y la vulnerabilidad que esta conlleva) como un defecto o una falla; tendencia que muchas veces nos lleva a sobre analizar, contaminando el corazón con la razón, representando un obstáculo para el desarrollo personal. Cacti enseña a Cxsinensis a ver esta sensibilidad como una gran virtud motivándolo a identificar cuándo es imprescindible callar a la razón y rendirse al sentir."
        },
        {
            nombre: "CELESTE",
            img: "https://lifechainqapublic.s3.amazonaws.com/nft/colecciones/images/CELESTE.webp",
            descripcion: "Esta gran nube blanca representa la grandeza de la imaginación, la oportunidad de crear y construir lugares, momentos, cosas, habilidades, etc; que trasciendan los límites físicos del espacio y el tiempo. Celeste enseña a Cxsinensis la importancia de desarrollar la imaginación y llevarla a todos lados pues es la imaginación la que nos permite cambiar al mundo desde adentro."
        },
        {
            nombre: "DUAL",
            img: "https://lifechainqapublic.s3.amazonaws.com/nft/colecciones/images/DUAL.webp",
            descripcion: "Dual representa el conflicto dentro del ser humano entre la duda (miedo) y la certeza (seguridad) argumentando que dicho conflicto siempre va a existir dado que la resistencia (en este caso a confiar) es parte inherente del ser. Dual enseña la aceptación consciente de dicho conflicto motivando a Cxsinensis a verlo como una oportunidad para traer sus inseguridades a la luz, trazando un blanco claro dónde trabajar para crecer."
        },
        {
            nombre: "PIGMENTOS",
            img: "https://lifechainqapublic.s3.amazonaws.com/nft/colecciones/images/PIGMENTOS.webp",
            descripcion: "Este pequeño ser colorido representa la personalidad humana mediante la siguiente analogía: Así como el color de un objeto es determinado por los pigmentos que absorben y rebotan ciertas frecuencias de luz, la personalidad de un humano está compuesta por la combinación de elementos (emocionales, sociales, físicos, morales, etc) a los que se siente atraído (absorbe) y los que no (rebota), o mejor dicho: por lo que le gusta y no le gusta. Pigmentos enseña a Cxsinensis a descubrir su color (aquello que lo forma) mediante un análisis introspectivo vital para su desarrollo personal pues, aquel que se conoce, conoce y opera mejor su entorno."
        },
        {
            nombre: "PISTACHE",
            img: "https://lifechainqapublic.s3.amazonaws.com/nft/colecciones/images/PISTACHE.webp",
            descripcion: "Este lindo y sonriente pistache verde es la representación de lo absurdo, del flujo y el azar sin rumbo y de la imposibilidad de usar la razón para entender este término. Pistache enseña a Cxsinensis que la respuesta a una de las preguntas más imponentes de nuestra historia: Cuál es el significado de la vida? realmente no tiene respuesta sino aquella que cada realidad individual decida otorgarle. Con la alegría y el optimismo como su único equipaje, lo invita a explorar uno de los lugares más recónditos de la mente humana."
        },
        {
            nombre: "SALMON",
            img: "https://lifechainqapublic.s3.amazonaws.com/nft/colecciones/images/SALMON.webp",
            descripcion: "Este gran pescado advierte el proceso mental que vive un artista musical al buscar el éxito, concepto que permanece oculto detrás de la seductora y embriagante idea de la fama y del dinero. Salmón enseña a Cxsinensis a defender y enfocarse en su propia visión artística buscando que sea esta visión la que defina el concepto del éxito y no viceversa."
        },
        {
            nombre: "AGORASCREFI",
            img: "https://lifechainqapublic.s3.amazonaws.com/nft/colecciones/images/AGORASCREFI.webp",
            descripcion: "Este pandero celebra a las emociones productivas, a todo aquello que nos hace sentir bien sin importar de donde venga o cómo se presente, una danza a la luz del optimismo y la sonrisa. Agorascrefi enseña a Cxsinensis a disfrutar, bailar y vivir."
        },
    ];

    useEffect(() => {
        const consultarColeccion = async (coleccionId) => {
            try {
                startLoading();
                var res = await Api.colecciones.get(coleccionId);
                var data = await res.json();
                console.log(data);
                var coleccion = data.coleccion;
                if (Util.requestSuccess(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        coleccion,
                        coleccionId
                    }));
                } else {
                    console.log(data.msg);
                    stopLoading();
                    await swal.fire("Ups!", "Error al consultar colección " + data.msg, "error");
                    return;
                }
            } catch (e) {
                console.log(e);
                stopLoading();
                await swal.fire("Ups!", "Error al consultar colección", "error");
            }
            stopLoading();
        };

        if (state.coleccionId.length === 0) {
            var initialId = window.location.pathname.replace("/collection/", "");
            if (initialId.length > 0) {
                const MINUTE_MS = 900000;
                const interval = setInterval(() => {
                    consultarColeccion(initialId.replace("/", ""));
                    clearInterval(interval);
                }, MINUTE_MS);

            }
            consultarColeccion(initialId.replace("/", ""));
        }
    }, []);

    function getFotoPortadaColeccion() {
        if (state.coleccion && state.coleccion.url_foto_portada)
            return {
                background: 'url("' + state.coleccion.url_foto_portada + '")',
                backgroundPosition: "center",
                filter: 'blur(8px)',
                height: "500px",
                backgroundRepeat: "no-repeat"
            };
        return {};
    }

    return (
        <div className='explore'>
            <Header />
            <section className="flat-title-page inner" style={getFotoPortadaColeccion()}>
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12" hidden={true}>
                                <h1 className="heading text-center">{(state.coleccion && state.coleccion.titulo) || "Explorar colección"}</h1>
                            </div>
                            <div className="breadcrumbs style2" hidden={true}>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="tf-section popular-collection bg-home-3">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="">
                                <div className="row mb-3">
                                    <div className="col-md-2 col-0"></div>
                                    <div className="col-md-8 col-12">
                                        <div className="container">
                                            <p style={{ whiteSpace: 'pre-wrap' }}>{state.coleccion && state.coleccion.descripcion}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-2 col-0"></div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-12">

                                        <EjemplosNFT data={personajes} url_foto_firma_artista={(state.coleccion && state.coleccion.url_foto_firma_artista) || null} />
                                        <div className="video-container" style={{ width: "100%", position: "relative", paddingBottom: "56.25%" }}>

                                            <div className="content">
                                                <p className='container'>
                                                    <p className='video-container'>
                                                        <iframe align="middle" src="https://www.youtube.com/embed/tRrux04ypOc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                                    </p>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <PageSubtitle subtitle="Experiencias exclusivas" />
                                <div className="row mb-5">
                                    <div className="col-md-2 col-0"></div>
                                    <div className="col-md-8 col-12" style={{fontSize: "18px"}}>
                                        <p style={{ whiteSpace: 'pre-wrap' }}>
                                            <br />Hemos incluido experiencias y artículos únicos dentro de la colección.
                                            <br /><br />Al adquirir tu personaje obtienes experiencias y regalos sorpresa que incluyen:
                                        </p><br />
                                        <ul>
                                            <li>{"\t"}- 01 Gorra oficial de la colección con tu personaje bordado.</li>
                                            <li>{"\t"}- 01 pieza de audio con un verso inédito de la canción de tu personaje.</li>
                                            <li>{"\t"}- 01 Pack de Stickers oficial de la colección.</li>
                                            <li>{"\t"}- Acceso a la “Zona imaginaria”, un espacio previo a cualquier concierto en vivo para compartir tiempo con Cxsinensis.</li>

                                            <li>{"\t"}- Escrito personal de Cxsinensis.</li>
                                            <li>{"\t"}- Cuando todos los ejemplares de un personaje sean coleccionados, un render 3D del personaje (esculpido por Fer Ruiz) será rifado entre los holders.</li>
                                        </ul>
                                        <p>
                                            <br />Al coleccionar varios personajes, el holder obtiene beneficios como:<br />
                                            <br />{"\t"}- 3 personajes - 01 cortesías a 01 show + 02 accesos a la “Zona Imaginaria”
                                            <br />{"\t"}- 6 personajes - 02 cortesías a 01 show + 02 Bebidas cortesía + 02 accesos a la “Zona Imaginaria”
                                            <br />{"\t"}- 9 personajes - 03 cortesías a 01 show + 03 Bebidas cortesía + 03 accesos a la “Zona Imaginaria”
                                            <br /><br />Así como otros beneficios que serán anunciados cercanos a los eventos por email.
                                        </p>
                                    </div>
                                    <div className="col-md-2 col-0"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <PasosCompra coleccion={state.coleccion || null} />
            {state.coleccion && state.coleccion.metodo_pago_cripto && (
                <MintCollection coleccion={state.coleccion || null} metamaskPublicKey={state.metamaskPublicKey} />
            )}
            {state.coleccion && state.coleccion.regalo && (
                <MintCollectionFree coleccion={state.coleccion || null} metamaskPublicKey={state.metamaskPublicKey} />
            )}
            {state.coleccion && state.coleccion.metodo_pago_mercadopago && (
                <MintCollectionFIAT coleccion={state.coleccion || null} metamaskPublicKey={state.metamaskPublicKey} />
            )}

            {state.coleccion && state.coleccion.fecha_lanzamiento && new Date(state.coleccion.fecha_lanzamiento) < (new Date()) &&
                <TodayPicks coleccion={(state.coleccion)} />
            }

            <Footer />
        </div >
    );
}

export default CollectionCXS;
