import React, { useState, useEffect } from "react";
import swal from 'sweetalert2';

import IFrameFooter from '../components/footer/IFrameFooter';
import TodayPicks from '../components/layouts/explore-02/TodayPicks';
import MintCollection from '../components/layouts/MintCollection';
import MintCollectionFree from '../components/layouts/MintCollectionFree';
import MintCollectionFIAT from '../components/layouts/MintCollectionFIAT';

import './Coleccion.css';

import Api from "../util/api";
import Util from "../util/util";
import useLoading from "../hooks/useLoading";

const IFrameColeccion = () => {
    const { startLoading, stopLoading } = useLoading();
    const [state, setState] = useState({
        coleccion: null,
        nfts: [],
        coleccionId: "",
        error: null,
        loading: false,
        metamaskPublicKey: null,
    });


    useEffect(() => {
        const consultarColeccion = async (coleccionId) => {
            try {
                startLoading();
                var res = await Api.colecciones.get(coleccionId);
                var data = await res.json();
                console.log(data);
                var coleccion = data.coleccion;
                if (Util.requestSuccess(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        coleccion,
                        coleccionId
                    }));
                } else {
                    console.log(data.msg);
                    stopLoading();
                    await swal.fire("Ups!", "Error al consultar colección " + data.msg, "error");
                    return;
                }
            } catch (e) {
                console.log(e);
                stopLoading();
                await swal.fire("Ups!", "Error al consultar colección", "error");
            }
            stopLoading();
        };

        if (state.coleccionId.length === 0) {
            var initialId = window.location.pathname.replace("/iframe/collection/", "");
            console.log(initialId);
            if (initialId.length > 0) {
                const MINUTE_MS = 900000;
                const interval = setInterval(() => {
                    consultarColeccion(initialId.replace("/", ""));
                    clearInterval(interval);
                }, MINUTE_MS);

            }
            consultarColeccion(initialId.replace("/", ""));
        }
    }, []);


    return (
        <div className='explore'>
            {state.coleccion && state.coleccion.metodo_pago_cripto && (
                <MintCollection coleccion={state.coleccion || null} metamaskPublicKey={state.metamaskPublicKey} />
            )}
            {state.coleccion && state.coleccion.regalo && (
                <MintCollectionFree coleccion={state.coleccion || null} metamaskPublicKey={state.metamaskPublicKey} />
            )}
            {state.coleccion && state.coleccion.metodo_pago_mercadopago && (
                <MintCollectionFIAT coleccion={state.coleccion || null} metamaskPublicKey={state.metamaskPublicKey} />
            )}

            {state.coleccion && state.coleccion.fecha_lanzamiento && new Date(state.coleccion.fecha_lanzamiento) < (new Date()) &&
                <TodayPicks coleccion={(state.coleccion)} />
            }

            <div hidden={true}>
                <IFrameFooter />
            </div>
        </div >
    );
}

export default IFrameColeccion;
