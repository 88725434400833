var apiURL = process.env.REACT_APP_URL_API;
var apiOpenSeaURL = process.env.REACT_APP_URL_OPENSEA_API;

const genericRequestJson = () => {
  return {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Authorization": localStorage.getItem("JWT"),
    },
    referrerPolicy: "no-referrer",
  };
};

const genericRequestJsonNoJWT = () => {
  return {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    referrerPolicy: "no-referrer",
  };
};

const Api = {
  auth: {
    login: (data) => {
      return fetch(apiURL + "/auth/login", {
        ...genericRequestJson(),
        method: "POST",
        body: JSON.stringify(data),
      });
    },
    googleLogin: (data) => {
      return fetch(apiURL + "/user/oauth2", {
        ...genericRequestJson(),
        method: "POST",
        body: JSON.stringify(data),
      });
    },
    cambiarPass: (data) => {
      return fetch(apiURL + "/user/olvide-contrasena", {
        ...genericRequestJson(),
        method: "POST",
        body: JSON.stringify(data),
      });
    },
    validarCambiarPass: (data, token) => {
      return fetch(apiURL + "/user/restablecer-contrasena/" + token, {
        ...genericRequestJsonNoJWT(),
        method: "POST",
        body: JSON.stringify(data),
      });
    },
    verificarEmail: (data) => {
      return fetch(apiURL + "/user/validar-email/", {
        ...genericRequestJsonNoJWT(),
        method: "POST",
        body: JSON.stringify(data),
      });
    },
  },
  usuarios: {
    crear: (data) => {
      return fetch(apiURL + "/user/", {
        ...genericRequestJson(),
        method: "POST",
        body: JSON.stringify(data),
      });
    },
    get: (id) => {
      return fetch(apiURL + "/user/" + id, genericRequestJson());
    },
    getFullData: () => {
      return fetch(apiURL + "/user/full", genericRequestJson());
    },
    list: () => {
      return fetch(apiURL + "/user/", genericRequestJson());
    },
    actualizar: (id, data) => {
      return fetch(apiURL + "/user/" + id, {
        ...genericRequestJson(),
        method: "PUT",
        body: JSON.stringify(data),
      });
    },
  },
  staff: {
    crear: (data) => {
      return fetch(apiURL + "/staff/", {
        ...genericRequestJson(),
        method: "POST",
        body: JSON.stringify(data),
      });
    },
    getStaffUsuario: (id) => {
      return fetch(apiURL + "/user/" + id, genericRequestJson());
    },
    myTeam: () => {
      return fetch(apiURL + "/staff/myteam", genericRequestJson());
    },
    actualizar: (id, data) => {
      return fetch(apiURL + "/staff/" + id, {
        ...genericRequestJson(),
        method: "PUT",
        body: JSON.stringify(data),
      });
    },
    eliminar: (id) => {
      return fetch(apiURL + "/staff/" + id, {
        ...genericRequestJson(),
        method: "DELETE",
      });
    },
  },
  colecciones: {
    crear: (data) => {
      return fetch(apiURL + "/collection/", {
        ...genericRequestJson(),
        method: "POST",
        body: JSON.stringify(data),
      });
    },
    list: () => {
      return fetch(apiURL + "/collection/", genericRequestJson());
    },
    get: (id) => {
      return fetch(apiURL + "/collection/" + id, genericRequestJson());
    },
    actualizar: (id, data) => {
      return fetch(apiURL + "/collection/" + id, {
        ...genericRequestJson(),
        method: "PUT",
        body: JSON.stringify(data),
      });
    },
  },
  eventos: {
    create: (data) => {
      return fetch(apiURL + "/event/", {
        ...genericRequestJson(),
        method: "POST",
        body: JSON.stringify(data),
      });
    },
    autorizar: (id) => {
      return fetch(apiURL + "/event/authorize/"+id, {
        ...genericRequestJson(),
        method: "POST",
      });
    },
    list: () => {
      return fetch(apiURL + "/event/", genericRequestJson());
    },
    listUsuario: () => {
      return fetch(apiURL + "/events/user", genericRequestJson());
    },
    get: (id) => {
      return fetch(apiURL + "/event/" + id, genericRequestJson());
    },
    update: (id, data) => {
      return fetch(apiURL + "/event/" + id, {
        ...genericRequestJson(),
        method: "PUT",
        body: JSON.stringify(data),
      });
    },
  },
  nft: {
    mint: (data) => {
      return fetch(apiURL + "/assets/", {
        ...genericRequestJson(),
        method: "POST",
        body: JSON.stringify(data),
      });
    },
    list: () => {
      return fetch(apiURL + "/assets/", genericRequestJson());
    },
    listColeccion: (id) => {
      return fetch(apiURL + "/assets/collection/" + id, genericRequestJson());
    },
    listCreador: (creador = null) => {
      if (creador === null)
        return fetch(apiURL + "/assets/", genericRequestJson());
      else
        return fetch(apiURL + "/assets?creador=" + creador, genericRequestJson());
    },
    listComprador: (comprador = null) => {
      if (comprador === null)
        return fetch(apiURL + "/assets/", genericRequestJson());
      else
        return fetch(apiURL + "/assets?comprador=" + comprador, genericRequestJson());
    },
    get: (direccion_smart_contract, nft_id) => {
      return fetch(apiURL + "/assets/" + direccion_smart_contract + "/" + nft_id, genericRequestJson());
    },
  },
  ticket: {
    mint: (data) => {
      return fetch(apiURL + "/ticket/", {
        ...genericRequestJson(),
        method: "POST",
        body: JSON.stringify(data),
      });
    },
    list: () => {
      return fetch(apiURL + "/ticket/", genericRequestJson());
    },
    listEvent: (id, queryParams = null) => {
      if (queryParams) {
        return fetch(apiURL + "/ticket/event/" + id + '?' + new URLSearchParams(queryParams).toString(), genericRequestJson());
      } else {
        return fetch(apiURL + "/ticket/event/" + id, genericRequestJson());
      }
    },
    listCreador: (creador = null) => {
      if (creador === null)
        return fetch(apiURL + "/ticket/", genericRequestJson());
      else
        return fetch(apiURL + "/asseticketts?creador=" + creador, genericRequestJson());
    },
    listComprador: (comprador = null) => {
      if (comprador === null)
        return fetch(apiURL + "/ticket/", genericRequestJson());
      else
        return fetch(apiURL + "/ticket?comprador=" + comprador, genericRequestJson());
    },
    get: (direccion_smart_contract, nft_id) => {
      return fetch(apiURL + "/ticket/" + direccion_smart_contract + "/" + nft_id, genericRequestJson());
    },
    getPasscode: (ticket_id) => {
      return fetch(apiURL + "/ticket/passcode/" + ticket_id, genericRequestJson());
    },
    checkin: (passcode) => {
      return fetch(apiURL + "/ticket/checkin/" + passcode, genericRequestJson());
    },
    actualizar: (id, data) => {
      return fetch(apiURL + "/ticket/update/" + id, {
        ...genericRequestJson(),
        method: "PUT",
        body: JSON.stringify(data),
      });
    },
  },
  tipoTicket: {
    create: (data) => {
      return fetch(apiURL + "/ticket-type/", {
        ...genericRequestJson(),
        method: "POST",
        body: JSON.stringify(data),
      });
    },
    list: () => {
      return fetch(apiURL + "/ticket-type/", genericRequestJson());
    },
    listEvent: (id) => {
      return fetch(apiURL + "/ticket-type/event/" + id, genericRequestJson());
    },
    listCreador: (creador = null) => {
      if (creador === null)
        return fetch(apiURL + "/ticket-type/", genericRequestJson());
      else
        return fetch(apiURL + "/asseticketts?creador=" + creador, genericRequestJson());
    },
    listComprador: (comprador = null) => {
      if (comprador === null)
        return fetch(apiURL + "/ticket-type/", genericRequestJson());
      else
        return fetch(apiURL + "/tipo-ticket?comprador=" + comprador, genericRequestJson());
    },
    get: (direccion_smart_contract, nft_id) => {
      return fetch(apiURL + "/ticket-type/" + direccion_smart_contract + "/" + nft_id, genericRequestJson());
    },
    update: (id, data) => {
      return fetch(apiURL + "/ticket-type/"+id, {
        ...genericRequestJson(),
        method: "PUT",
        body: JSON.stringify(data),
      });
    },
  },
  sponsor: {
    crear: (data) => {
      return fetch(apiURL + "/sponsor/", {
        ...genericRequestJson(),
        method: "POST",
        body: JSON.stringify(data),
      });
    },
    list: () => {
      return fetch(apiURL + "/sponsor/", genericRequestJson());
    },
    listEvent: (id) => {
      return fetch(apiURL + "/sponsor/event/" + id, genericRequestJson());
    },
    listCreador: (creador = null) => {
      if (creador === null)
        return fetch(apiURL + "/sponsor/", genericRequestJson());
      else
        return fetch(apiURL + "/asseticketts?creador=" + creador, genericRequestJson());
    },
    listComprador: (comprador = null) => {
      if (comprador === null)
        return fetch(apiURL + "/sponsor/", genericRequestJson());
      else
        return fetch(apiURL + "/sponsor?comprador=" + comprador, genericRequestJson());
    },
    get: (direccion_smart_contract, nft_id) => {
      return fetch(apiURL + "/sponsor/" + direccion_smart_contract + "/" + nft_id, genericRequestJson());
    },
  },
  ordenCompra: {
    crearMercadoPago: (data) => {
      return fetch(apiURL + "/orden/mercado-pago/", {
        ...genericRequestJson(),
        method: "POST",
        body: JSON.stringify(data),
      });
    },
    getOrdenesEvento: (id) => {
      return fetch(apiURL + "orden/evento/"+id, {
        ...genericRequestJson(),
      });
    },
    crearTicketsMercadoPago: (data) => {
      return fetch(apiURL + "/orden/tickets/mercado-pago/", {
        ...genericRequestJson(),
        method: "POST",
        body: JSON.stringify(data),
      });
    },
  },
  imagenGaleria: {
    crear: (data) => {
      return fetch(apiURL + "/imagenes/", {
        ...genericRequestJson(),
        method: "POST",
        body: JSON.stringify(data),
      });
    },
    list: () => {
      return fetch(apiURL + "/imagenes/", genericRequestJson());
    },
    get: (id) => {
      return fetch(apiURL + "/imagenes/" + id, genericRequestJson());
    },
    actualizar: (id, data) => {
      return fetch(apiURL + "/imagenes/" + id, {
        ...genericRequestJson(),
        method: "PUT",
        body: JSON.stringify(data),
      });
    },
    eliminar: (id, data) => {
      return fetch(apiURL + "/imagenes/" + id, {
        ...genericRequestJson(),
        method: "DELETE",
      });
    },
  },
  buscar: {
    buscar_entidad: (usuarioOrganizacionId) => {
      return fetch(
        apiURL + "/buscar/id/" + usuarioOrganizacionId,
        genericRequestJson()
      );
    },
  },
  prospectos: {
    crear: (data) => {
      return fetch("https://api.lifechain.mx/prospectos/", {
        ...genericRequestJson(),
        method: "POST",
        body: JSON.stringify(data),
      });
    },
  },
  opensea: {
    getNFT: (contract, id) => {
      return fetch(apiOpenSeaURL + "asset/" + contract + "/" + id + "/?force_update=true", {
        ...genericRequestJson(),
        method: "GET",
      });
    },
  },
};

export default Api;
