import React, { useState, useEffect, Fragment } from 'react';
import swal from 'sweetalert2';
import PropTypes from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import SponsorsModal from './SponsorsModal';

import Api from "../../../util/api";
import Util from "../../../util/util";

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

const Sponsors = props => {

    const [state, setState] = useState({
        patrocinadores: null,
        tipoTicketSeleccionado: {},
    });

    const [modalShow, setModalShow] = useState(false);

    function seleccionarTipoTicket(index) {
        setState(() => ({
            tipoTicketSeleccionado: props.tiposTicket[index]
        }));
        setModalShow(true);
    }

    useEffect(() => {
        const consultarSponsors = async (eventoId) => {
            try {
                //handleLoading(true);
                var res = await Api.sponsor.listEvent(eventoId);
                var data = await res.json();
                var patrocinadores = data.patrocinadores;
                console.log(data);
                if (Util.requestSuccess(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        patrocinadores,
                    }));
                } else {
                    console.log(data.msg);
                    await swal.fire("Ups!", "Error al consultar patrocinadores" + data.msg, "error");
                    return;
                }
            } catch (e) {
                console.log(e);
                await swal.fire("Ups!", "Error al consultar patrocinadores", "error");
            }
            //handleLoading(false);
        };

        if (props.evento && !state.patrocinadores) {
            consultarSponsors(props.evento);
        }
    }, [props.evento]);

    return (
        <Fragment>
            <section className="tf-section live-auctions" hidden={!state.patrocinadores || state.patrocinadores.length === 0}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="">
                                <h2 className="tf-title">Partners</h2>
                                <div className="heading-line"></div>
                            </div>
                        </div>
                        <div className="col-12">
                            <Swiper
                                modules={[Navigation, Pagination, Scrollbar, A11y]}
                                spaceBetween={30}

                                breakpoints={{
                                    0: {
                                        slidesPerView: 1,
                                    },
                                    767: {
                                        slidesPerView: 2,
                                    },
                                    991: {
                                        slidesPerView: 3,
                                    },
                                    1300: {
                                        slidesPerView: 4,
                                    },
                                }}
                                pagination={{ clickable: true }}
                                scrollbar={{ draggable: true }}
                                style={{ zIndex: 0 }}
                            >
                                {
                                    state.patrocinadores && state.patrocinadores.map((item, index) => (
                                        <SwiperSlide key={index} >
                                            <a href={item.url_sitio_web} target="_blank" rel="noreferrer">
                                                <div className="swiper-container show-shadow carousel auctions">
                                                    <div className="swiper-wrapper">
                                                        <div className="swiper-slide">
                                                            <div className="slider-item">
                                                                <div className="sc-card-product">
                                                                    <div className="card-media">
                                                                        <img src={item.url_imagen} alt={item.nombre} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </SwiperSlide>
                                    ))
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
}

/*Sponsors.propTypes = {
    data: PropTypes.array.isRequired,
}*/


export default Sponsors;
