import React, { useState, useEffect, useContext } from "react";
import swal from 'sweetalert2';
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import TiposTicket from '../components/layouts/tipos-boleto/TiposTicket';
import Sponsors from '../components/layouts/sponsors/Sponsors';
import { useLoadScript, GoogleMap, Marker } from '@react-google-maps/api';

import "bootstrap-icons/font/bootstrap-icons.css";
import "./Event.css";

import { UserContext } from '../context/UserContext';
import useLoading from '../hooks/useLoading';

import Api from "../util/api";
import Util from "../util/util";
import UtilEth from '../util/eth';
import LoginForm from "./LoginForm";
import EventDescription from "../components/layouts/EventDescription";

const Event = () => {
    const { startLoading, stopLoading } = useLoading();
    const libraries = ["places"];

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyD64ExEBNKY0QGeRQK6S5Rs9gu3hTPzhfg", // Reemplaza con tu clave API
        libraries,
    });

    const [state, setState] = useState({
        selectedIndex: 0,
        evento: {},
        tiposTicket: [],
        ticketsReventa: [],
        patrocinadores: [],
        eventoId: "",
        location: { lat: -99.13305653243955, lng: 19.43273198798258 },
        error: null,
        loading: false,
        metamaskPublicKey: null,
    });

    const { stateApp, setStateApp } = useContext(UserContext);

    async function mintTickets() {

        startLoading();

        var items = [];

        for (var i = 0; i < state.tiposTicket.length; i++) {
            if (!state.tiposTicket[i].cantidad_a_comprar || state.tiposTicket[i].cantidad_a_comprar === 0) continue;
            items.push(state.tiposTicket[i]);
        }

        //Llamar API para generar referencia de compra
        var res = await Api.ordenCompra.crearTicketsMercadoPago({
            coleccion: state.evento._id,
            items,
            address_to: stateApp.usuario.llave_publica_lifechain
        });
        console.log(res);
        var data = await res.json();
        if (await Util.requestSuccess(res)) {
            console.log(data);
            window.location = data.init_point;
        } else {
            stopLoading();
            await swal.fire("Ups!", data.msg, "error");
        }
        stopLoading();
    }

    useEffect(() => {
        const consultarEvento = async (eventoId) => {
            console.log('consultar');
            try {
                startLoading();
                var res = await Api.eventos.get(eventoId);
                var data = await res.json();
                var evento = data.evento;
                var nfts = data.nfts;
                console.log(data);
                if (Util.requestSuccess(res)) {
                    var location = state.location;
                    if (evento.coordenadas_lugar) {
                        location = {
                            lng: evento.coordenadas_lugar[0],
                            lat: evento.coordenadas_lugar[1],
                        }
                    }
                    setState((prevState) => ({
                        ...prevState,
                        nfts,
                        evento,
                        eventoId,
                        location
                    }));
                    consultarTicketsReventa(evento._id);
                } else {
                    console.log(data.msg);
                    await swal.fire("Ups!", "Error al consultar evento " + data.msg, "error");
                    return;
                }
            } catch (e) {
                console.log(e);
                await swal.fire("Ups!", "Error al consultar evento", "error");
            }
            stopLoading();
        };

        const consultarTiposTicket = async (eventoId) => {
            console.log('consultar');
            try {
                startLoading();
                var res = await Api.tipoTicket.listEvent(eventoId);
                var data = await res.json();
                var tiposTicket = data.tiposTicket;
                console.log(data);
                if (Util.requestSuccess(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        tiposTicket,
                    }));
                } else {
                    console.log(data.msg);
                    await swal.fire("Ups!", "Error al consultar tipos de tickets" + data.msg, "error");
                    return;
                }
            } catch (e) {
                console.log(e);
                await swal.fire("Ups!", "Error al consultar tipos de tickets", "error");
            }
            stopLoading();
        };

        

        const consultarTicketsReventa = async (eventoId) => {
            console.log('consultar');
            try {
                startLoading();
                var res = await Api.ticket.listEvent(eventoId);
                var data = await res.json();
                var ticketsReventa = data.tickets;
                console.log(data);
                if (Util.requestSuccess(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        ticketsReventa,
                    }));
                } else {
                    console.log(data.msg);
                    await swal.fire("Ups!", "Error al consultar tickets en reventa" + data.msg, "error");
                    return;
                }
            } catch (e) {
                console.log(e);
                await swal.fire("Ups!", "Error al consultar tickets en reventa", "error");
            }
            stopLoading();
        };

        if (state.eventoId.length === 0) {
            var initialId = window.location.pathname.replace("/event/", "");
            if (initialId.length > 0) {
                consultarEvento(initialId.replace("/", ""));
                consultarTiposTicket(initialId.replace("/", ""));

                setState((prevState) => ({
                    ...prevState,
                    eventoId: initialId
                }));
            }
        }
    }, []);

    const autorizarEventoAPI = async (e) => {
        if(e) e.preventDefault();
        console.log('consultar');
        try {
            startLoading();
            var res = await Api.eventos.autorizar(state.evento._id);
            if (await Util.requestSuccess(res)) {
                await swal.fire("OK!", "Evento autorizado", "success");
            } else {
                await swal.fire("Ups!", "Error al autorizar evento", "error");
            }
        } catch (e) {
            console.log(e);
            await swal.fire("Ups!", "Error al autorizar evento", "error");
        }
        stopLoading();
    };

    function getTiposTicket(tiposBoleto) {
        setState((prevState) => ({
            ...prevState,
            selectedIndex: 1,
        }));
    }

    function getFotoPortadaColeccion() {
        if (state.evento?.url_foto_perfil)
            return {
                background: 'url("' + state.evento.url_foto_perfil + '")',
                backgroundPosition: "center",
                //filter: 'blur(8px)',
                height: "500px",
                backgroundRepeat: "no-repeat",
                zIndex: -1
            };
        return {};
    }

    async function validarPasosCompra(tabClick) {
        if (tabClick > state.selectedIndex) {
            if (tabClick === 1) await swal.fire("Ups!", "Primero debes elegir tus tickets", "error");
        }
    }

    async function regresarPaso() {
        if (state.selectedIndex === 0) return;
        setState((prevState) => ({
            ...prevState,
            selectedIndex: prevState.selectedIndex - 1,
        }));
    }

    async function connectWalletHandler() {
        await UtilEth.connectWalletHandler(setState);
        const wallet = await UtilEth.getMetamaskWallet();
        if (!wallet) {
            setStateApp((prevState) => ({
                ...prevState,
                llave_publica_wallet: null,
                isWalletConnected: false
            }));
            return;
        }
        sessionStorage.setItem("isWalletConnected", "true");
        setStateApp((prevState) => ({
            ...prevState,
            llave_publica_wallet: wallet,
            isWalletConnected: true
        }));
        /*if (props.handleMetamaskPublicKey)
            props.handleMetamaskPublicKey(wallet);*/
    }

    const getTotalTickets = (tiposTicket = null) => {
        if (!tiposTicket) tiposTicket = state.tiposTicket;
        if (!tiposTicket) return 0;
        var totalTickets = 0;
        for (var i = 0; i < tiposTicket.length; i++) {
            if (tiposTicket[i].cantidad_a_comprar) totalTickets += tiposTicket[i].cantidad_a_comprar;
        }
        return totalTickets;
    }

    const getTotalAmount = () => {
        var totalAmount = 0;
        for (var i = 0; i < state.tiposTicket.length; i++) {
            if (state.tiposTicket[i].cantidad_a_comprar) {
                totalAmount += state.tiposTicket[i].cantidad_a_comprar * state.tiposTicket[i].precio;
            }
        }
        return new Intl.NumberFormat('en-US').format(totalAmount);
    }


    //alert(state.evento?.creador?._id);
    //alert(stateApp.usuario?._id);

    console.log(stateApp.usuario);

    return (
        <div className='explore'>
            <Header />
            <section className="flat-title-page inner" style={getFotoPortadaColeccion()}>
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row" hidden={true}>
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">{(state.evento && state.evento.titulo) || ""}</h1>


                            </div>
                            <div className="breadcrumbs style2" hidden={true}>
                                <ul>
                                    <li><Link to={"/" + (state.evento && state.evento.creador && state.evento.creador.nombre) || ""}>{(state.evento && state.evento.creador && state.evento.creador.nombre) || ""}</Link></li>
                                    <li>{(state.evento && state.evento.titulo) || ""}</li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {false && (<section>
                <figure className="c-event-info__banner is-home" style={{ marginTop: "-500px", zIndex: 10 }}><img className="c-event-info__cover" src={state.evento?.url_foto_portada} alt="Banner" style={{ opacity: "1", transition: "opacity 0.3s ease 0s" }} /></figure>
            </section>)}
            <section className="tf-section popular-collection bg-home-3">
                <div className="container">
                    <div className="row">
                        <div className="mb-4 col-md-12">
                            <h2 className="heading text-center mt-4 mb-4">{(state.evento && state.evento.titulo) || ""}</h2>
                        </div>

                        {state.evento?.creador?._id && state.evento?.creador?._id === stateApp.usuario?._id ? (
                            <div className="mb-4 col-md-12" style={{ textAlign: "right" }}>
                                <Link className="text-right mr-2" to={"/event/edit/" + (state.evento?.titulo_url) || ""}>
                                    <button className="sc-button loadmore fl-button pri-3" ><span>Editar evento</span></button>
                                </Link>
                                <Link className="text-right" to={"/event/reports/" + (state.evento?.titulo_url) || ""}>
                                    <button className="sc-button loadmore fl-button pri-3" ><span>Reportes</span></button>
                                </Link>
                            </div>

                        ) : null}
                        {stateApp.usuario?.is_superuser && !state.evento?.direccion_smart_contract && (
                            <div className="mb-4 col-md-12" style={{ textAlign: "right" }}>
                                <button className="sc-button loadmore fl-button pri-3" onClick={autorizarEventoAPI}><span>Activar Evento</span></button>
                            </div>
                        )}

                    </div>
                    <div className="mb-4" hidden={!state.tiposTicket || state.tiposTicket.length === 0}>
                        <Tabs selectedIndex={state.selectedIndex} className="authors-2" onSelect={() => { }}>
                            <TabList hidden={true}>
                                <Tab tabIndex="0" onClick={validarPasosCompra}>1. Tickets</Tab>
                                <Tab tabIndex="1" onClick={validarPasosCompra}>2. Checkout</Tab>
                            </TabList>

                            <div className="content-tab">
                                <div className="content-inner">
                                    <div className="row">
                                        <TabPanel key={0}>
                                            <TiposTicket evento={state.evento || null} tiposTicket={state.tiposTicket} getTiposTicket={getTiposTicket} />
                                        </TabPanel>
                                        <TabPanel key={1}>
                                            <div style={{ width: "100%", textAlign: "center" }}>{stateApp.usuario ? (
                                                <div className="container mt-5">
                                                    <div className="row">
                                                        <div className="col-xl-2 col-md-2"></div>
                                                        <div className="col-xl-8 col-md-8 col-12">
                                                            <div className="container">


                                                                {
                                                                    state.tiposTicket && state.tiposTicket.map((item, index) => {
                                                                        return item.cantidad_a_comprar > 0 ?
                                                                            <div className="row" key={index}>
                                                                                <div className="col-md-6 col-7"><p>{item.nombre} </p></div>
                                                                                <div className="col-md-2 col-1"><p>x{item.cantidad_a_comprar}</p></div>
                                                                                <div className="col-md-4 col-4" style={{ textAlign: "right" }}><p>${new Intl.NumberFormat('en-US').format(item.precio)} MXN</p></div>
                                                                            </div>

                                                                            : null
                                                                    })
                                                                }
                                                                <hr />
                                                                <br />
                                                                <div className="row mb-5">
                                                                    <div className="col-md-6 col-7"><p>Total a pagar </p></div>
                                                                    <div className="col-md-2 col-1"><p>x{getTotalTickets()}</p></div>
                                                                    <div className="col-md-4 col-4" style={{ textAlign: "right" }}><p> ${getTotalAmount()} MXN</p></div>
                                                                </div>
                                                                <div className="row mb-5">
                                                                    <div className="col-md-8">
                                                                        <p style={{ overflowWrap: "break-word" }}>{getTotalTickets() > 1 ? "Wallet que recibirá los boletos:" : "Wallet que recibirá el boleto:"} </p>
                                                                        <p>{(stateApp.isWalletConnected && stateApp.llave_publica_wallet + " (Metamask)") || (stateApp.usuario && stateApp.usuario.llave_publica_lifechain && (stateApp.usuario.llave_publica_lifechain.substring(0, 30) + "... (Generada por LifeChain)")) || "No se encontro wallet"}</p>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className="row form-inner" style={{ width: "100%", textAlign: "center" }}>
                                                                            <p>Si prefieres conectar otra wallet con Metamask, da click <a href="" onClick={connectWalletHandler}>aquí</a></p>
                                                                        </div>
                                                                    </div>
                                                                    <br /><br />
                                                                </div>

                                                            </div>

                                                        </div>
                                                        <div className="col-xl-2 col-md-2"></div>

                                                    </div>
                                                    <div className="row form-inner">
                                                        <div className="col-md-6 col" style={{ textAlign: "right" }}>
                                                            <button style={{ width: "100%" }} onClick={regresarPaso}>Regresar</button>
                                                        </div>
                                                        <div className="col-md-6 col">
                                                            <button style={{ width: "100%" }} onClick={mintTickets}>Pagar</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (<LoginForm />)}</div>
                                        </TabPanel>
                                    </div>
                                </div>
                            </div>
                        </Tabs >
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="mb-5" hidden={!state.evento?.descripcion || state.evento.descripcion.length === 0}>
                                <h4 className="heading mb-2">About this event</h4>
                                {!state.evento?.fecha_inicio_evento ? null :
                                    <p className="mt-4 mb-4" > <i className="bi bi-calendar mr-4"></i>{Util.formatDateTimeShort(state.evento.fecha_inicio_evento)} al {Util.formatDateTimeShort(state.evento.fecha_fin_evento)}</p>
                                }
                                <p style={{ whiteSpace: 'pre-wrap' }} hidden={true}>{state.evento && state.evento.descripcion}</p>
                                <EventDescription style={{ whiteSpace: 'pre-wrap' }} htmlContent={state.evento?.descripcion?.replaceAll("<p></p>", "<br/><br/>") || null} />
                                {/*<EjemplosNFT data={personajes} url_foto_firma_artista={(state.evento && state.evento.url_foto_firma_artista) || null} />
                                            <div className="video-container" style={{ width: "100%", position: "relative", paddingBottom: "56.25%" }}>
                                                <iframe
                                                    src="https://www.youtube.com/embed/tRrux04ypOc"
                                                    frameborder="0"
                                                    style={{ left: "0", top: "0", height: "100%", width: "100%", position: "absolute" }}
                                                    allow="accelerometer; autoplay; encrypted-media; gyroscope;"
                                                    allowfullscreen></iframe></div>*/ }




                            </div>
                        </div>
                        <div className="col-md-4 mb-5">
                            <div hidden={!state.evento?.direccion_lugar}>
                                <h4 className="heading mb-2">Place</h4>

                                <p hidden={!state.evento.nombre_lugar} className="mt-4 mb-4"><i className="bi bi-geo-alt mr-4"></i>{state.evento?.nombre_lugar}</p>
                                <p hidden={!state.evento.direccion_lugar}className="mb-2 mt-2">{state.evento?.direccion_lugar}</p>
                                {isLoaded && state.location && (
                                    <GoogleMap
                                        mapContainerStyle={{ width: "100%", height: "300px" }}
                                        center={state.location}
                                        zoom={15}
                                        style={{ width: "100%" }}
                                        options={{
                                            streetViewControl: false,
                                            disableDefaultUI: true,
                                            zoomControl: true
                                        }}
                                    >
                                        <Marker position={state.location} />
                                    </GoogleMap>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/*state.evento && (
                state.evento.precio === 0 ? (
                    <MintCollectionFree coleccion={state.evento || null} metamaskPublicKey={state.metamaskPublicKey} />
                ) : (
                    <MintCollectionFIAT coleccion={state.evento || null} metamaskPublicKey={state.metamaskPublicKey} />
                )
                )*/}

            <Sponsors evento={state.eventoId} hidden={!state.patrocinadores || state.patrocinadores.length === 0} />

            <Footer />
        </div >
    );
}

export default Event;
