import Home03 from "./Home03";
import ExploreCollections from "./ExploreCollections";
import ExploreEvents from "./ExploreEvents";
import LiveAuctions from "./LiveAuctions";
import ItemDetailsNFT from "./ItemDetailsNFT";
import ItemDetailsTicket from "./ItemDetailsTicket";
import ResaleTicketList from "./ResaleTicketList";
import ItemDetails02 from "./ItemDetails02";
import Activity01 from "./Activity01";
import Activity02 from "./Activity02";
import Blog from "./Blog";
import BlogDetails from "./BlogDetails";
import HelpCenter from "./HelpCenter";
import Authors01 from "./Authors01";
import Authors02 from "./Authors02";
import Redeem from "./Redeem";
import CreateItem from "./CreateItem";
import CreateEvent from "./CreateEvent";
import EditProfile from "./EditProfile";
import Ranking from "./Ranking";
import MyTeam from "./MyTeam";
import Login from "./Login";
import SignUp from "./SignUp";
import NoResult from "./NoResult";
import FAQ from "./FAQ";
import Contact01 from "./Contact01";
import Collection from "./Collection";
import Event from "./Event";
import EventCheckIn from "./EventCheckIn";
import EventReports from "./EventReports";
import AllEventsReports from "./AllEventsReports";
import EventSold from "./EventSold";
import IFrameColeccion from "../iframes/IFrameColeccion";
import IFrameEvento from "../iframes/IFrameEvento";
import IFrameEventos from "../iframes/IFrameEventos";
import IFrameEventSold from "../iframes/IFrameEventSold";
import CollectionCXS from "./CollectionCXS";
import ForgotPass from "./ForgotPass";


const routes = [
  { path: '/', component: <Home03 /> },
  { path: '/collections', component: <ExploreCollections /> },
  { path: '/collection/imaginario-mundo-cxsinensis', component: <CollectionCXS /> },
  { path: '/collection/:id', component: <Collection /> },
  { path: '/events', component: <ExploreEvents /> },
  { path: '/event/:id', component: <Event /> },
  { path: '/event/checkin/:id', component: <EventCheckIn /> },
  { path: '/event/resale/:id', component: <ResaleTicketList /> },
  { path: '/event/reports/:id', component: <EventReports />, private: true },
  { path: '/event/edit/:id', component: <CreateEvent /> },
  { path: '/event/create', component: <CreateEvent /> },
  { path: '/event/reports/', component: <AllEventsReports /> },
  { path: '/event/sold/:id', component: <EventSold /> },
  { path: '/myteam', component: <MyTeam /> },
  { path: '/iframe/collection/:id', component: <IFrameColeccion /> },
  { path: '/iframe/event/:id', component: <IFrameEvento /> },
  { path: '/iframe/events', component: <IFrameEventos /> },
  { path: '/iframe/event/sold/:id', component: <IFrameEventSold /> },
  { path: '/live-auctions', component: <LiveAuctions /> },
  { path: '/asset/:smart_contract_address/:id', component: <ItemDetailsNFT /> },
  { path: '/ticket/:smart_contract_address/:id', component: <ItemDetailsTicket /> },
  { path: '/item-details-02', component: <ItemDetails02 /> },
  { path: '/activity-01', component: <Activity01 /> },
  { path: '/activity-02', component: <Activity02 /> },
  { path: '/blog', component: <Blog /> },
  { path: '/blog-details', component: <BlogDetails /> },
  { path: '/help-center', component: <HelpCenter /> },
  { path: '/redeem', component: <Redeem /> },
  { path: '/create-item', component: <CreateItem /> },
  { path: '/create-event', component: <CreateEvent /> },
  { path: '/edit-profile', component: <EditProfile /> },
  { path: '/ranking', component: <Ranking /> },
  { path: '/login', component: <Login /> },
  { path: '/sign-up', component: <SignUp /> },
  { path: '/forgotpass', component: <ForgotPass /> },
  { path: '/no-result', component: <NoResult /> },
  { path: '/faq', component: <FAQ /> },
  { path: '/contact', component: <Contact01 /> },
  { path: '/authors-01', component: <Authors01 /> },
  { path: '/:usuario', component: <Authors02 /> },
]

export default routes;