import React from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import heroSliderData from '../assets/fake-data/data-slider-3';
import Beneficios from '../components/layouts/home-3/Beneficios';
import Create from '../components/layouts/home-2/Create';
import Slider from '../components/slider/Slider';
import PopularCollection from '../components/layouts/home-3/PopularCollection';
import PopularEvents from '../components/layouts/home-3/PopularEvents';
import popularCollectionData from '../assets/fake-data/data-popular-collection';
import './videoresponsive.css';

const Home03 = () => {
    return (
        <div className='home-3'>
            <Header />
            <Slider data={heroSliderData} />
            <Create />
            <div style={{ backgroundColor: "black" }} className="content">
                <div className='container'>
                    <div className='video-container'>
                        <iframe align="middle" src="https://www.youtube.com/embed/bCtEmm3Rqpo" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </div>
                </div>
            </div>
            <Beneficios />
            {/*<LiveAuction data={liveAuctionData} />*/}
            {/*<TopSeller data={topSellerData} />*/}
            {/*<TodayPicks data={todayPickData} />*/}
            <PopularEvents data={popularCollectionData} />
            <PopularCollection data={popularCollectionData} />
            <Footer />
        </div>
    );
}

export default Home03;
