import React, { useState, useRef } from "react";
import swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import { GoogleLogin } from '@react-oauth/google';
import useUser from '../hooks/useUser';
import useAuth from '../hooks/useAuth';
import useLoading from '../hooks/useLoading';

import Settings from "../config/settings";
import Api from "../util/api";
import Util from "../util/util";

const LoginForm = (props) => {
    const { startLoading, stopLoading } = useLoading();
    const { login } = useAuth();
    const recaptchaRef = useRef();
    const [state, setState] = useState({
        auth: {
            email: "",
            pass: "",
            captchaScore: "",
        },
        passSelected: false,
        error: null,
    });

    const { stateApp, setStateApp, showLoginModal } = useUser();

    const handleChange = (e) => {
        const { id, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            auth: {
                ...prevState.auth,
                [id]: value,
            },
        }));
    };



    function onChangeCaptcha(value) {
        setState((prevState) => ({
            ...prevState,
            auth: {
                ...prevState.auth,
                captchaToken: value,
            },
        }));
    }

    const validarRegistro = async () => {
        if (!Util.emailValido(state.auth.email)) {
            await swal.fire("¡Ups!", 'Debes ingresar un email válido', "error");
            return false;
        }
        if (state.auth.pass.length < 0) {
            await swal.fire("¡Ups!", 'Debes ingresar una contraseña', "error");
            return false;
        }
        if (!state.auth.captchaToken) {
            await swal.fire("¡Ups!", 'Es necesario que marques la casilla "No soy un robot"', "error");
            return false;
        }
        return true;
    }

    const loginClick = async (e) => {
        if (e) e.preventDefault();
        try {
            if (!await validarRegistro()) return;
            startLoading();
            var res = await Api.auth.login(
                state.auth
            );
            var data = await res.json();
            if (await Util.requestSuccess(res)) {
                localStorage.setItem("JWT", data.token);
                Util.getValidJWTInfo(setStateApp);
                login(data.token);
                if (props.redirect) window.location.href = props.redirect;

            } else {
                if (res.status !== 403) {
                    await swal.fire("¡Ups!", data.msg, "error");
                    recaptchaRef.current.reset();
                    stopLoading();
                    return;
                }

                const { value: activation_token } = await swal.fire({
                    title: 'Email enviado',
                    icon: 'success',
                    input: 'text',
                    inputLabel: 'Número de verificación',
                    inputPlaceholder: 'Ingresa el número de verificación',
                    html: "Hemos enviado un email a la dirección " +
                        state.auth.email +
                        " para confirmar que sea real, es necesario que confirmes tu cuenta."
                });

                if (!activation_token) {
                    stopLoading();
                    return;
                }

                res = await Api.auth.verificarEmail({
                    email: state.auth.email,
                    activation_token
                });

                data = await res.json();
                if (Util.requestSuccess(res)) {
                    await swal.fire("Listo!", "Tu email fué verificado, ya puedes iniciar sesión.", "success");
                } else {
                    await swal.fire("Ups!", "Error al verificar email." + data.msg, "error");
                    recaptchaRef.current.reset();
                    stopLoading();
                    return;
                }
            }
        } catch (e) {
            console.log(e);
            await swal.fire("¡Ups!", "Error al iniciar sesión. Es posible que tengas problemas con tu conexión a internet.", "error");

            stopLoading();
        }
        recaptchaRef.current.reset();
    };

    const googleLoginSendCredential = async (credential) => {
        try {
            startLoading();
            var res = await Api.auth.googleLogin(
                credential
            );
            var data = await res.json();
            if (await Util.requestSuccess(res)) {
                localStorage.setItem("JWT", data.token);
                login(data.token);
                const jsonUser = JSON.parse(
                    decodeURIComponent(escape(atob(data.token.split(".")[1])))
                );
                setStateApp((prevState) => ({
                    ...prevState,
                    usuario: {
                        _id: jsonUser._id,
                        nombre: jsonUser.nombre,
                        foto_perfil: jsonUser.foto_perfil,
                        email: jsonUser.email,
                        llave_publica_lifechain: jsonUser.llave_publica_lifechain
                    },
                }));
                if (props.redirect) window.location.href = props.redirect;
            } else {
                await swal.fire("¡Ups!", "Error al iniciar sesión con google.", "error");
            }
        } catch (e) {
            console.log(e);
            await swal.fire("¡Ups!", "Error al iniciar sesión. Es posible que tengas problemas con tu conexión a internet.", "error");

            stopLoading();
        }
    };

    return (

        <div className="themesflat-container" style={{ width: "100%" }}>
            <div className="row">
                <div className="col-12">
                    <h2 className="tf-title-heading ct style-1" hidden={true}>
                        Login
                    </h2>


                    <div className="flat-form box-login-social" >
                        <div className="box-title-login">
                            <h5>Inicia sesión con</h5>
                        </div>

                        <ul>
                            <li>
                                <Link to="#" className="sc-button style-2 fl-button pri-3" hidden={true} >
                                    <i className="icon-fl-google-2"></i>
                                    <span>Google</span>
                                </Link>
                                <GoogleLogin
                                    className="mt-4"
                                    onSuccess={googleLoginSendCredential}
                                    onError={() => {
                                        console.log('Login Failed');
                                    }}
                                />
                            </li>
                        </ul>
                    </div>

                    <div className="flat-form box-login-email">
                        <div className="box-title-login">
                            <h5>O usa tu email</h5>
                        </div>

                        <div className="form-inner">
                            <form action="#" id="contactform">
                                <input onChange={handleChange} id="email" name="email" tabIndex="1" aria-required="true" type="email" placeholder="Email" required />
                                <input onChange={handleChange} id="pass" name="pass" tabIndex="2" aria-required="true" type="password" placeholder="Contraseña" required />
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    onChange={onChangeCaptcha}
                                    sitekey={Settings.auth.captcha_key}
                                    style={{ marginLeft: "50px", marginBottom: "20px" }}
                                />
                                <div className="row-form style-1">
                                    <Link to="/sign-up" className="forgot-pass">Crea tu cuenta</Link>
                                    <Link to="/forgotpass" className="forgot-pass">¿Olvidaste tu contraseña?</Link>
                                </div>

                                <button onClick={loginClick} className="mb-4" hidden={stateApp.isLoading}>Iniciar sesión</button>


                            </form>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    );
}

export default LoginForm;