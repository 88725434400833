import React, { useState, useEffect, useContext } from "react";
import swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import CardModal from '../components/layouts/CardModal';
import NFTCard from '../components/nftCard/NFTCard';
import TicketCard from '../components/ticketCard/TicketCard';

import { UserContext } from '../context/UserContext';
import useLoading from '../hooks/useLoading';

import avt from '../assets/images/avatar/avt-author-tab.jpg'
import img1 from '../assets/images/box-item/card-item-3.jpg'
import imga1 from '../assets/images/avatar/avt-1.jpg'
import imgCollection1 from '../assets/images/avatar/avt-18.jpg'
import img2 from '../assets/images/box-item/card-item-4.jpg'
import imga2 from '../assets/images/avatar/avt-2.jpg'
import imgCollection2 from '../assets/images/avatar/avt-18.jpg'


import Api from "../util/api";
import Util from "../util/util";


const Authors02 = () => {
    const { startLoading, stopLoading } = useLoading();
    const [menuTab] = useState(
        [
            {
                class: 'active',
                name: 'Creados'
            },
            {
                class: '',
                name: 'Comprados'
            }
        ]
    )
    const [panelTab] = useState(
        [
            {
                id: 1,
                dataContent: [
                    {
                        img: img1,
                        title: "The RenaiXance Rising the sun ",
                        tags: "bsc",
                        imgAuthor: imga1,
                        nameAuthor: "SalvadorDali",
                        price: "4.89 ETH",
                        priceChange: "$12.246",
                        wishlist: "100",
                        imgCollection: imgCollection1,
                        nameCollection: "Creative Art 3D"
                    }
                ]
            },
            {
                id: 2,
                dataContent: [
                    {
                        id: 2,
                        img: img2,
                        title: "The RenaiXance Rising the sun ",
                        tags: "bsc",
                        imgAuthor: imga2,
                        nameAuthor: "SalvadorDali",
                        price: "4.89 ETH",
                        priceChange: "$12.246",
                        wishlist: "100",
                        imgCollection: imgCollection2,
                        nameCollection: "Creative Art 3D"
                    }
                ]
            },
        ]
    )

    const [visible, setVisible] = useState(8);
    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 4);
    }

    const [modalShow, setModalShow] = useState(false);

    const { stateApp, setStateApp } = useContext(UserContext);

    const [state, setState] = useState({
        tabIndex: -1,
        usuario: null,
        usuarioId: "",
        error: null,
        loading: true,
        nftsCreados: [],
        nftsComprados: [],
        ticketsComprados: []
    });

    

    useEffect(async () => {
        const consultarUsuario = async (usuarioId) => {
            try {
                startLoading();
                var res = await Api.usuarios.get(usuarioId);
                var data = await res.json();
                var usuario = data.usuario;
                console.log(data);
                if (await Util.requestSuccess(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        usuario,
                        usuarioId
                    }));
                    //consultarNFTsCreados(usuario._id);
                    consultarNFTsComprados(usuario._id);
                    consultarTicketsComprados(usuario._id);
                } else {
                    console.log(data.msg);
                    await swal.fire("Ups!", "Error al consultar usuario " + data.msg, "error");
                }
            } catch (e) {
                console.log(e);
                await swal.fire("Ups!", "Error al consultar usuario", "error");
            }
            //stopLoading();
        };

        const consultarNFTsCreados = async (usuarioId) => {
            try {
                startLoading();
                var res = await Api.nft.listCreador(usuarioId);
                var data = (await res.json());
                var nftsCreados = data.nfts;
                console.log(data);
                if (Util.requestSuccess(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        nftsCreados
                    }))
                } else {
                    console.log(data.msg);
                    await swal.fire("Ups!", "Error al consultar NFTs. " + data.msg, "error");
                }
            } catch (e) {
                console.log(e);
                await swal.fire("Ups!", "Error al consultar NFTs", "error");
            }
            stopLoading();
        };

        const consultarNFTsComprados = async (usuarioId) => {
            try {
                startLoading();
                var res = await Api.nft.listComprador(usuarioId);
                var data = (await res.json());
                var nftsComprados = data.nfts;
                console.log(data);
                if (Util.requestSuccess(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        nftsComprados
                    }))
                } else {
                    console.log(data.msg);
                    await swal.fire("Ups!", "Error al consultar NFTs. " + data.msg, "error");
                }
            } catch (e) {
                console.log(e);
                await swal.fire("Ups!", "Error al consultar NFTs", "error");
            }
            stopLoading();
        };

        const consultarTicketsComprados = async (usuarioId) => {
            try {
                startLoading();
                var res = await Api.ticket.listComprador(usuarioId);
                var data = (await res.json());
                var ticketsComprados = data.tickets;
                console.log(data);
                if (Util.requestSuccess(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        ticketsComprados
                    }))
                } else {
                    console.log(data.msg);
                    await swal.fire("Ups!", "Error al consultar Tickets. " + data.msg, "error");
                }
            } catch (e) {
                console.log(e);
                await swal.fire("Ups!", "Error al consultar Tickets", "error");
            }
            stopLoading();
        };

        if (state.usuarioId.length === 0) {
            var initialId = window.location.pathname.replace("/", "");
            initialId = initialId.replace("/", "");
            if (initialId.length > 0) {
                if (initialId === "profile") {
                    const usuario = await Util.getValidJWTInfo(setStateApp);
                    if (!usuario) {
                        setStateApp((prevState) => ({
                            ...prevState,
                            showLoginModal: true,
                            initialId,
                        }));
                        return;
                    } else {
                        consultarUsuario(usuario._id);
                        return;
                    }

                } else {
                    consultarUsuario(initialId);
                    setStateApp((prevState) => ({
                        ...prevState,
                        initialId
                    }));
                    //consultarNFTsComprados(initialId);
                    //consultarTicketsComprados(initialId);
                }

            }
        }
    }, [stateApp?.usuario?._id]);


    return (
        <div className='authors-2'>
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
            </section>
            <section className="tf-section sc-explore-2">
                <div className="themesflat-container">
                    <div className="flat-tabs tab-authors">
                        <div className="author-profile flex">
                            <div className="feature-profile">
                                <img src={state.usuario?.foto_perfil || avt} alt={state.usuario?.nombre || "Nombre de usuario"} className="avatar" />
                            </div>
                            <div className="infor-profile">
                                <span>Perfil de usuario</span>
                                <h2 className="title">{state.usuario?.nombre || "Nombre de usuario"}</h2>
                                <p style={{ whiteSpace: "pre-wrap" }} className="content" >{state.usuario?.bio || ""}</p>
                                <form hidden={!state.usuario?.llave_publica}>
                                    <input type="text" className="inputcopy" defaultValue={state.usuario?.llave_publica || '0x...'} readOnly />
                                    <button type="button" className="btn-copycode"><i className="icon-fl-file-1"></i></button>
                                </form>
                            </div>
                            <div className="widget-social style-3">
                                <ul>
                                    <li hidden={!state.usuario?.url_twitter}><a href={state.usuario?.url_twitter} target="_blank" rel="noreferrer"><i className="fab fa-twitter"></i></a></li>
                                    <li hidden={!state.usuario?.url_instagram}><a href={state.usuario?.url_instagram} target="_blank" rel="noreferrer"><i className="fab fa-instagram"></i></a></li>
                                    <li hidden={!state.usuario?.url_youtube}><a href={state.usuario?.url_youtube} target="_blank" rel="noreferrer"><i className="fab fa-youtube"></i></a></li>
                                    <li hidden={!state.usuario?.url_tiktok}><a href={state.usuario?.url_tiktok} target="_blank" rel="noreferrer"><i className="icon-fl-tik-tok-2"></i></a></li>
                                    <li hidden={!state.usuario?.url_facebook}><a href={state.usuario?.url_facebook} target="_blank" rel="noreferrer"><i className="fab fa-facebook"></i></a></li>
                                    <li hidden={!state.usuario?.url_externo}><a href={state.usuario?.url_externo} target="_blank" rel="noreferrer"><i className="fas fa-link"></i></a></li>
                                </ul>
                                {/*<div className="btn-profile"><Link to="/login" className="sc-button style-1 follow">Follow</Link></div>*/}
                            </div>
                        </div>
                        <Tabs selectedIndex={(state.tabIndex > -1 && state.tabIndex) || (state.nftsCreados.length > state.nftsComprados.length) ? 0 : 1} onSelect={tabIndex => setState((prevState) => ({ ...prevState, tabIndex: (tabIndex - 1) * -1 }))}>
                            <TabList>
                                <Tab tabIndex="0">Creados</Tab>
                                <Tab tabIndex="1">Adquiridos</Tab>
                            </TabList>

                            <div className="content-tab">
                                <div className="content-inner">
                                    <div className="row">
                                        <TabPanel key={1}>
                                            {state.nftsCreados.length === 0 && (<p style={{ width: "100%", textAlign: "center" }}>Este usuario no tiene NFTs creados en LifeChain.</p>)}
                                            {
                                                state.nftsCreados?.length > 0 && state.nftsCreados.slice(0, visible).map((item, id) => (
                                                    <NFTCard item={item} key={id} />
                                                ))
                                            }
                                            {
                                                visible < state.nftsCreados.length &&
                                                <div className="col-md-12 wrap-inner load-more text-center">
                                                    <Link to="#" id="load-more" className="sc-button loadmore fl-button pri-3" onClick={showMoreItems}><span>Cargar más</span></Link>
                                                </div>
                                            }
                                        </TabPanel>
                                        <TabPanel key={2}>
                                            {
                                                state.ticketsComprados?.length > 0 && state.ticketsComprados.slice(0, visible).map((item, id) => (
                                                    <TicketCard item={item} key={id} />
                                                ))
                                            }
                                            {
                                                state.nftsComprados?.length > 0 && state.nftsComprados.slice(0, visible).map((item, id) => (
                                                    <NFTCard item={item} key={id} />
                                                ))
                                            }
                                            {
                                                visible < state.ticketsComprados.length &&
                                                <div className="col-md-12 wrap-inner load-more text-center">
                                                    <Link to="#" id="load-more" className="sc-button loadmore fl-button pri-3" onClick={showMoreItems}><span>Cargar más</span></Link>
                                                </div>
                                            }
                                        </TabPanel>
                                    </div>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
            </section>
            <CardModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
            <Footer />
        </div>
    );
}

export default Authors02;
